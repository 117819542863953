<template>
  <div>
    <div v-scroll-hint class="table-responsive scroll-hint">
      <table class="ld-table ld-table-sm ld-td_ontop">
        <thead>
          <tr>
            <th style="width: 35%;">Location</th>
            <th style="width: 20%;">Assigned Employees</th>
            <th style="width: 20%;">Year</th>
            <th style="width: 25%;">Number of Holidays</th>
          </tr>
        </thead>
        <tbody data-cy="holidays-table">
          <tr v-if="loading">
            <td class="tw-bg-white" colspan="4">
              <NoData message="Loading" />
            </td>
          </tr>
          <template v-else>
            <HolidayLocationsTableRow
              v-for="(location, index) in locationsWithHolidays"
              :key="index"
              :location="location"
              @edit-holidays="editHolidays"
              @delete-holidays="deleteHolidays"
              @assign-to-all-employments="
                $emit('assign-to-all-employments', location)
              "
              @add-year="addYear(location, $event)"
            />
            <tr v-if="!locationsWithHolidays.length">
              <td class="tw-bg-white" colspan="5">
                <NoData message="No holidays available" />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HolidayLocationsTableRow from '@/components/holidays/HolidayLocationsTableRow'
import NoData from '@/components/NoData'

export default {
  name: 'HolidayLocationsTable',

  components: { HolidayLocationsTableRow, NoData },

  props: {
    locations: {
      type: Object,
      required: true,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    locationsWithHolidays() {
      return this.locations.locationsWithHolidays()
    },
  },

  methods: {
    editHolidays(holidayLocation) {
      this.$emit('edit-holidays', holidayLocation)
    },

    deleteHolidays(holidayLocation) {
      this.$emit('delete-holidays', holidayLocation)
    },

    addYear(location, year) {
      this.$emit('add-year', {
        year,
        location,
      })
    },
  },
}
</script>
