<template>
  <div class="tw-flex">
    <slot>
      <UserAvatar :user="event.getActor()" class="tw-mt-2 tw-mr-2" />
    </slot>

    <div class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none tw-bg-white">
      <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
        <div class="tw-text-gray-700">
          <EventText :text="event.asText(activeEmployment)" />
        </div>

        <div class="tw-text-sm tw-text-gray-600 tw-whitespace-no-wrap">
          {{ event.getCreatedTime() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'
import EventText from '@/components/requests/EventText'

export default {
  name: 'DetailEvent',

  components: { EventText },

  props: {
    event: {
      type: OvertimeEvent,
      required: true,
    },
  },
}
</script>
