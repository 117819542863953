import Mousetrap from 'mousetrap'

export default {
  mounted() {
    Mousetrap.bind('shift+n', e => {
      e.preventDefault()
      this.showLeaveForm()
    })

    this.$once('hook:beforeDestroy', () => {
      Mousetrap.unbind('shift+n')
    })
  },
}
