<template>
  <v-select
    v-model="selectedOption"
    :options="options"
    :multiple="false"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    :max-height="180"
    placeholder="Select event type"
  >
  </v-select>
</template>

<script>
import vSelect from 'vue-multiselect'

export default {
  name: 'WebhookSingleEventTypePicker',

  components: { vSelect },

  props: {
    value: {
      type: String,
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    selectedOption: {
      get() {
        return this.value
      },

      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
