<template>
  <div>
    <Multiselect
      ref="multiSelect"
      :value="selectedEventTypes"
      :options="groupedEventTypes"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :internal-search="false"
      :show-labels="false"
      :max-height="300"
      :group-select="true"
      group-label="group"
      group-values="event_types"
      placeholder="Search and select"
      track-by="id"
      class="input-chips input-chips_basic"
      @search-change="search"
      @remove="removeEventTypes"
      @select="selectEventTypes"
    >
      <template slot="option" slot-scope="props">
        <WebhookEventTypesPickerOption
          :props="props"
          :selected-event-types="selectedEventTypes"
          :grouped-event-types="groupedEventTypes"
        />
      </template>

      <template slot="placeholder">
        <span class="tw-font-normal">
          {{ 'No events selected' }}
        </span>
      </template>

      <span slot="noResult">No results were found.</span>

      <template slot="selection" slot-scope="{ isOpen }">
        <span
          v-if="value.length === 1"
          class="multiselect__single tw-font-normal"
        >
          {{ selectedEventTypeName }}
        </span>

        <span
          v-else-if="value.length > 1"
          class="multiselect__single tw-font-normal"
        >
          {{ value.length }}
          {{ 'event' | pluralize(value.length) }} selected
        </span>

        <span v-else-if="isOpen" class="multiselect__single tw-font-normal">
          {{ 'No events selected' }}
        </span>
      </template>
    </Multiselect>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect'
import { some, first, groupBy, map, filter, reject } from 'lodash-es'
import WebhookEventTypesPickerOption from '@/components/integrations/webhooks/WebhookEventTypesPickerOption'

export default {
  name: 'WebhookEventTypesPicker',

  components: { Multiselect, WebhookEventTypesPickerOption },

  props: {
    value: {
      type: Array,
      default: null,
    },

    eventTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filteredEventTypes: [...this.eventTypes],
    }
  },

  computed: {
    selectedEventTypeName() {
      return first(this.selectedEventTypes)?.name
    },

    groupedEventTypes() {
      const groupedEventTypes = groupBy(
        map(this.filteredEventTypes, eventType => {
          return {
            ...eventType,
            is_selected: some(
              this.selectedEventTypes,
              selectedEventType => selectedEventType.id === eventType.id
            ),
          }
        }),
        'group.name'
      )

      return map(groupedEventTypes, eventTypes => {
        return {
          group: first(eventTypes).group.name,
          event_types: [...eventTypes],
        }
      })
    },

    selectedEventTypes: {
      get() {
        return this.value
      },

      set(selectedEventTypes) {
        this.$emit('input', [...selectedEventTypes])
      },
    },
  },

  methods: {
    search(query) {
      const searchQuery = query.trim().toLowerCase()

      if (searchQuery.length === 0) {
        this.filteredEventTypes = this.eventTypes

        return
      }

      this.filteredEventTypes = filter(
        this.eventTypes,
        eventType => eventType.name.toLowerCase().indexOf(searchQuery) >= 0
      )
    },

    removeEventTypes(options) {
      let filteredEventTypes = []

      if (options instanceof Array) {
        filteredEventTypes = reject(this.selectedEventTypes, eventType =>
          some(options, ['id', eventType.id])
        )
      } else {
        filteredEventTypes = reject(
          this.selectedEventTypes,
          eventType => eventType.id === options.id
        )
      }

      this.selectedEventTypes = [...filteredEventTypes]
    },

    selectEventTypes(options) {
      if (options instanceof Array) {
        this.selectedEventTypes = [...this.selectedEventTypes, ...options]

        return
      }

      this.selectedEventTypes = [...this.selectedEventTypes, options]
    },

    hide() {
      this.$refs.multiSelect.$refs.search.blur()
    },
  },
}
</script>
