<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <DetailEvent v-else :event="event">
      <img
        v-if="event.wasAutomaticallyApproved()"
        src="@/assets/icons/logo.svg"
        class="tw-w-10 leavedates-header-logo"
        alt="leavedates-logo"
      />
    </DetailEvent>
  </div>
</template>

<script>
import OvertimeApprovedEvent from '@/components/requests/overtime/events/OvertimeApprovedEvent'
import DetailEvent from '@/components/requests/overtime/timeline/DetailEvent'
import SummaryEvent from '@/components/requests/overtime/timeline/SummaryEvent'

export default {
  name: 'OvertimeWasApproved',

  components: { SummaryEvent, DetailEvent },

  props: {
    event: {
      type: OvertimeApprovedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
