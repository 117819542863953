<template>
  <div
    class="tw-flex tw-justify-between tw-items-center tw-cursor-pointer hover:tw-bg-gray-300 filter-label"
  >
    <a
      class="tw-w-full tw-no-underline tw-overflow-hidden"
      @click.prevent="$emit('option-changed', option)"
    >
      <span class="tw-font-normal tw-mx-4 tw-my-4 tw-block">{{
        optionLabel
      }}</span>
    </a>
    <div class="tw-flex tw-items-center tw-justify-center tw-px-3 tw-m-4 ">
      <input
        :id="option"
        type="radio"
        name="options"
        class="star-checkbox tw-absolute tw-hidden"
        :checked="option === companyScheduleFilter"
        @change="$emit('default-filter-changed', option)"
      />
      <label
        class="tw-relative tw-block tw-cursor-pointer tw-align-middle"
        :for="option"
      ></label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionLabel: {
      type: String,
      required: true,
    },
    defaultFilter: {
      type: String,
      default: null,
    },
    option: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      companyScheduleFilter: this.defaultFilter,
    }
  },
}
</script>

<style scoped>
.star-checkbox + label:before {
  position: absolute;
  display: inline-block;
  top: -15px;
  left: -7px;
  content: ' ';
  font-size: 19px;
  color: transparent;
}

.star-checkbox:checked + label:before,
.filter-label:hover .star-checkbox:checked + label:before,
.filter-label:hover .star-checkbox:not(:checked) + label:hover:before {
  content: '\2605';
  color: #f6ad55;
}

.filter-label:hover .star-checkbox:not(:checked) + label:before {
  content: '\2605';
  color: #b6bdc9;
}
</style>
