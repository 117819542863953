<template>
  <div class="tw-overflow-y-auto">
    <div class="tw-flex tw-justify-between tw-items-center tw-mt-1">
      <h4 class="tw-text-xl">
        {{ data.subscribed_event_type }}
      </h4>

      <SpinnerButton
        :disabled="webhookCallEventResending"
        :loading="webhookCallEventResending"
        :spinner-only="true"
        theme="white"
        :spinner-classes="['tw-text-gray-700']"
        class="tw-px-3 tw-py-2"
        @click="resendWebhookCallEvent"
      >
        <div class="tw-flex tw-items-center">
          <svg-icon
            name="refresh"
            class="tw-w-4 tw-h-4 tw-text-gray-700 tw-mr-2"
          />

          Resend
        </div>
      </SpinnerButton>
    </div>

    <div class="tw-mt-8">
      <h4 class="tw-text-lg">Response</h4>

      <BaseTag class="tw-mt-6" :theme="webhookCallEventStatusColor">
        {{ webhookCallEventStatus }}
      </BaseTag>

      <div class="tw-w-full tw-mt-6">
        <div v-if="data.response.body">
          {{ data.response.body }}
        </div>

        <NoData v-else message="No response body" />
      </div>

      <div class="tw-mt-6 tw-border-t tw-border-gray-350"></div>

      <h4 class="tw-text-lg tw-mt-6">Request</h4>

      <div class="tw-w-full tw-mt-6">
        <div v-if="data.request">
          {{ data.request }}
        </div>

        <NoData v-else message="No request body" />
      </div>
    </div>
  </div>
</template>

<script>
import NoData from '@/components/NoData'
import BaseTag from '@/components/BaseTag'
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from '@/mixins/ValidatesForm'
import { startsWith } from 'lodash-es'

export default {
  name: 'WebhookCallEventData',

  components: {
    NoData,
    BaseTag,
    SpinnerButton,
  },

  mixins: [ValidatesForm],

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      webhookCallEventResending: false,
    }
  },

  computed: {
    webhookCallEventStatus() {
      if (startsWith(this.data.response.status_code, '2')) {
        return 'Success'
      }

      return 'Error'
    },

    webhookCallEventStatusColor() {
      if (startsWith(this.data.response.status_code, '2')) {
        return 'green'
      }

      return 'red'
    },
  },

  methods: {
    async resendWebhookCallEvent() {
      this.webhookCallEventResending = true

      try {
        await this.$http.post(
          `webhook-call-events/${this.data.webhook_call_event_id}/resend`,
          {
            company_id: this.activeCompany.id,
          }
        )

        this.success('Webhook call event resent successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.webhookCallEventResending = false

      this.$emit('webhook-call-event-resent')
    },
  },
}
</script>
