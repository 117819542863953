import { isEmpty } from 'lodash-es'

export default {
  computed: {
    marketingSiteUrl() {
      if (isEmpty(this.$route.query)) {
        return process.env.VUE_APP_MARKETING_URL
      }

      return (
        process.env.VUE_APP_MARKETING_URL.replace(/\/+$/g, '/') +
        '?' +
        Object.entries(this.$route.query)
          .map(([key, value]) => key + '=' + value)
          .join('&')
      )
    },
  },
}
