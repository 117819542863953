<template>
  <div ref="reference">
    <span class="fc-title">&zwnj;</span>
    <div
      :style="styles"
      :class="{ 'request-event--stripes': isRequestedLeave }"
      class="request-event"
      data-cy="leave-bar"
    >
      &zwnj;
    </div>

    <div v-show="false" ref="tooltip">
      <div v-if="!leave.from">
        <div class="tw-px-3 tw-py-2">Loading...</div>
      </div>

      <div v-else>
        <div style="width: 240px">
          <div class="tw-p-4">
            <div
              :style="{ color: leaveTypeColor }"
              class="tw-text-sm tw-font-semibold tw-uppercase"
            >
              {{ leave.type.name }}
            </div>

            <div
              v-if="leaveDuration"
              class="tw-mt-1 tw-font-normal tw-text-base tw-uppercase tw-leading-none"
              data-cy="leave-duration"
            >
              {{ leaveDuration }}
            </div>

            <div
              v-if="leave.hasOwnProperty('reason') && leave.reason"
              class="tw-mt-1 tw-w-full tw-text-gray-600"
            >
              {{ reason }}
            </div>
          </div>
          <div
            class="tw-px-4 tw-py-3 tw-flex tw-justify-start tw-items-center tw-content-center tw-bg-gray-300"
          >
            <UserAvatar class="tw-shadow-lg" :user="leave.owner" />
            <div class="tw-px-3 tw-flex tw-items-center">
              <div class="tw-text-base tw-font-normal">
                <div>{{ leave.owner.full_name }}</div>
                <div
                  v-if="leave.owner.department"
                  class="tw-text-xs tw-text-gray-700"
                >
                  {{ leave.owner.department.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          v-if="canViewLeaveDetails"
          data-cy="view-details"
          class="tw-w-full tw-p-4 tw-flex tw-justify-center tw-items-center btn-link tw-bg-blue-500 tw-text-white tw-text-thin tw-font-semibold tw-uppercase hover:tw-no-underline"
          @click="showMoreDetails"
        >
          View Details
          <SvgIcon
            class="tw-ml-1 tw-w-5 tw-h-5 tw-text-white"
            name="cheveron-right"
            style="margin-top: 1px;"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import tippy from 'tippy.js'
import router from '@/router'
import { truncate } from 'lodash-es'
import FormatNumbers from '@/mixins/FormatNumbers'
import LeavePermissions from '@/mixins/LeavePermissions'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import UserAvatar from '@/components/UserAvatar'
import { Leaves } from '@/api'
import RequestDuration from '@/components/requests/RequestDuration'

export default Vue.extend({
  router,

  name: 'Leave',

  components: { UserAvatar },

  mixins: [LeavePermissions, FormatNumbers, FormatDate, ValidatesForm],

  props: {
    dataLeave: {
      type: Object,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },

    employment: {
      type: Object,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    element: {
      type: HTMLElement,
      default: () => {},
    },
  },

  data() {
    return {
      leave: this.dataLeave,
    }
  },

  computed: {
    leaveDuration() {
      return new RequestDuration(this.leave.getLeaveDuration()).toString(
        'Total: '
      )
    },

    styles() {
      return {
        backgroundColor: this.leaveTypeColor,
      }
    },

    leaveTypeColor() {
      return this.leave.type.colour
    },

    isRequestedLeave() {
      return this.leave.status === 'REQUESTED'
    },

    canViewLeaveDetails() {
      return this.employment.hasPermissionsToSeeMoreDetails(this.leave.owner)
    },

    reason() {
      return truncate(this.leave.reason, {
        length: 80,
      })
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.tippy = tippy.one(this.$refs.reference, {
        html: this.$refs.tooltip.firstChild,
        interactive: true,
        theme: 'light',
        animation: 'scale',
        placement: 'bottom',
        arrow: true,
        delay: [100, 20],
        onShow: () => {
          this.fetchLeave()
        },
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    })
  },

  methods: {
    showMoreDetails() {
      this.tippy.hide()

      this.$emit('show-leave', this.leave)
    },

    async fetchLeave() {
      this.leave = await Leaves.get(this.dataLeave.id, this.company)
    },
  },
})
</script>
