<template>
  <div></div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'LeaveLimit',

  props: {
    element: {
      type: HTMLElement,
      default: () => {},
    },

    leaveLimit: {
      type: Object,
      required: true,
    },
  },
})
</script>
