<template>
  <v-select
    v-model="selectedFrequency"
    :options="options"
    :multiple="false"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    :max-height="180"
    track-by="value"
    label="name"
    data-cy="select-report-frequency"
  >
  </v-select>
</template>

<script>
import VSelect from 'vue-multiselect'

export default {
  name: 'ReportFrequencyPicker',

  components: {
    VSelect,
  },

  props: {
    value: {
      type: String,
      default: '*',
    },
    options: {
      type: Array,
      required: true,
    },
  },

  computed: {
    selectedFrequency: {
      get() {
        return this.options.find(f => f.value === this.value)
      },
      set(value) {
        this.$emit('input', value.value)
      },
    },
  },
}
</script>
