<template>
  <div class="card" data-cy="next-seven-days-summary">
    <div v-if="eventsGroupedByDay.length">
      <div
        class="tw-mt-2 tw-mb-6 tw-flex tw-justify-between next-seven-days-filter"
      >
        <h2 class="tw-leading-loose tw-text-gray-800 ">The Next 7 Days</h2>
        <div class="tw-flex tw-items-center tw-items-center">
          <NextSevenDaysFilter
            :scope="scope"
            :display-filters="displayFilters"
            @change-filters="$emit('change-filters', $event)"
          />
        </div>
      </div>
      <SingleDay
        v-for="(eventsOfDay, index) in eventsGroupedByDay"
        :key="eventsOfDay.day"
        :day-number="index"
        :day-leave="eventsOfDay.leave"
        :birthdays="eventsOfDay.birthdays"
        :holidays="eventsOfDay.holidays"
        :work-anniversaries="eventsOfDay.workAnniversaries"
        :day="eventsOfDay.day"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import MomentPeriod from '@/mixins/MomentPeriod'
import FormatDate from '@/mixins/FormatDate'
import { orderBy, uniqBy } from 'lodash-es'
import NextSevenDaysFilter from '@/components/next-seven-days/NextSevenDaysFilter'

const SingleDay = () => import('@/components/next-seven-days/SingleDay')

export default {
  name: 'NextSevenDays',

  components: { NextSevenDaysFilter, SingleDay },

  mixins: [MomentPeriod, FormatDate],

  props: {
    nextSevenDaysLeaves: {
      type: Array,
      required: true,
    },
    birthdays: {
      type: Array,
      required: true,
    },
    holidays: {
      type: Array,
      required: true,
    },
    workAnniversaries: {
      type: Array,
      required: true,
    },
    displayFilters: {
      type: Array,
      required: true,
    },
    selectedScope: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      scope: this.selectedScope,
    }
  },

  computed: {
    eventsGroupedByDay() {
      const startDate = moment()
        .utc(true)
        .startOf('day')

      return this.createPeriod(
        startDate.clone(),
        startDate
          .clone()
          .add(6, 'day')
          .endOf('day')
      ).map(weekDay => {
        return {
          day: weekDay.toISOString(),
          leave: this.leaveForDay(weekDay),
          birthdays: this.birthdaysForDay(weekDay),
          holidays: this.holidaysForDay(weekDay),
          workAnniversaries: this.anniversariesForDay(weekDay),
        }
      })
    },
  },

  methods: {
    leaveForDay(weekDay) {
      return orderBy(
        this.nextSevenDaysLeaves
          .filter(leave => this.leaveBreakdownForDay(leave, weekDay))
          .map(leave => {
            return {
              ...leave,
              matchedLeaveDate: this.leaveBreakdownForDay(leave, weekDay),
            }
          }),
        leave => leave.owner.full_name.toLowerCase()
      )
    },

    leaveBreakdownForDay(leave, weekDay) {
      return leave.leave_breakdowns.find(leaveBreakdown => {
        return weekDay.isSame(
          this.toMoment(leaveBreakdown.to, leave.timezone).utc(true),
          'day'
        )
      })
    },

    birthdaysForDay(weekDay) {
      return orderBy(
        this.birthdays.filter(birthday => {
          return weekDay.isSame(this.momentBirthday(birthday), 'day')
        }),
        birthday => birthday.owner.full_name.toLowerCase()
      )
    },

    momentBirthday(birthday) {
      const date = birthday.birthday.split('/')[0]
      const month = birthday.birthday.split('/')[1] - 1

      let nextBirthday = moment()
        .utc(true)
        .startOf('day')
        .set({
          date: date,
          month: month,
        })

      if (
        nextBirthday.isBefore(
          moment()
            .utc(true)
            .startOf('day')
        )
      )
        return nextBirthday.add(1, 'years')

      return nextBirthday
    },

    holidaysForDay(weekDay) {
      return orderBy(
        uniqBy(
          this.holidays.filter(holiday => {
            return weekDay.isSame(moment.utc(holiday.date), 'day')
          }),
          holiday => {
            return (
              holiday.date +
              holiday.name.toLowerCase() +
              holiday.location.toLowerCase()
            )
          }
        ),
        holiday => holiday.name.toLowerCase()
      )
    },

    anniversariesForDay(weekDay) {
      return orderBy(
        this.workAnniversaries.filter(
          anniversary =>
            weekDay.format('MM-DD') ===
            moment.utc(anniversary.start_date).format('MM-DD')
        ),
        anniversary => anniversary.owner.full_name.toLowerCase()
      )
    },
  },
}
</script>
<style scoped>
.next-seven-days-filter {
  min-height: 33px;
}
</style>
