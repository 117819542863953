<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <div v-else>
      <div class="tw-flex">
        <UserAvatar :user="event.event.actor" class="tw-mt-2 tw-mr-2" />

        <div
          class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none"
          :class="bgColor"
        >
          <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
            <div class="tw-font-medium tw-text-purple-700 tw-capitalize">
              {{ event.getActorName(activeEmployment) }}
            </div>

            <div class="tw-text-sm tw-text-gray-600">
              {{ event.getCreatedTime() }}
            </div>
          </div>

          <div class="tw-flex tw-justify-between tw-items-center">
            <div
              class="tw-text-justify tw-flex tw-items-center"
              :class="commentTextClasses"
            >
              <SvgIcon
                v-if="event.isDeleted()"
                class="tw-w-4 tw-h-4 tw-mr-1"
                name="minus-circle"
              />
              <span>{{ event.getCommentText(activeEmployment) }}</span>
            </div>

            <button
              v-if="canDeleteComment"
              class="tw-flex tw-items-center tw-border-none tw-outline-none tw-ml-5 tw-mr-5"
              type="button"
              @click="deleteComment"
            >
              <SvgIcon class="tw-w-5 tw-h-5 tw-text-red-700" name="bin" />
            </button>

            <button
              v-if="canRestoreComment"
              class="tw-flex tw-items-center tw-border-none tw-outline-none tw-ml-5 tw-mr-5"
              type="button"
              @click="restoreComment"
            >
              <SvgIcon class="tw-w-5 tw-h-5 tw-text-gray-700" name="undo" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentEvent from '@/components/requests/leave/events/CommentEvent'
import SummaryEvent from '@/components/requests/leave/timeline/SummaryEvent'

export default {
  name: 'LeaveCommentWasAdded',

  components: { SummaryEvent },

  props: {
    event: {
      type: CommentEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    bgColor() {
      if (this.event.viewerIsActor(this.activeEmployment)) {
        return ['tw-bg-gray-300']
      }
      return ['tw-bg-purple-100']
    },

    commentTextClasses() {
      return !this.event.isDeleted() ? 'tw-text-gray-800' : 'tw-text-gray-550'
    },

    canDeleteComment() {
      return this.event.canDeleteComment(this.activeEmployment)
    },

    canRestoreComment() {
      return this.event.canRestoreComment(this.activeEmployment)
    },
  },

  methods: {
    deleteComment() {
      this.$emit('delete-comment', this.event.event.resource)
    },

    restoreComment() {
      this.$emit('restore-comment', this.event.event.resource)
    },
  },
}
</script>
