import http from '@/plugins/http'

export default class Approver {
  static async getReport(params) {
    return await http.get('reports/approver', {
      params: params,
    })
  }

  static async download(params) {
    return await http.get('reports/downloads/approver', {
      params: params,
      responseType: 'blob',
    })
  }
}
