<template>
  <div>
    <ToggleButton
      :value="enabled"
      :sync="true"
      :labels="true"
      color="#1da1f2"
      data-cy="enable-weekly-summary-report"
      @change="toggleEnableWeeklySummaryReport"
    />
    <label class="tw-ml-2 tw-select-none">
      Receive weekly summary report by email.
    </label>
    <span
      v-if="enabled"
      class="btn-link tw-font-semibold"
      @click="$modal.show('weekly-email-summary-config')"
    >
      Configure
    </span>

    <Modal
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="450"
      name="weekly-email-summary-config"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="reset"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between tw-leading-tight">
          <div>
            <p class="modal-title">Weekly Email Schedule</p>
          </div>
          <div>
            <button
              class="modal-close"
              data-cy="employee-close"
              @click.prevent="$modal.hide('weekly-email-summary-config')"
            >
              <svg-icon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form class="tw-w-full">
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="email-report-date-frequency"
                >Send me a weekly report by email every</label
              >
              <div class="tw-relative">
                <report-frequency-picker
                  id="email-report-date-frequency"
                  v-model="dateFrequency"
                  :options="frequencies"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="email-report-time"
                >At what time ({{ activeEmploymentTimezone }})</label
              >
              <div class="tw-relative">
                <ClockPicker
                  id="email-report-time"
                  v-model="selectedEmailReportTime"
                  class="form-control"
                  style="width: 85px;"
                />
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="email-report-scope">Showing</label>
              <div class="tw-relative">
                <email-report-scope-picker
                  id="email-report-scope"
                  v-model="reportScopes"
                  :options="selectableEmailReportScopes"
                />
              </div>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <button
                  :disabled="!scopeValidity || loading"
                  class="btn btn-blue"
                  type="submit"
                  data-cy="btn-save-weekly-email-schedule"
                  @click.prevent="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import ClockPicker from '../ClockPicker'
import { flatMap } from 'lodash-es'
import { ToggleButton } from 'vue-js-toggle-button'
import ReportFrequencyPicker from '../pickers/ReportFrequencyPicker'
import EmailReportScopePicker from '../pickers/EmailReportScopePicker'
import ValidatesForm from '../../mixins/ValidatesForm'
import EmailReportScopes from '../../mixins/EmailReportScopes'

export default {
  name: 'WeeklyEmailReportSchedule',

  components: {
    ClockPicker,
    ToggleButton,
    ReportFrequencyPicker,
    EmailReportScopePicker,
  },

  mixins: [ValidatesForm, EmailReportScopes],

  data() {
    return {
      loading: false,
      id: '',
      time: moment({
        hour: '09',
        minute: '00',
      }).format('hh:mma'),
      dateFrequency: '1',
      frequencies: [
        {
          name: 'Monday',
          value: '1',
        },
        {
          name: 'Tuesday',
          value: '2',
        },
        {
          name: 'Wednesday',
          value: '3',
        },
        {
          name: 'Thursday',
          value: '4',
        },
        {
          name: 'Friday',
          value: '5',
        },
        {
          name: 'Saturday',
          value: '6',
        },
        {
          name: 'Sunday',
          value: '0',
        },
      ],
      enabled: false,
      validity: false,
    }
  },

  computed: {
    activeEmploymentTimezone() {
      return this.activeEmployment.timezone
    },

    selectedEmailReportTime: {
      get() {
        return moment.utc(this.time, 'hh:mma')
      },
      set(time) {
        this.time = time.format('hh:mma')
      },
    },

    hasConfigured() {
      return !!this.id
    },
  },

  async created() {
    await this.fetchCompanies()

    this.$emit('loading', true)

    await this.fetchEmailReportSchedule()

    this.$emit('loading', false)
  },

  methods: {
    reset() {
      if (!this.hasConfigured) {
        this.enabled = false
      }

      this.fetchEmailReportSchedule()
    },

    toggleEnableWeeklySummaryReport({ value }) {
      if (!this.hasConfigured) {
        this.enabled = true
        return this.$modal.show('weekly-email-summary-config')
      }

      if (value) {
        return this.enableEmailReportSchedule()
      }

      this.disableEmailReportSchedule()
    },

    async submit() {
      this.loading = true

      try {
        const method = this.hasConfigured ? 'put' : 'post'

        await this.$http[method]('me/weekly-email-report-schedules', {
          time: this.time,
          date_frequency: this.dateFrequency,
          report_scope: this.formattedReportScope,
        })

        await this.fetchEmailReportSchedule()

        this.$modal.hide('weekly-email-summary-config')

        this.success('Configuration saved successfully.')
      } catch (e) {
        this.serverError()
      }

      this.loading = false
    },

    async enableEmailReportSchedule() {
      this.loading = true

      await this.$http.post('me/weekly-email-report-schedule-notifications')

      this.success('Weekly email summary report enabled successfully.')

      await this.fetchEmailReportSchedule()

      this.loading = false
    },

    async disableEmailReportSchedule() {
      this.loading = true

      await this.$http.delete('me/weekly-email-report-schedule-notifications')

      this.success('Weekly email summary report disabled successfully.')

      await this.fetchEmailReportSchedule()

      this.loading = false
    },

    async fetchEmailReportSchedule() {
      try {
        const { data } = await this.$http.get(
          'me/weekly-email-report-schedules'
        )

        if (data.length) {
          const schedule = data[0]
          const reportScopes = flatMap(data, schedule => schedule.report_scope)

          this.id = schedule.id
          this.time = schedule.time
          this.dateFrequency = schedule.date_frequency
          this.setReportScopes(reportScopes)
          this.enabled = schedule.enabled
        }
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },
  },
}
</script>
