<template>
  <div class="fc-cell-text">
    <router-link
      v-if="isAdmin"
      :to="route"
      class="tw-text-blue-500 hover:tw-text-blue-500 tw-no-underline tw-text-base tw-font-semibold"
      data-cy="add-employee-link"
    >
      <div class="tw-flex tw-items-center tw-justify-start">
        <div class="tw--ml-1" style="margin-right: 8px;">
          <div class="tw-pt-1">
            <svg-icon name="add-outline" class="tw-w-8 tw-h-8" />
          </div>
        </div>
        <div>
          <div class="tw-font-semibold tw-text-left">Add</div>
          <div
            class="tw-font-normal tw-text-gray-800"
            style="margin-left: -1px; font-size: 0.95em;"
          >
            Employee
          </div>
        </div>
      </div>
    </router-link>

    <span v-else class="tw-py-1 tw-text-base tw-font-normal tw-text-gray-800"
      >Employee</span
    >
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'AddEmployment',

  computed: {
    isAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },

    route() {
      return {
        name: 'employees',
        query: {
          ...this.$route.query,
        },
      }
    },
  },
})
</script>
