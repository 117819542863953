<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <div v-else class="tw-flex">
      <UserAvatar :user="event.getActor()" class="tw-mt-2 tw-mr-2" />

      <div
        class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none tw-bg-red-100"
      >
        <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
          <div>
            <span class="tw-font-medium tw-capitalize tw-text-red-900">
              {{ event.getActorName(activeEmployment) }}
            </span>

            <span class="tw-text-red-900">
              deleted this attachment
            </span>
          </div>

          <div class="tw-text-sm tw-text-gray-600">
            {{ event.getCreatedTime() }}
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-justify-between">
          <AttachmentPreview :attachment="attachment" :is-deleted="true" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SummaryEvent from '@/components/requests/leave/timeline/SummaryEvent'
import AttachmentPreview from '@/components/AttachmentPreview'
import ValidatesForm from '@/mixins/ValidatesForm'
import AttachmentDeletedEvent from '@/components/requests/leave/events/AttachmentDeletedEvent'

export default {
  name: 'LeaveAttachmentWasDeleted',

  components: { SummaryEvent, AttachmentPreview },

  mixins: [ValidatesForm],

  props: {
    event: {
      type: AttachmentDeletedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    attachment() {
      return this.event.getResource()
    },
  },
}
</script>
