<template>
  <input
    :value="formattedTime"
    v-bind="$attrs"
    type="text"
    autocomplete="off"
    data-cy="clock-picker"
  />
</template>

<script>
import $ from 'jquery'
import './../assets/vendor/clockpicker/js/jquery-clockpicker.min'
import FormatDate from '../mixins/FormatDate'

export default {
  name: 'ClockPicker',

  mixins: [FormatDate],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      time: this.value.clone(),
    }
  },

  computed: {
    in24HourClock() {
      return this.authUser.settings.in_24_hour_clock
    },

    formattedTime: {
      get() {
        return this.formatDateFromIsoToHourMinutes(this.value)
      },
      set(value) {
        let hours = parseInt(value.substring(0, 2))
        const minutes = parseInt(value.substring(3, 5))
        const meridiem = value.substring(5, 7)

        if (!this.in24HourClock) {
          if (meridiem === 'PM') {
            hours = hours === 12 ? 12 : hours + 12
          }

          if (meridiem === 'AM') {
            hours = hours === 12 ? 0 : hours
          }
        }

        this.time = this.time.clone().set({
          hours,
          minutes,
          milliseconds: 0,
        })
      },
    },
  },

  mounted() {
    const picker = $(this.$el).clockpicker({
      autoclose: true,
      default: this.formattedTime,
      twelvehour: !this.in24HourClock,
    })

    picker.change(e => {
      this.formattedTime = e.target.value
      this.$emit('input', this.time)
    })

    this.$once('hook:beforeDestroy', () => {
      picker.remove()
    })
  },
}
</script>
