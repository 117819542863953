<template>
  <!-- eslint-disable vue/no-v-html -->
  <div :class="eventTextClass" v-html="eventText"></div>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'EventText',

  props: {
    text: {
      type: String,
      required: true,
    },
    eventTextClass: {
      type: String,
      default: 'tw-text-gray-700',
    },
  },

  computed: {
    eventText() {
      // match text inside ** ** marks
      let regex = /\*\*(.*?)\*\*/g

      const highlightedText = `<span class="tw-font-medium tw-text-purple-700 tw-capitalize">$1</span>`

      return this.text.replaceAll(regex, highlightedText)
    },
  },
}
</script>
