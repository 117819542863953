<template>
  <tr>
    <td data-cy="holiday-date">{{ formattedDay }}</td>
    <td>
      <div
        :title="holiday.name"
        class="tw-truncate"
        data-cy="holiday-selected-name"
      >
        {{ holiday.name }}
      </div>
    </td>
    <td class="tw-truncate" data-cy="holiday-type">{{ holidayType }}</td>
    <td class="tw-text-right">
      <div>
        <button
          class="btn btn-icon"
          title="Delete"
          data-cy="delete-holiday"
          @click.prevent="$emit('remove')"
        >
          <span class="tw-flex tw-items-center tw-justify-center">
            <SvgIcon
              name="trash"
              class="tw-w-3 tw-h-3 tw-text-gray-600 svg-icon"
            />
          </span>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: 'HolidaysTableRow',

  mixins: [FormatDate],

  props: {
    holiday: {
      type: Object,
      required: true,
    },
    holidayTypes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    holidayType() {
      return this.holidayTypes.find(type => type.id === this.holiday.type).name
    },

    formattedDay() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.holiday.date
      )
    },
  },
}
</script>
<style scoped>
.ld-table td {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
}
</style>
