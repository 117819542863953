<script>
import FormatEmploymentName from '@/mixins/FormatEmploymentName'
import Notification from '@/components/notifications/Notification'

export default {
  name: 'RequestDetails',

  mixins: [FormatEmploymentName],

  props: {
    notification: {
      type: Notification,
      default: null,
    },
  },

  computed: {
    owner() {
      if (this.notification.viewerIsOwner(this.activeEmployment)) {
        return 'your'
      }

      if (this.notification.actorIsOwner()) {
        return 'their'
      }

      return this.compactFullName(this.notification.getOwner()) + "'s"
    },

    actor() {
      return this.compactFullName(this.notification.getActor())
    },
  },

  render() {
    return this.$scopedSlots.default({
      owner: this.owner,
      actor: this.actor,
    })
  },
}
</script>
