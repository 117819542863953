<template>
  <div>
    <multiselect
      :value="pickedLeaveTypes"
      :options="leaveTypes"
      :multiple="true"
      :hide-selected="true"
      :show-labels="false"
      :max-height="130"
      class="input-chips input-chips_basic"
      label="name"
      placeholder=""
      track-by="id"
      @input="setLeaveTypes"
    >
      <template slot="option" slot-scope="props">
        <div class="tw-flex tw-justify-start tw-items-center tw-content-center">
          <div class="tw-text-base tw-font-normal tw-text-gray-800">
            {{ props.option.name }}
          </div>
        </div>
      </template>

      <template slot="tag" slot-scope="props">
        <div class="chip-wrapper">
          <div class="chips">
            <div class="chip">
              <div class="title">{{ props.option.name }}</div>
              <button class="close" type="button" @click="remove(props.option)">
                <SvgIcon name="close" class="tw-w-2 tw-h-2 tw-text-red-500" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import SvgIcon from '@/components/SvgIcon'
import { Multiselect } from 'vue-multiselect'
import { last } from 'lodash-es'

export default {
  name: 'MultipleLeaveTypesPicker',

  components: { SvgIcon, Multiselect },

  props: {
    value: {
      type: Array,
      default: null,
    },

    leaveTypes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      pickedLeaveTypes: [],
    }
  },

  computed: {
    defaultLeaveType() {
      return this.leaveTypes.find(type => type.id === '')
    },

    pickedLeaveTypeKeys() {
      if (!this.pickedLeaveTypes.length) {
        return null
      }

      if (
        this.pickedLeaveTypes.find(type => type.id === this.defaultLeaveType.id)
      ) {
        return []
      }

      return this.pickedLeaveTypes.map(type => type.id)
    },
  },

  mounted() {
    if (!this.value || !this.value.length) {
      this.pickedLeaveTypes = [this.defaultLeaveType]

      return
    }

    this.pickedLeaveTypes = this.leaveTypes.filter(type =>
      this.value.includes(type.id)
    )
  },

  methods: {
    setLeaveTypes(types) {
      this.pickedLeaveTypes =
        last(types).id === this.defaultLeaveType.id
          ? [this.defaultLeaveType]
          : [...types.filter(type => type.id !== this.defaultLeaveType.id)]

      this.$emit('input', this.pickedLeaveTypeKeys)
    },

    remove(removedType) {
      this.pickedLeaveTypes = this.pickedLeaveTypes.filter(
        type => type.id !== removedType.id
      )

      this.$emit('input', this.pickedLeaveTypeKeys)
    },
  },
}
</script>
