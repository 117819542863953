<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title"><h2 class="page-title">Leave Report</h2></div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table
        class="ld-table ld-table-striped"
        data-cy="table-summary-leave-report"
      >
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Type</th>
            <th>Total Count</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="leaveReports.length">
            <leave-summary-report-table-row
              v-for="(leaveReport, index) in leaveReports"
              :key="index"
              :leave-report="leaveReport"
            />
          </template>
          <tr v-if="!loading && !leaveReports.length">
            <td class="tw-bg-white" colspan="11">
              <no-data />
            </td>
          </tr>
        </tbody>
      </table>
      <loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('../../NoData')
const LeaveSummaryReportTableRow = () => import('./LeaveSummaryReportTableRow')

export default {
  name: 'LeaveSummaryReportTable',

  components: {
    NoData,
    Loading,
    LeaveSummaryReportTableRow,
  },

  props: {
    leaveReports: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
