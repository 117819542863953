<template>
  <div style="display: flex">
    <input
      id="search"
      v-model="searchText"
      v-focus
      name="search"
      data-vv-as="search"
      data-cy="search"
      class="form-control"
      type="text"
      tabindex="1"
      autocomplete="off"
      placeholder="Search..."
    />
    <a
      style="margin-top: 8px;"
      data-cy="close-search"
      @click.prevent="searchTextClear"
    >
      <svg-icon
        v-if="searchText"
        name="close"
        class="tw-ml-1 tw-w-5 tw-h-5 tw-cursor-pointer tw-text-gray-800"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'SearchBoxSwitcher',

  computed: {
    searchText: {
      get() {
        return this.$route.query.search
      },
      set(searchText) {
        if (searchText) {
          this.$router.push(
            {
              query: {
                ...this.$route.query,
                search: searchText,
              },
            },
            () => {}
          )
        } else {
          let query = { ...this.$route.query }
          delete query.search
          this.$router.replace({ query }, () => {})
        }
      },
    },
  },

  methods: {
    searchTextClear() {
      this.searchText = ''
    },
  },
}
</script>
