<template>
  <div class="tw-ml-2 ">
    <button
      ref="filterButton"
      class="tw-px-6 tw-py-2 tw-flex tw-items-center tw-rounded-lg tw-shadow-sm tw-cursor-pointer tw-no-underline tw-text-gray-700"
      type="button"
      data-cy="filter-conflicts"
    >
      <SvgIcon name="filter" class="tw-w-4 tw-h-4 tw-mr-2 tw-text-gray-700" />
      <span class="tw-font-bold tw-truncate">Filter</span>

      <div v-show="false" ref="filterContainer">
        <div class="tw-rounded tw-overflow-auto filter-container">
          <div class="tw-pt-4 tw-pb-1 tw-px-6 tw-border-b tw-border-gray-300">
            <div class="tw-mb-5 tw-text-gray-600">
              Show:
            </div>
            <template v-for="filterScope of filterScopes">
              <div
                :key="filterScope.key"
                class="tw-mb-4 tw-flex tw-items-center"
              >
                <input
                  :id="filterScope.key"
                  v-model="selectedScope"
                  class="magic-radio"
                  name="conflicts"
                  type="radio"
                  :value="filterScope.key"
                  :data-cy="filterScope.key"
                  @change="changeFilters()"
                />
                <label
                  :for="filterScope.key"
                  class="tw-select-none magic-radio-label tw-w-full tw-font-normal"
                >
                  {{ filterScope.value }}
                </label>
              </div>
            </template>
          </div>

          <div class="tw-p-4 tw-rounded tw-overflow-auto filter-container">
            <div class="tw-mb-5 tw-text-gray-600">
              Display:
            </div>

            <template v-for="filter of displayFilters">
              <div :key="filter" class="tw-mb-4 tw-flex tw-items-center">
                <input
                  :id="filter"
                  v-model="selectedDisplayFilters"
                  :value="filter"
                  checked
                  class="magic-checkbox"
                  name="leave"
                  type="checkbox"
                  data-cy="leave-booked-checkbox"
                  @change="changeFilters()"
                />
                <label
                  :for="filter"
                  class="tw-select-none magic-checkbox-label tw-w-full tw-font-normal"
                  >{{ filter }}</label
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'NextSevenDaysFilter',

  props: {
    scope: {
      type: String,
      required: true,
    },
    displayFilters: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      filterScopes: [
        { key: 'company', value: 'Entire Company' },
        { key: 'department', value: 'Department' },
      ],
      selectedScope: this.scope,
      selectedDisplayFilters: this.displayFilters,
    }
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.filterButton, {
      html: this.$refs.filterContainer.firstChild,
      trigger: 'click',
      distance: -38,
      placement: 'bottom-end',
      interactive: true,
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },

  methods: {
    changeFilters() {
      this.$emit('change-filters', {
        scope: this.selectedScope,
        displayFilters: this.selectedDisplayFilters,
      })
    },
  },
}
</script>
<style scoped>
.filter-container {
  min-width: 163px;
}
</style>
