import { find, flatMap } from 'lodash-es'

export default {
  props: {
    reportCompany: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      companies: [],
      reportScopes: [
        {
          id: this.reportCompany.id,
          fullName: `${this.reportCompany.name} - Whole Company`,
        },
      ],
    }
  },

  computed: {
    scopeValidity() {
      return !!this.reportScopes.length
    },

    formattedReportScope() {
      let scopes = []
      this.reportScopes.forEach(scope => {
        if (scope.company_id) {
          return scopes.push(`report_department_id:${scope.id}`)
        }

        scopes.push(`report_company_id:${scope.id}`)
      })

      return scopes
    },

    selectableEmailReportScopes() {
      let reportScope = []

      this.companies.forEach(company => {
        reportScope.push({
          company: company.name,
          data: [
            {
              id: company.id,
              name: 'Whole Company',
              fullName: company.name + ' - Whole Company',
            },
            ...company.departments.map(department => {
              return {
                ...department,
                fullName: company.name + ' - ' + department.name,
              }
            }),
          ],
        })
      })

      return reportScope
    },
  },

  methods: {
    setReportScopes(scopes) {
      let reportScopes = []

      const departments = flatMap(
        this.companies,
        company => company.departments
      )

      scopes.forEach(scope => {
        let [type, value] = scope.split(':')

        if (type === 'report_department_id') {
          let department = find(departments, department => {
            return department.id === value
          })

          let departmentCompany = find(this.companies, company => {
            return company.id === department.company_id
          })

          return reportScopes.push({
            id: value,
            company_id: departmentCompany.id,
            fullName: `${departmentCompany.name} - ${department.name}`,
          })
        }

        let company = find(this.companies, company => {
          return company.id === value
        })

        reportScopes.push({
          id: value,
          fullName: `${company.name} - Whole Company`,
        })
      })

      this.reportScopes = reportScopes
    },

    async fetchCompanies() {
      try {
        const { data } = await this.$http.get('reportable-companies')

        this.companies = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },
  },
}
