<template>
  <div>
    <multiselect
      v-model="selectedScopes"
      :options="applicableScopes"
      :multiple="true"
      :hide-selected="true"
      :show-labels="false"
      :searchable="false"
      :max-height="130"
      class="input-chips input-chips_basic"
      data-cy="person-scopes"
      label="name"
      placeholder=""
      track-by="key"
    >
      <template slot="option" slot-scope="props">
        <div class="tw-flex tw-justify-start tw-items-center tw-content-center">
          <div
            class="tw-text-base tw-font-normal tw-text-gray-800"
            data-cy="drpdown-employee-name"
          >
            {{ props.option.name }}
          </div>
        </div>
      </template>

      <template slot="tag" slot-scope="props">
        <div class="chip-wrapper">
          <div class="chips">
            <div class="chip">
              <div class="title">{{ props.option.name }}</div>
              <button class="close" type="button" @click="remove(props.option)">
                <svg-icon name="close" class="tw-w-2 tw-h-2 tw-text-red-500" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect'
import { filter, includes, last, map, find } from 'lodash-es'

export default {
  name: 'VisibilityScopesPicker',

  components: { Multiselect },

  props: {
    value: {
      type: Array,
      default: null,
    },

    departments: {
      type: Array,
      required: true,
    },

    department: {
      type: Object,
      default: null,
    },

    exceptTheirOwnData: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      scopes: [],
    }
  },

  computed: {
    departmentScopes() {
      return this.departments.map(department => ({
        key: `departments:${department.id}`,
        name: department.name,
      }))
    },

    applicableScopes() {
      const scopes = [
        {
          key: `everyone`,
          name: 'Everyone',
        },
        {
          key: `me`,
          name: 'Their own data',
        },
        ...this.departmentScopes,
      ]

      if (this.department && !this.department.id) {
        scopes.splice(2, 0, {
          key: `this`,
          name: 'This Department',
        })
      }

      if (this.exceptTheirOwnData) {
        scopes.splice(1, 1)
      }

      return scopes
    },

    everyoneScope() {
      return find(this.applicableScopes, scope => scope.key === 'everyone')
    },

    scopesExceptEveryone() {
      return filter(this.scopes, scope => scope.key !== 'everyone')
    },

    scopesKeys() {
      return map(this.scopes, scope => scope.key)
    },

    selectedScopes: {
      get() {
        return this.scopes
      },
      set(value) {
        this.scopes = [...this.scopes, ...value]

        if (last(value).key === 'everyone') {
          this.scopes = [this.everyoneScope]
        } else {
          this.scopes = this.scopesExceptEveryone
        }

        this.$emit('input', this.scopesKeys)
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(scopes) {
        if (scopes) {
          this.scopes = this.applicableScopes.filter(scope =>
            includes(scopes, scope.key)
          )
        }
      },
    },
  },

  methods: {
    remove(option) {
      this.scopes = this.scopes.filter(scope => scope.key !== option.key)

      this.$emit('input', this.scopesKeys)
    },
  },
}
</script>
