<template>
  <div class="tw-flex">
    <slot>
      <UserAvatar :user="event.event.actor" class="tw-mt-2 tw-mr-2" />
    </slot>

    <div class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none tw-bg-white">
      <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
        <EventText :text="event.asText(activeEmployment)" />

        <div class="tw-text-sm tw-text-gray-600 tw-whitespace-no-wrap">
          {{ event.getCreatedTime() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventText from '@/components/requests/EventText'
import LeaveEvent from '@/components/requests/leave/events/LeaveEvent'

export default {
  name: 'DetailEvent',

  components: { EventText },

  props: {
    event: {
      type: LeaveEvent,
      required: true,
    },
  },
}
</script>
