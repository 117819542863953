<template>
  <LeaveWasApproved :event="event" :summary="summary" />
</template>

<script>
import LeaveWasApproved from '@/components/requests/leave/timeline/LeaveWasApproved.vue'
import ApprovedEvent from '@/components/requests/leave/events/ApprovedEvent'

export default {
  name: 'OneOfBulkLeaveWasApproved',

  components: { LeaveWasApproved },

  props: {
    event: {
      type: ApprovedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
