<template>
  <img
    src="./../assets/icons/leavedates-logo.svg"
    class="tw-mt-6 tw-mb-4 leavedates-header-logo"
  />
</template>

<script>
export default {
  name: 'HeaderLogo',
}
</script>
<style scoped>
.leavedates-header-logo {
  width: 100px;
}
</style>
