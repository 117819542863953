<script>
import EmploymentAllowance from '@/models/reporting/EmploymentAllowance'
import AllowanceSummaryReportTableRow from '@/components/reports/allowances/AllowanceSummaryReportTableRow'

export default {
  name: 'AllowanceSummaryReportTableAllowanceUnitGroup',

  functional: true,

  components: { AllowanceSummaryReportTableRow },

  props: {
    allowances: {
      type: Array,
      required: true,
    },

    hasToilOfferedForAtLeastOneAllowance: {
      type: Boolean,
      required: true,
    },
  },

  render(createElement, { props }) {
    return props.allowances.map(allowance => {
      const employmentAllowance = new EmploymentAllowance(allowance)

      return createElement(AllowanceSummaryReportTableRow, {
        props: {
          allowance: employmentAllowance,
          allowanceTypeRowspan: allowance.is_allowance_type_group_first
            ? allowance.allowance_type_group_count
            : 0,
          allowanceUnitRowspan: allowance.is_allowance_unit_group_first
            ? allowance.allowance_unit_group_count
            : 0,
          hasToilOfferedForAtLeastOneAllowance:
            props.hasToilOfferedForAtLeastOneAllowance,
        },
      })
    })
  },
}
</script>
