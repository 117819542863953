<template>
  <DatePicker
    v-model="pickedDate"
    :configurations="configurations"
    class="tw-flex tw-items-center tw-justify-start"
    @close="applyCorrectedInput"
  >
    <template v-slot:default="{ isMobile, picker }">
      <slot>
        <svg-icon
          name="calendar-empty"
          class="tw-w-5 tw-h-5 tw-text-gray-400"
        />

        <input
          v-model="inputValue"
          data-input
          class="tw-ml-2 sm:tw-ml-4 tw-w-full"
          :placeholder="placeholder"
          :readonly="isMobile || readonly"
          @blur="applyCorrectedInputIfPickerIsNotOpen(picker)"
          @focus="picker.open()"
        />
      </slot>
    </template>
  </DatePicker>
</template>

<script>
import moment from 'moment-timezone'
import DatePicker from '@/components/DatePicker'
import { isMoment } from 'moment/moment'

export default {
  name: 'SingleDatePicker',

  components: { DatePicker },

  props: {
    value: {
      type: [String, Object],
      required: true,
    },

    minDate: {
      type: [String, Object],
      default: null,
    },

    maxDate: {
      type: [String, Object],
      default: null,
    },

    format: {
      type: String,
      default: 'DD-MM-YYYY',
    },

    placeholder: {
      type: String,
      default: 'DD-MM-YYYY',
    },

    emitsMoment: {
      type: Boolean,
      default: false,
    },

    inlinePicker: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
    },
  },

  data: function() {
    return {
      inputValue: '',
    }
  },

  computed: {
    pickedDate: {
      get() {
        return this.parseToDate(this.value)
      },
      set(dates) {
        const dateInMoment = moment(dates[0])

        this.emitDate(
          this.emitsMoment ? dateInMoment : dateInMoment.format(this.format)
        )
      },
    },

    configurations() {
      const minDate = this.parseToDate(this.minDate)
      const maxDate = this.parseToDate(this.maxDate)

      return {
        ...(!!minDate && { minDate }),
        ...(!!maxDate && { minDate }),
        static: this.inlinePicker,
      }
    },

    correctedInputValue() {
      const date = moment(this.inputValue, this.format)

      if (this.emitsMoment) {
        return date
      }

      return date.isValid() ? date.format(this.format) : ''
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.inputValue = this.parseToString(value)
      },
    },
  },

  methods: {
    emitDate(date) {
      this.$emit('input', date)
    },

    parseToDate(date) {
      if (date instanceof Date) {
        return date
      }

      if (typeof date == 'string') {
        const momentDate = moment(date, this.format)

        return momentDate.isValid() ? momentDate.toDate() : null
      }

      if (isMoment(date)) {
        return date.isValid() ? date.toDate() : null
      }

      return null
    },

    parseToString(date) {
      if (typeof date == 'string') {
        return this.value
      }

      if (isMoment(date)) {
        return date.isValid() ? date.format(this.format) : ''
      }

      return ''
    },

    applyCorrectedInputIfPickerIsNotOpen(picker) {
      if (!picker.isOpen) {
        this.applyCorrectedInput()
      }
    },

    applyCorrectedInput() {
      this.emitDate(this.correctedInputValue)
    },
  },
}
</script>
