<template>
  <div class="tw-flex tw-items-center tw-justify-between">
    <EventText
      :text="event.asText(activeEmployment)"
      :event-text-class="'tw-flex-1 tw-mr-3'"
    />

    <AttachmentDate
      class="tw-text-sm tw-text-gray-555"
      :date="event.getCreatedAt()"
    />
  </div>
</template>

<script>
import EventText from '@/components/requests/EventText'
import AttachmentDate from '@/components/AttachmentDate'
import OvertimeEvent from '@/components/requests/overtime/events/OvertimeEvent'

export default {
  name: 'SummaryEvent',

  components: { EventText, AttachmentDate },

  props: {
    event: {
      type: OvertimeEvent,
      required: true,
    },
  },
}
</script>
