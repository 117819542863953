<template>
  <Component
    :is="arg.event.extendedProps.eventType"
    v-bind="componentProperties"
    @show-overtime="overtime => $emit('show-overtime', overtime)"
    @show-leave="leave => $emit('show-leave', leave)"
  />
</template>

<script>
import Holiday from '@/components/wall-chart/Holiday'
import Birthday from '@/components/wall-chart/Birthday'
import Leave from '@/components/wall-chart/Leave'
import Overtime from '@/components/wall-chart/Overtime'
import LeaveLimit from '@/components/wall-chart/LeaveLimit'

export default {
  name: 'EventComponent',

  components: {
    Holiday,
    Birthday,
    Leave,
    Overtime,
    LeaveLimit,
  },

  props: {
    arg: {
      type: Object,
      required: true,
    },
  },

  computed: {
    renderLeaveProperties() {
      return {
        arg: this.arg,
        company: this.activeCompany,
        employment: this.activeEmployment,
        user: this.authUser,
        dataLeave: {
          id: this.arg.event.id,
          ...this.arg.event.extendedProps,
        },
      }
    },

    renderOvertimeProperties() {
      return {
        arg: this.arg,
        company: this.activeCompany,
        employment: this.activeEmployment,
        user: this.authUser,
        dataOvertime: {
          id: this.arg.event.id,
          ...this.arg.event.extendedProps,
        },
      }
    },

    renderLeaveLimitProperties() {
      return { leaveLimit: this.arg.event.extendedProps }
    },

    renderHolidayProperties() {
      return {
        holiday: {
          id: this.arg.event.id,
          ...this.arg.event.extendedProps,
        },
      }
    },

    renderBirthdayProperties() {
      return { birthday: this.arg.event.extendedProps }
    },

    componentProperties() {
      if (this.arg.event.extendedProps.eventType === 'leave') {
        return this.renderLeaveProperties
      }

      if (this.arg.event.extendedProps.eventType === 'overtime') {
        return this.renderOvertimeProperties
      }

      if (this.arg.event.extendedProps.eventType === 'leave-limit') {
        return this.renderLeaveLimitProperties
      }

      if (this.arg.event.extendedProps.eventType === 'holiday') {
        return this.renderHolidayProperties
      }

      return this.renderBirthdayProperties
    },
  },
}
</script>
