<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'
import Profile from '@/api/profile/Profile'

export default {
  name: 'VerifyInvitation',

  layout: 'DefaultLayout',

  async created() {
    try {
      const { data } = await Profile.verifyInvitation(
        this.$route.query.email,
        this.$route.query.token
      )

      if (data.registration_required) {
        this.registerUser(data)
        return
      }

      if (data.authentication_required) {
        this.$router.replace({ name: 'signin' }, () => {})
        this.success('You already have an account. Please login.')

        return
      }

      await this.fetchUser()

      this.$router.replace(
        { name: 'leave-summary', query: { company: data.company_id } },
        () => {}
      )
      this.success('Your invitation has been successfully accepted.')
    } catch (error) {
      this.$router.replace({ name: 'signin' }, () => {})
      this.error('Invitation token is invalid.')
    }
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    async registerUser(data) {
      this.$router.replace(
        {
          name: 'signup',
          params: {
            employment: data.employment,
          },
          query: {
            ...this.$route.query,
            invited: true,
          },
        },
        () => {}
      )
    },
  },
}
</script>
