<template>
  <extra-info
    v-if="!hasMatchingWorkingDurations"
    class="extra-info_warning tw--ml-3"
    icon="exclamation-solid"
  >
    <div class="tw-p-4 tw-w-48">
      The hours per working day does not align with the working week schedule
      provided.
    </div>
  </extra-info>
</template>

<script>
import moment from 'moment-timezone'
import ExtraInfo from '../ExtraInfo'
import { groupBy, sum, some } from 'lodash-es'

export default {
  name: 'HoursPerWorkingDayWarning',

  components: {
    ExtraInfo,
  },

  props: {
    scheduleBreakdowns: {
      type: Array,
      required: true,
    },
    minutesPerWorkingDay: {
      type: Number,
      required: true,
    },
  },

  computed: {
    hasMatchingWorkingDurations() {
      return some(this.workingDays, workingDay => {
        return (
          this.durationOfWorkingDay(workingDay) === this.minutesPerWorkingDay
        )
      })
    },

    workingDays() {
      return groupBy(this.scheduleBreakdowns, 'iso_week_day')
    },
  },

  methods: {
    durationOfWorkingDay(workingDay) {
      return sum(
        workingDay.map(breakdown => {
          return moment(breakdown.end_time, 'hh:mm:ss').diff(
            moment(breakdown.start_time, 'hh:mm:ss'),
            'minutes'
          )
        })
      )
    },
  },
}
</script>
