<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col tw-text-center">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <div
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
        >
          <p class="tw-font-semibold tw-my-4 tw-text-5xl tw-text-gray-800">
            Unauthorized
          </p>

          <svg-icon
            class="tw-mb-6 tw-w-12 tw-h-12 tw-text-gray-500"
            name="lock-closed"
          />

          <div class="tw-mb-4">
            <p class="tw-mb-8 tw-text-gray-700">
              Sorry, you do not have permission to view this page. Please check
              with your system administrator.
            </p>
            <router-link
              :to="{ name: 'wall-chart' }"
              class="btn btn-blue tw-py-2 tw-no-underline"
            >
              Go Home
            </router-link>
          </div>
        </div>

        <auth-footer />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFooter from './../components/AuthFooter'

export default {
  name: 'Unauthorized',

  layout: 'DefaultLayout',

  components: { AuthFooter },
}
</script>
