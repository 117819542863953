<template>
  <RequestRejected
    request-type="overtime"
    :notification="notification"
    :active-notification="activeNotification"
    @show-more-details="showMoreDetails"
  />
</template>

<script>
import RequestRejected from '@/components/notifications/RequestRejected'
import Notification from '@/components/notifications/mixins/Notification'

export default {
  name: 'OvertimeRequestRejected',

  components: {
    RequestRejected,
  },

  mixins: [Notification],

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)
    },
  },
}
</script>
