<template>
  <div>
    <div
      v-if="pendingApprovals.length"
      class="card"
      data-cy="approvals-summary"
    >
      <div class="sm:tw-flex tw-justify-between tw-mb-6">
        <h2 class="tw-text-gray-800 tw-mt-2">
          Pending Approvals
        </h2>
        <div class="tw-flex tw-mt-6 sm:tw-mt-0">
          <SpinnerButton
            theme="red"
            :class="'tw-mr-3'"
            :disabled="cancelling"
            :loading="cancelling"
            :spinner-only="true"
            type="button"
            cypress-attribute="btn-decline-all"
            @click="$emit('decline')"
          >
            Decline All
          </SpinnerButton>
          <SpinnerButton
            theme="green"
            type="button"
            cypress-attribute="btn-approve-all"
            :disabled="approving"
            :loading="approving"
            :spinner-only="true"
            @click="$emit('approve')"
          >
            Approve All
          </SpinnerButton>
        </div>
      </div>
      <div v-scroll-hint class="table-responsive scroll-hint tw-relative">
        <table
          class="ld-table ld-table-clickable tw-mb-0"
          data-cy="requested-leaves-table"
        >
          <thead>
            <tr>
              <th>Employee Name</th>
              <th>Type</th>
              <th>Starting</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody v-if="pendingApprovals.length">
            <PendingApprovalsSummaryTableRow
              v-for="pendingApproval in pendingApprovals"
              :key="pendingApproval.id"
              :pending-approval="pendingApproval"
              @show-info="$emit('show-info', $event)"
            />
          </tbody>
        </table>
        <Loading loader="dots" :is-full-page="false" :active.sync="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SpinnerButton from '@/components/SpinnerButton'
import PendingApprovalsSummaryTableRow from '@/components/pending-approvals/PendingApprovalsSummaryTableRow'

export default {
  name: 'PendingApprovalsSummaryTable',

  components: {
    SpinnerButton,
    Loading,
    PendingApprovalsSummaryTableRow,
  },

  props: {
    pendingApprovals: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: () => false,
    },

    cancelling: {
      type: Boolean,
      default: () => false,
    },

    approving: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>
<style scoped>
.vld-overlay {
  z-index: 99 !important;
}
</style>
