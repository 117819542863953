<template>
  <paginate
    :value="currentPage"
    :page-count="pageCount"
    :page-range="pageRange"
    :click-handler="clickHandler"
    prev-text="Prev"
    next-text="Next"
    container-class="pagination"
    prev-class="page-prev"
    next-class="page-next"
    active-class="page-active"
    break-view-link-class="break-view-link"
    disabled-class="disabled-class"
    data-cy="paginate"
  />
</template>

<script>
import Paginate from 'vuejs-paginate'

const PAGE_RANGE = 7
const MOBILE_PAGE_RANGE = 3

export default {
  name: 'Pagination',

  components: {
    Paginate,
  },

  props: {
    currentPage: {
      type: Number,
      required: true,
    },

    pageCount: {
      type: Number,
      required: true,
    },

    clickHandler: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      pageRange: PAGE_RANGE,
    }
  },

  watch: {
    currentPage: {
      immediate: true,
      handler(value) {
        this.adjustPageRange(value)
      },
    },
  },

  methods: {
    adjustPageRange(currentPage) {
      const pageRange = screen.width <= 760 ? MOBILE_PAGE_RANGE : PAGE_RANGE

      if (
        currentPage > parseInt(pageRange / 2) + 1 &&
        currentPage < this.pageCount - Math.round(pageRange / 2) + 1
      ) {
        this.pageRange = pageRange - 2
        return
      }

      this.pageRange = pageRange
    },
  },
}
</script>
