import moment from 'moment-timezone'

export default {
  methods: {
    createPeriod(from, to, unit = 'days') {
      let period = []

      for (let m = moment(from); m.isSameOrBefore(to, unit); m.add(1, unit)) {
        period.push(moment(m))
      }

      return period
    },
  },
}
