<template>
  <div>
    <div v-if="arg.level === 0">
      <div
        v-if="arg.view.type !== 'resourceTimelineDay'"
        class="tw-mx-auto tw-rounded-full"
      >
        <div class="tw-text-center weekview-date-wrap">
          <p class="tw-text-gray-400 tw-text-xs tw-font-normal">{{ day }}</p>
          <p class="fc-cell-text-date tw-text-gray-800">{{ date }}</p>
        </div>
      </div>
      <div v-else>
        <p class="fc-cell-text-date tw-text-gray-800">{{ arg.text }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'

export default {
  name: 'DateTimeLabel',
  props: {
    arg: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    day() {
      return moment(this.arg.date)
        .utc()
        .format('ddd')
    },

    date() {
      return moment(this.arg.date)
        .utc()
        .format('D')
    },
  },
}
</script>
