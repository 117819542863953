import { Employments } from '@/api'
import Promise from 'lodash-es/_Promise'

export default class ExampleEmployment {
  static create(count, payload = {}) {
    let employments = []

    for (let i = 1; i <= count; i++) {
      employments.push(
        Employments.create({
          ...payload,
          full_name: `Example ${i}`,
        })
      )
    }

    return Promise.all(employments)
  }
}
