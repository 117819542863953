<template>
  <tr>
    <td data-cy="holiday-location-holiday-table">
      {{ location.name }}
    </td>
    <td data-cy="assigned-emp-holiday-table">
      {{ assignedEmployments }} of
      {{ totalEmployments }}
      <a
        v-if="!assignedToAll"
        class="tw-ml-2 btn-link tw-font-semibold"
        data-cy="assign-to-all-employees"
        @click.prevent="$emit('assign-to-all-employments')"
      >
        Assign to All
      </a>
    </td>
    <HolidaysGroup
      :holiday-group="holidaysByYear"
      @edit-holidays="editHolidays"
      @delete-holidays="deleteHolidays"
      @add-year="addYear"
    />
  </tr>
</template>

<script>
import HolidaysGroup from './HolidaysGroup'
import { maxBy } from 'lodash-es'
import HolidayLocation from '@/models/company/HolidayLocation'

export default {
  name: 'HolidayLocationsTableRow',
  components: { HolidaysGroup },
  props: {
    location: {
      type: HolidayLocation,
      required: true,
    },
  },

  computed: {
    totalEmployments() {
      return this.location.totalEmployments
    },

    assignedEmployments() {
      return this.location.assignedEmployments
    },

    assignedToAll() {
      return this.assignedEmployments == this.totalEmployments
    },

    holidaysByYear() {
      return this.location.holidaysOrderByYear()
    },

    upcomingHolidayYear() {
      const maxYear = maxBy(this.holidaysByYear, 'year').year

      return (parseInt(maxYear) + 1).toString()
    },
  },

  methods: {
    addYear() {
      this.$emit('add-year', this.upcomingHolidayYear)
    },

    editHolidays(year) {
      this.$emit('edit-holidays', {
        year,
        location: this.location,
      })
    },

    deleteHolidays(year) {
      this.$emit('delete-holidays', {
        year,
        location: this.location,
      })
    },
  },
}
</script>
