<template>
  <LeaveWasRequested :event="event" :summary="summary" />
</template>

<script>
import LeaveWasRequested from '@/components/requests/leave/timeline/LeaveWasRequested.vue'
import RequestedEvent from '@/components/requests/leave/events/RequestedEvent'

export default {
  name: 'OneOfBulkLeaveWasRequested',

  components: { LeaveWasRequested },

  props: {
    event: {
      type: RequestedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
