import { sortBy, uniqBy } from 'lodash-es'
import { Departments } from '@/api'

export default {
  computed: {
    allOption() {
      return {
        id: null,
        name: 'All',
      }
    },

    isAdmin() {
      return this.activeEmployment && this.activeEmployment.is_admin
    },

    isApprover() {
      return this.activeEmployment && this.activeEmployment.is_approver
    },

    isNonAdminApprover() {
      return (
        !!this.activeEmployment &&
        !this.activeEmployment.is_admin &&
        this.activeEmployment.is_approver
      )
    },

    selectableDepartments() {
      if (!this.isAdmin && this.isApprover) {
        const departments = this.departments.filter(department => {
          if (department.id === this.activeEmployment.department_id) return true

          return this.activeEmployment.subordinates.some(subordinate => {
            return subordinate.department_id === department.id
          })
        })

        return [this.allOption, ...departments]
      }

      return [this.allOption, ...this.departments]
    },
  },

  methods: {
    async fetchDepartments() {
      try {
        const { data } = await Departments.all({
          company_id: this.activeCompany.id,
        })

        this.departments = data
      } catch ({ response }) {
        this.serverError()
      }
    },

    getSubordinates() {
      const employments = [
        ...this.activeEmployment.subordinates,
        this.activeEmployment,
      ]

      const uniqueEmployments = uniqBy(employments, 'id')

      this.employments = sortBy(
        uniqueEmployments.filter(employment => {
          return (
            !this.reportData.selectedDepartment ||
            !this.reportData.selectedDepartment.id ||
            employment.department_id === this.reportData.selectedDepartment.id
          )
        }),
        'full_name'
      )
    },
  },
}
