<template>
  <OvertimeWasApproved :event="event" :summary="summary" />
</template>

<script>
import OvertimeWasApproved from '@/components/requests/overtime/timeline/OvertimeWasApproved'

export default {
  name: 'OneOfBulkOvertimeWasApproved',

  components: { OvertimeWasApproved },

  props: {
    event: {
      type: Object,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
