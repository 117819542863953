import http from '@/plugins/http'
import HolidayLocationsCollection from '@/models/company/HolidayLocationsCollection'

export default class CompanyHolidayLocations {
  static async getReport(payload) {
    const { data } = await http.get(
      'reports/company-holiday-locations',
      payload
    )

    return new HolidayLocationsCollection(data)
  }
}
