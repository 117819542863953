export default {
  methods: {
    isActiveEmployment(id) {
      return this.activeEmployment && this.activeEmployment.id === id
    },

    isSubordinateOfActiveEmployment(employment) {
      return (
        this.activeEmployment &&
        this.activeEmployment.subordinates.some(subordinate => {
          return subordinate.id === employment.id
        })
      )
    },
  },
}
