<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
      data-cy="setup-company"
    >
      <Component
        :is="component"
        :date="startDate"
        @date-selected="setDate"
        @next="next"
      />
    </div>
  </div>
</template>

<script>
import LeaveYearSelector from '@/components/setup-company/LeaveYearSelector'
import CreateCompany from '@/components/setup-company/CreateCompany'
import InviteUsers from '@/components/setup-company/InviteUsers'
import FormatDate from '@/mixins/FormatDate'
import moment from 'moment-timezone'

export default {
  name: 'SetupCompany',

  layout: 'DefaultLayout',

  middleware: 'auth',

  components: { LeaveYearSelector, CreateCompany, InviteUsers },

  mixins: [FormatDate],

  data: () => ({
    component: 'LeaveYearSelector',
    date: '',
  }),

  computed: {
    startDate() {
      if (this.isFutureDate) {
        return moment(this.date).subtract(1, 'year')
      }

      return this.date
    },

    isFutureDate() {
      return moment(this.date).isAfter(moment())
    },
  },

  created() {
    if (this.$route.query.company) {
      this.component = 'InviteUsers'
    }
  },

  methods: {
    next(component) {
      this.component = component
    },

    setDate(date) {
      this.date = date
    },
  },
}
</script>
