<template>
  <RequestDetails :notification="notification">
    <template v-slot="{ owner, actor }">
      <div
        class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
        @click="$emit('show-more-details')"
      >
        <div class="tw-flex tw-items-center">
          <div>
            <UserAvatar
              :user="notification.getActor()"
              class="tw-block tw-mr-4 notifications-list-avatar"
            ></UserAvatar>
          </div>

          <div>
            <div>
              <div class="notification-message-wrap">
                <span class="tw-font-semibold">{{ actor }}</span> has updated
                <span class="tw-font-semibold">{{ owner }}</span>
                {{ requestType }}.
              </div>
            </div>

            <HappenedOn :time="fromNow" class="tw-mt-1" />
          </div>
        </div>
      </div>
    </template>
  </RequestDetails>
</template>

<script>
import Notification from '@/components/notifications/mixins/Notification'
import HappenedOn from '@/components/notifications/HappenedOn'
import RequestDetails from '@/components/notifications/RequestDetails'

export default {
  name: 'RequestUpdated',

  components: {
    HappenedOn,
    RequestDetails,
  },

  mixins: [Notification],

  props: {
    requestType: {
      type: String,
      required: true,
    },
  },
}
</script>
