<template>
  <div
    class="event-types-picker-option tw-flex tw-justify-between tw-items-center tw-content-center"
    style="margin: -0.75rem -1rem; padding: 0.75rem 1rem;"
  >
    <div
      class="tw-text-base tw-font-normal option-label-text"
      :class="props.option.$isLabel ? 'tw-text-blue-600' : 'tw-text-gray-800'"
    >
      <span
        v-if="props.option.$isLabel"
        class="tw-font-semibold tw-inline-block md:tw-truncate tw-w-full md:tw-w-88 "
      >
        {{ props.option.$groupLabel }}
      </span>

      <span v-else>{{ props.option.name }}</span>
    </div>

    <div class="tw-flex tw-items-center tw-relative">
      <div v-if="props.option.$isLabel" class="tw-hidden md:tw-inline">
        <svg-icon
          v-if="isAllSelectedInGroup"
          name="check-circle-solid"
          class="tw-w-4 tw-h-4 tw-text-blue-500 option-check-icon"
        />

        <span v-else class="tw-w-5 tw-h-5 tw-text-gray-500 option-label-text">
          Select all
        </span>
      </div>

      <div v-else>
        <svg-icon
          name="check-circle-solid"
          class="tw-w-4 tw-h-4 event-type-check-icon"
          :class="
            props.option.is_selected
              ? 'tw-text-green-500 event-type-check-icon-selected'
              : 'tw-text-gray-500 event-type-check-icon-not-selected'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import { size, filter, flatMap } from 'lodash-es'

export default {
  name: 'WebhookEventTypesPickerOption',

  props: {
    props: {
      type: Object,
      required: true,
    },

    selectedEventTypes: {
      type: Array,
      required: true,
    },

    groupedEventTypes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    groupName() {
      return this.props.option.$groupLabel
    },

    availableEventTypes() {
      return flatMap(this.groupedEventTypes, 'event_types')
    },

    eventTypesInGroup() {
      return filter(
        this.availableEventTypes,
        eventType => eventType.group.name === this.groupName
      )
    },

    selectedEventTypesInGroup() {
      return filter(
        this.selectedEventTypes,
        eventType => eventType.group.name === this.groupName
      )
    },

    isAllSelectedInGroup() {
      return (
        size(this.eventTypesInGroup) === size(this.selectedEventTypesInGroup)
      )
    },
  },
}
</script>

<style scoped lang="scss">
.event-types-picker-option {
  @apply .tw-bg-white;

  &:hover {
    @apply .tw-bg-blue-500;

    .option-label-text,
    .option-check-icon,
    .event-type-check-icon {
      @apply .tw-text-white;
    }

    .event-type-check-icon-selected {
      @apply .tw-bg-green-500;
      border-radius: 50%;
    }

    .event-type-check-icon-not-selected {
      @apply .tw-bg-gray-500;
      border-radius: 50%;
    }
  }
}
</style>
