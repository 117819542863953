<template>
  <LeaveWasApproved :event="event" :summary="summary">
    <img
      src="@/assets/icons/logo.svg"
      class="tw-w-10 leavedates-header-logo"
      alt="leavedates-logo"
    />
  </LeaveWasApproved>
</template>

<script>
import LeaveWasApproved from '@/components/requests/leave/timeline/LeaveWasApproved'
import OneOfImportedLeaveWasApprovedEvent from '@/components/requests/leave/events/OneOfImportedLeaveWasApprovedEvent'

export default {
  name: 'OneOfImportedLeaveWasApproved',

  components: { LeaveWasApproved },

  props: {
    event: {
      type: OneOfImportedLeaveWasApprovedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
