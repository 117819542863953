<template>
  <div
    class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden"
  >
    <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
      <h2 class="tw-mb-4 tw-text-gray-800">
        Change Password
      </h2>

      <form class="tw-w-full" @submit.prevent="updatePassword">
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label" for="current_password">
              Current Password<span class="required-field">&#42;</span>
            </label>
            <input
              id="current_password"
              v-model="form.current_password"
              v-validate="'min:6|max:128'"
              name="current_password"
              data-vv-as="current password"
              class="form-control"
              type="password"
              autocomplete="off"
              placeholder="Current Password"
            />
            <p
              v-show="errors.has('current_password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('current_password') }}
            </p>
          </div>
        </div>
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label" for="new_password">
              New Password <span class="required-field">&#42;</span>
            </label>
            <PasswordStrengthMeter
              id="new_password"
              ref="new_password"
              v-model="form.new_password"
              v-validate="'required|min:8|max:128'"
              name="new_password"
              data-vv-as="new password"
              autocomplete="off"
              type="password"
              placeholder="New Password"
            />
            <p
              v-show="errors.has('new_password')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('new_password') }}
            </p>
          </div>
        </div>
        <div class="form-group">
          <div class="tw-w-full">
            <label class="form-label" for="new_password_confirmation">
              Confirm New Password<span class="required-field">&#42;</span>
            </label>
            <input
              id="new_password_confirmation"
              v-model="form.new_password_confirmation"
              v-validate="'required|confirmed:new_password'"
              name="new_password_confirmation"
              data-vv-as="confirm password"
              autocomplete="off"
              class="form-control"
              type="password"
              placeholder="Confirm Password"
            />
            <p
              v-show="errors.has('new_password_confirmation')"
              class="tw-mt-1 tw-text-red-700 tw-text-sm"
            >
              {{ errors.first('new_password_confirmation') }}
            </p>
          </div>
        </div>
        <div class="form-group tw-mb-0">
          <div class="tw-w-full">
            <SpinnerButton
              :disabled="!valid || loading"
              :loading="loading"
              :spinner-only="true"
              type="submit"
            >
              Save
            </SpinnerButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Profile from '@/api/profile/Profile'
import ValidatesForm from '@/mixins/ValidatesForm'
import SpinnerButton from '@/components/SpinnerButton'
import PasswordStrengthMeter from '@/components/PasswordStrengthMeter'

export default {
  name: 'ChangePassword',

  components: {
    PasswordStrengthMeter,
    SpinnerButton,
  },

  mixins: [ValidatesForm],

  data() {
    return {
      loading: false,
      form: {
        current_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
    }
  },

  mounted() {
    const dictionary = {
      custom: {
        new_password_confirmation: {
          confirmed: 'The password confirmation does not match',
        },
      },
    }

    this.$validator.localize('en', dictionary)
  },

  methods: {
    async updatePassword() {
      await this.validate()

      if (!this.valid) return

      this.loading = true

      try {
        await Profile.updatePassword({
          ...this.form,
          company_id: this.activeCompany.id,
        })

        this.success('Password updated successfully.')

        this.form = {
          current_password: null,
          new_password: null,
          new_password_confirmation: null,
        }

        await this.$validator.reset()
      } catch ({ response }) {
        if (response.status === 422) {
          this.validateFromResponse(response, false)
        } else {
          this.error('We are having some issues updating password.')
        }
      }

      this.loading = false
    },
  },
}
</script>
