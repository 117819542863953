var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-container"},[_c('div',{staticClass:"tw-py-10"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-flex-wrap"},[_vm._m(0),_c('div',[_c('SpinnerButton',{attrs:{"data-cy":"btn-add-company","type":"button"},on:{"click":_vm.create}},[_vm._v(" Add Company ")])],1)])]),_c('div',{directives:[{name:"scroll-hint",rawName:"v-scroll-hint"}],staticClass:"table-responsive"},[_c('table',{staticClass:"ld-table ld-table-clickable",attrs:{"data-cy":"company-name-table"}},[_vm._m(1),_c('tbody',[(_vm.ownCompanies.length)?_vm._l((_vm.ownCompanies),function(company){return _c('tr',{key:company.id,on:{"click":function($event){$event.stopPropagation();return _vm.editCompanySettings(company)}}},[_c('td',[_vm._v(_vm._s(company.name))]),_c('td',[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-end"},[_c('button',{staticClass:"btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3",attrs:{"title":"Edit","data-cy":"edit-company"},on:{"click":function($event){$event.stopPropagation();return _vm.editCompanySettings(company)}}},[_c('span',{staticClass:"tw-flex tw-items-center tw-justify-center"},[_c('SvgIcon',{staticClass:"svg-icon tw-w-3 tw-h-3 tw-text-gray-700",attrs:{"name":"edit-pencil"}})],1)]),_c('button',{staticClass:"btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3",attrs:{"title":"Delete","data-cy":"delete-company"},on:{"click":function($event){$event.stopPropagation();return _vm.destroy(company)}}},[_c('span',{staticClass:"tw-flex tw-items-center tw-justify-center"},[_c('SvgIcon',{staticClass:"svg-icon tw-w-3 tw-h-3 tw-text-white",attrs:{"name":"trash"}})],1)])])])])}):_c('tr',[_c('td',{staticClass:"tw-bg-white",attrs:{"colspan":"2","data-cy":"no-company-data"}},[_c('NoData',{attrs:{"message":"You are not an admin of any company"}})],1)])],2)])]),_c('Modal',{attrs:{"classes":[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ],"max-width":480,"name":"company-form","width":"95%","height":"auto","adaptive":"","scrollable":""}},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"tw-flex tw-justify-between"},[_c('div',[_c('p',{staticClass:"modal-title"},[_vm._v("New Company")])]),_c('div',[_c('button',{staticClass:"modal-close",attrs:{"data-cy":"close-company"},on:{"click":function($event){return _vm.$modal.hide('company-form')}}},[_c('SvgIcon',{staticClass:"tw-w-4 tw-h-4",attrs:{"name":"close"}})],1)])])]),_c('CompanyForm',{attrs:{"pick-start-date":true,"locale":_vm.authUser.locale,"loading":_vm.loading},on:{"submit":_vm.createCompany}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"data-cy":"page-title"}},[_c('h2',{staticClass:"page-title"},[_vm._v("Companies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Company Name")]),_c('th')])])
}]

export { render, staticRenderFns }