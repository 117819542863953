<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <DetailEvent v-else :event="event" />
  </div>
</template>

<script>
import DetailEvent from '@/components/requests/overtime/timeline/DetailEvent'
import SummaryEvent from '@/components/requests/overtime/timeline/SummaryEvent'
import OvertimeDeclinedEvent from '@/components/requests/overtime/events/OvertimeDeclinedEvent'

export default {
  name: 'OvertimeWasRejected',

  components: { SummaryEvent, DetailEvent },

  props: {
    event: {
      type: OvertimeDeclinedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
