<template>
  <LeaveWasApproved :event="event" :summary="summary" />
</template>

<script>
import LeaveWasApproved from '@/components/requests/leave/timeline/LeaveWasApproved.vue'
import OneOfBulkLeaveWasImported from '@/components/requests/leave/events/OneOfBulkLeaveWasImportedEvent'

export default {
  name: 'OneOfBulkLeaveWasImported',

  components: { LeaveWasApproved },

  props: {
    event: {
      type: OneOfBulkLeaveWasImported,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
