<template>
  <RequestDetails :notification="notification">
    <template v-slot="{ owner, actor }">
      <div
        class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
        @click="$emit('show-more-details')"
      >
        <div class="tw-flex tw-items-center">
          <div>
            <UserAvatar
              :user="notification.getActor()"
              class="tw-block tw-mr-4 notifications-list-avatar"
            />
          </div>

          <div>
            <div>
              <div
                class="notification-message-wrap"
                data-cy="notification-message"
              >
                <span class="tw-font-semibold">{{ actor }}</span> has retracted

                <span>
                  <span v-if="hasRetractedAction"
                    >the {{ retractedAction }} for
                    <span class="tw-font-semibold">{{ owner }}</span>
                    leave.</span
                  >
                  <span v-else>
                    <span class="tw-font-semibold">{{ owner }}</span>
                    {{ requestType }}.
                  </span>
                </span>
              </div>
            </div>

            <HappenedOn :time="fromNow" class="tw-mt-1" />
          </div>
        </div>
      </div>
    </template>
  </RequestDetails>
</template>

<script>
import Notification from '@/components/notifications/mixins/Notification'
import HappenedOn from '@/components/notifications/HappenedOn'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'
import RequestDetails from '@/components/notifications/RequestDetails'

export default {
  name: 'RequestRetracted',

  components: {
    HappenedOn,
    RequestDetails,
  },

  mixins: [Notification, FormatEmploymentName],

  props: {
    requestType: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasRetractedAction() {
      return this.notification.notification.data.hasOwnProperty(
        'retracted_leave_status'
      )
    },

    retractedAction() {
      const retractStatusMap = {
        approved: 'approval',
        cancelled: 'cancellation',
        rejected: 'decline',
      }

      return retractStatusMap[
        this.notification.notification.data.retracted_leave_status
      ]
    },
  },
}
</script>
