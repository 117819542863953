<template>
  <div>
    <v-select
      id="email-report-scope"
      ref="scopePicker"
      v-validate="'required'"
      :value="value"
      :options="options"
      :multiple="true"
      group-values="data"
      group-label="company"
      track-by="id"
      label="name"
      :max-height="130"
      :show-labels="false"
      :searchable="false"
      :hide-selected="true"
      class="input-chips input-chips_basic input-chips_nested"
      data-vv-name="showing"
      data-vv-as="showing"
      data-cy="showing"
      placeholder=""
      @input="updateScope"
    >
      <template slot="option" slot-scope="{ option }">
        <div class="tw-flex tw-justify-start tw-items-center tw-content-center">
          <div
            class="multiselect-list-item tw-text-base tw-font-normal tw-text-gray-800"
          >
            {{ option.$isLabel ? option.$groupLabel : option.name }}
          </div>
        </div>
      </template>

      <template slot="tag" slot-scope="{ option, remove }">
        <div class="chip-wrapper">
          <div class="chips">
            <div class="chip">
              <div class="title" data-cy="selected-value">
                {{ option.fullName }}
              </div>
              <button class="close" type="button" @click="remove(option)">
                <svg-icon name="close" class="tw-w-2 tw-h-2 tw-text-red-500" />
              </button>
            </div>
          </div>
        </div>
      </template>
    </v-select>
    <p
      v-show="errors.has('showing')"
      class="tw-mt-1 tw-text-red-700 tw-text-sm"
    >
      {{ errors.first('showing') }}
    </p>
  </div>
</template>

<script>
import { last } from 'lodash-es'
import vSelect from 'vue-multiselect'
import ValidatesForm from '../../mixins/ValidatesForm'

export default {
  name: 'EmailReportScopePicker',

  components: {
    vSelect,
  },

  mixins: [ValidatesForm],

  props: {
    value: {
      type: Array,
      default: null,
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateScope(options) {
      const matchingScope = options.find(option => {
        if (option.id === last(options).id) return false

        if (option.company_id) {
          return (
            option.company_id === last(options).company_id ||
            option.company_id === last(options).id
          )
        }

        return (
          option.id === last(options).company_id ||
          option.id === last(options).id
        )
      })

      this.$emit('input', [...options])

      if (matchingScope) {
        this.$nextTick(() => {
          this.$refs.scopePicker.removeElement(matchingScope)
        })
      }
    },
  },
}
</script>
