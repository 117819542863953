import { groupBy, uniqBy } from 'lodash-es'
import moment from 'moment'

export default class HolidayLocation {
  constructor(location) {
    this.id = location.id
    this.name = location.name
    this.country = location.country
    this.region = location.region
    this.companyKey = location.company_id
    this.totalEmployments = location.total_employments
    this.assignedEmployments = location.assigned_employments
    this.holidays = location.holiday_location_events
  }

  holidaysOrderByYear() {
    const holidaysByYear = groupBy(this.holidays, holiday => {
      return moment(holiday.date).year()
    })

    return Object.keys(holidaysByYear).map(year => {
      return { year: year, holidays: holidaysByYear[year] }
    })
  }

  holidaysForYear(year) {
    const holidays = this.holidays.filter(holiday => {
      if (moment(holiday.date).year() === moment(year).year()) {
        return holiday
      }
    })

    return uniqBy(
      holidays,
      holiday => holiday.name + holiday.type + holiday.date
    )
  }
}
