<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <DetailEvent v-else :event="event">
      <img
        v-if="
          !event.isActorApprover() &&
            event.previousEventLeaveStatus() !== 'requested'
        "
        src="@/assets/icons/logo.svg"
        class="tw-w-10 leavedates-header-logo"
        alt="leavedates-logo"
      />
    </DetailEvent>
  </div>
</template>

<script>
import UpdatedEvent from '@/components/requests/leave/events/UpdatedEvent'
import SummaryEvent from '@/components/requests/leave/timeline/SummaryEvent'
import DetailEvent from '@/components/requests/leave/timeline/DetailEvent'

export default {
  name: 'LeaveWasUpdated',

  components: { DetailEvent, SummaryEvent },

  props: {
    event: {
      type: UpdatedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
