<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <div v-else class="tw-flex">
      <UserAvatar :user="event.getActor()" class="tw-mt-2 tw-mr-2" />

      <div
        class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none"
        :class="eventBodyClasses"
      >
        <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
          <EventText
            :text="event.asText(activeEmployment)"
            :event-text-class="eventTextClass"
          />

          <div class="tw-text-sm tw-text-gray-600">
            {{ event.getCreatedTime() }}
          </div>
        </div>

        <div class="tw-flex tw-items-center tw-justify-between">
          <AttachmentPreview
            :attachment="attachment"
            :is-deleted="event.isDeleted()"
          />

          <button
            v-if="!event.isDeleted()"
            class="tw-mx-4 tw-p-1 tw-flex tw-items-center tw-border-none tw-outline-none"
            type="button"
            @click="downloadAttachment"
          >
            <SvgIcon class="tw-w-5 tw-h-5 tw-text-gray-800" name="download" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileSaver from 'file-saver'
import SummaryEvent from '@/components/requests/leave/timeline/SummaryEvent'
import AttachmentPreview from '@/components/AttachmentPreview'
import ValidatesForm from '@/mixins/ValidatesForm'
import AttachmentAddedEvent from '@/components/requests/leave/events/AttachmentAddedEvent'
import { Attachments } from '@/api'
import EventText from '@/components/requests/EventText'

export default {
  name: 'LeaveAttachmentWasAdded',

  components: { EventText, SummaryEvent, AttachmentPreview },

  mixins: [ValidatesForm],

  props: {
    event: {
      type: AttachmentAddedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },

  computed: {
    eventTextClass() {
      if (this.event.isDeleted()) {
        return 'tw-text-red-900'
      }

      return 'tw-text-gray-700'
    },

    attachment() {
      return this.event.getResource()
    },

    eventBodyClasses() {
      if (this.event.isDeleted()) {
        return ['tw-bg-red-100']
      }

      if (this.event.viewerIsActor(this.activeEmployment)) {
        return ['tw-bg-gray-300']
      }

      return ['tw-bg-purple-100']
    },
  },

  methods: {
    async downloadAttachment() {
      try {
        const { data } = await Attachments.download(
          this.attachment,
          this.$route.query
        )

        FileSaver.saveAs(
          new Blob([data], { type: this.attachment.mime_type }),
          this.attachment.name
        )
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },
  },
}
</script>
