<template>
  <BaseFooter>
    <div
      slot-scope="{ privacyUrl, termsUrl, cookieUrl, currentYear }"
      class="tw-w-full"
    >
      <div class="tw-text-center tw-px-4 tw-mb-6">
        <img
          src="./../assets/img/leavedates-whoop-strapline-stacked.svg"
          class="tw-my-6 tw-mx-auto leavedates-whoop-strapline-stacked"
        />

        <p class="tw-mb-2 tw-text-gray-900 tw-text-sm">
          Copyright &#169; {{ currentYear }} Leave Dates. All Rights Reserved.
        </p>

        <div class="tw-text-xs tw-space-x-4">
          <BaseLink theme="lightGray" :href="cookieUrl">
            Cookie Policy
          </BaseLink>

          <BaseLink theme="lightGray" :href="termsUrl">
            Our Terms of Use
          </BaseLink>

          <BaseLink theme="lightGray" :href="privacyUrl">
            Privacy
          </BaseLink>
        </div>
      </div>
    </div>
  </BaseFooter>
</template>

<script>
import BaseFooter from '@/components/BaseFooter'
import BaseLink from '@/components/BaseLink'

export default {
  name: 'AuthFooter',

  components: { BaseFooter, BaseLink },
}
</script>
<style scoped>
.leavedates-whoop-strapline-stacked {
  height: 40px;
}
</style>
