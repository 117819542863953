<template>
  <OvertimeWasRequested :event="event" :summary="summary" />
</template>

<script>
import OvertimeWasRequested from '@/components/requests/overtime/timeline/OvertimeWasRequested'
import OvertimeRequestedEvent from '@/components/requests/overtime/events/OvertimeRequestedEvent'

export default {
  name: 'OneOfBulkOvertimeWasRequested',

  components: { OvertimeWasRequested },

  props: {
    event: {
      type: OvertimeRequestedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
