<template>
  <div
    class="tw-flex tw-justify-start tw-items-center tw-content-center tw-p-2 tw-pl-0 employee-list_wrap"
    data-cy="wall-chart-emp-wrapper"
  >
    <UserAvatar :user="employment" class="tw-mr-2" />
    <div class="tw-overflow-hidden">
      <div
        class="tw-flex tw-flex-wrap tw-flex-col"
        data-cy="wall-chart-employees"
      >
        <MyYearPageLink :employee="employment">
          <span
            :title="employment.full_name"
            class="tw-font-normal avatar-title"
          >
            {{ formattedFullName }}
          </span>
        </MyYearPageLink>
        <span
          v-if="employment.department"
          :title="employment.department.name"
          class="tw-text-xs tw-text-gray-700 tw-truncate tw-cursor-default department-name"
          style="max-width: 100%;"
        >
          {{ employment.department.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'
import LeavePermissions from '@/mixins/LeavePermissions'
import ActiveEmployment from '@/mixins/ActiveEmployment'
import Employment from '@/models/employment/Employment'
import MyYearPageLink from '@/components/MyYearPageLink'

export default Vue.extend({
  name: 'Employment',
  components: { MyYearPageLink },

  mixins: [FormatEmploymentName, LeavePermissions, ActiveEmployment],

  props: {
    employment: {
      type: Employment,
      required: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formattedFullName() {
      if (this.compact) {
        return this.compactFullName(this.employment)
      }

      return this.fullName(this.employment)
    },
  },
})
</script>

<style>
.avatar-title {
  font-size: 0.95em;
}
</style>
