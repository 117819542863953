<template>
  <div class="card card-container">
    <div class="tw-py-10">
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
        <div>
          <h2 class="page-title">Webhooks</h2>
        </div>

        <div class="tw-flex tw-flex-wrap">
          <div>
            <SpinnerButton type="button" @click="showCreateWebhookForm">
              Add Endpoint
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="tw-mb-2">
        Webhooks are a way for developers to integrate Leave Dates with 3rd
        party products.
      </p>

      <p class="tw-mb-4">
        Every time a certain event occurs (for example, a new leave is
        requested) we will send the data to your chosen destination URL.

        <BaseLink :href="webhooksDocumentationUrl">More info</BaseLink>
      </p>

      <div v-scroll-hint class="table-responsive  settings-table">
        <WebhooksTable
          :webhooks="webhooks"
          @edit-webhook="editWebhook"
          @delete-webhook="deleteWebhook"
          @show-webhook-call-events="showWebhookCallEvents"
        />
      </div>
    </div>

    <Modal
      id="modal-webhook"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-webhook"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-open="$validator.resume()"
      @before-close="resetWebhookForm"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <p class="modal-title">{{ editing ? 'Edit' : 'New' }} Webhook</p>

          <button class="modal-close" @click="hideWebhookModel">
            <SvgIcon name="close" class="tw-w-4 tw-h-4" />
          </button>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form id="webhookForm" class="tw-w-full" @submit.prevent="submit">
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="url">
                Url <span class="required-field">&#42;</span>
              </label>

              <input
                id="url"
                v-model="form.url"
                v-validate="'required'"
                :placeholder="webhookUrlPlaceholder"
                data-vv-name="url"
                data-vv-as="url"
                class="form-control"
                type="text"
                autocomplete="off"
                tabindex="1"
              />

              <p
                v-show="errors.has('url')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('url') }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label">
                Events <span class="required-field">&#42;</span>
              </label>

              <div class="tw-relative">
                <WebhookEventTypesPicker
                  v-model="selectedEventTypes"
                  :event-types="availableEventTypes"
                />
              </div>

              <p
                v-show="errors.has('event_types')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('event_types') }}
              </p>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="!valid || loading"
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                  tabindex="2"
                >
                  {{ editing ? 'Update' : 'Create' }}
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>

    <Modal
      id="modal-webhook-call-events"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="1000"
      name="modal-webhook-call-events"
      width="95%"
      height="750"
      adaptive
      scrollable
      @before-close="resetWebhookCallEvents"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">Webhook Call Events</p>
          </div>

          <div data-cy="edit-provider-close">
            <button class="modal-close" @click="hideWebhookCallEventsModal">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-p-6">
        <Loading
          class="tw-outline-none"
          :blur="null"
          loader="dots"
          :is-full-page="false"
          :active="isPageLoading"
        />

        <div class="tw-flex tw-w-full tw-space-x-6">
          <div class="tw-w-1/2">
            <h4 class="tw-text-gray-600 tw-uppercase tw-font-semibold">
              Webhook
            </h4>

            <span
              v-if="selectedWebhook"
              class="page-title tw-text-xl tw-mt-2 tw-block tw-truncate"
              style="max-width: 470px;"
              :title="selectedWebhook.url"
            >
              {{ selectedWebhook.url }}
            </span>

            <SpinnerButton
              :disabled="callEventsFetching"
              :loading="callEventsFetching"
              :spinner-only="true"
              theme="white"
              :spinner-classes="['tw-text-gray-700']"
              class="tw-mt-3 tw-px-3 tw-py-2"
              @click="fetchWebhookCallEvents(selectedWebhook)"
            >
              <div class="tw-flex tw-items-center">
                <SvgIcon
                  name="refresh"
                  class="tw-w-4 tw-h-4 tw-text-gray-700 tw-mr-2"
                />

                Refresh
              </div>
            </SpinnerButton>
          </div>

          <div class="tw-w-1/2">
            <h4 class="tw-text-gray-600 tw-uppercase tw-font-semibold">
              Send test event
            </h4>

            <form
              class="tw-flex tw-items-center tw-w-full tw-mt-2"
              @submit.prevent="submitFakeEvent"
            >
              <div class="tw-w-1/2">
                <WebhookSingleEventTypePicker
                  id="webhook-event-type"
                  v-model="form.event_type"
                  :options="selectableEventTypes"
                  style="max-height: 36px"
                />

                <p
                  v-show="errors.has('webhook-event-type')"
                  class="tw-mt-1 tw-text-red-700 tw-text-sm"
                >
                  {{ errors.first('webhook-event-type') }}
                </p>
              </div>

              <div class="tw-w-1/2">
                <SpinnerButton
                  :disabled="!form.event_type || fakeEventSubmitting"
                  :loading="fakeEventSubmitting"
                  :spinner-only="true"
                  theme="white"
                  :spinner-classes="['tw-text-gray-700']"
                  class="tw-ml-3 tw-px-3 tw-py-0 tw-h-10"
                  type="submit"
                >
                  Send
                </SpinnerButton>
              </div>
            </form>
          </div>
        </div>

        <div class="tw-mt-6 tw-border-t tw-border-gray-350"></div>

        <div class="tw-flex tw-w-full tw-space-x-6 tw-mt-6">
          <WebhookCallEventsTable
            class="tw-w-1/2"
            :events="webhookCallEvents"
            :selected-event="selectedWebhookCallEvent"
            @show-webhook-call-event-data="showWebhookCallEventData"
          />

          <div v-if="webhookCallEventData" class="tw-w-1/2">
            <WebhookCallEventData
              style="max-height: 550px"
              :data="webhookCallEventData"
              @webhook-call-event-resent="
                fetchWebhookCallEvents(selectedWebhook)
              "
            />
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import ValidatesForm from '@/mixins/ValidatesForm'
import WebhooksTable from '@/components/integrations/webhooks/WebhooksTable'
import WebhookCallEventData from '@/components/integrations/webhooks/WebhookCallEventData'
import WebhookCallEventsTable from '@/components/integrations/webhooks/WebhookCallEventsTable'
import WebhookEventTypesPicker from '@/components/integrations/webhooks/WebhookEventTypesPicker'
import WebhookSingleEventTypePicker from '@/components/integrations/webhooks/WebhookSingleEventTypePicker'
import SpinnerButton from '@/components/SpinnerButton'
import BaseLink from '@/components/BaseLink'
import Loading from 'vue-loading-overlay'
import documentationUrls from '@/documentations/documentation-urls'
import { filter, first, map } from 'lodash-es'

const AVAILABLE_EVENT_TYPES = [
  {
    id: 1,
    name: 'leave.requested',
    group: { id: 1, name: 'Leave' },
  },
  {
    id: 2,
    name: 'leave.updated',
    group: { id: 1, name: 'Leave' },
  },
  {
    id: 3,
    name: 'leave.approved',
    group: { id: 1, name: 'Leave' },
  },
  {
    id: 4,
    name: 'leave.rejected',
    group: { id: 1, name: 'Leave' },
  },
  {
    id: 5,
    name: 'leave.canceled',
    group: { id: 1, name: 'Leave' },
  },
]

export default {
  name: 'Webhooks',

  components: {
    WebhooksTable,
    WebhookCallEventData,
    WebhookCallEventsTable,
    WebhookEventTypesPicker,
    WebhookSingleEventTypePicker,
    SpinnerButton,
    BaseLink,
    Loading,
  },

  mixins: [ValidatesForm],

  data() {
    return {
      editing: false,
      loading: false,
      fakeEventSubmitting: false,
      callEventsFetching: false,
      form: {
        id: null,
        url: null,
        event_types: [],
        event_type: null,
      },
      webhooks: [],
      webhookCallEvents: [],
      selectedWebhook: null,
      selectedWebhookCallEvent: null,
      webhookCallEventData: null,
      isPageLoading: false,
    }
  },

  computed: {
    webhooksDocumentationUrl() {
      return documentationUrls.webhookUrl
    },

    availableEventTypes() {
      return AVAILABLE_EVENT_TYPES
    },

    webhookUrlPlaceholder() {
      return 'https://dev-12345.example.com'
    },

    selectableEventTypes() {
      if (!this.selectedWebhook) {
        return []
      }

      return this.selectedWebhook.event_types
    },

    selectedEventTypes: {
      get() {
        return filter(this.availableEventTypes, availableEventType =>
          this.form.event_types.some(
            selectedEventType => selectedEventType === availableEventType.name
          )
        )
      },

      set(selectedEventTypes) {
        this.form.event_types = map(selectedEventTypes, 'name')
      },
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(companyId) {
        this.fetchWebhooks(companyId)
      },
    },
  },

  methods: {
    setSelectedEventTypes(eventTypes) {
      this.form.event_types = map(eventTypes, 'name')
    },

    showCreateWebhookForm() {
      this.$modal.show('modal-webhook')
    },

    async showWebhookCallEvents(webhook) {
      this.isPageLoading = true

      this.$modal.show('modal-webhook-call-events')

      await this.fetchWebhookCallEvents(webhook)

      this.selectedWebhook = webhook

      if (this.webhookCallEvents.length) {
        await this.showWebhookCallEventData(first(this.webhookCallEvents))
      }

      this.isPageLoading = false
    },

    async showWebhookCallEventData(webhookCallEvent) {
      await this.fetchWebhookCallEventData(webhookCallEvent)

      this.selectedWebhookCallEvent = webhookCallEvent
    },

    async fetchWebhooks(companyId) {
      this.loading = true

      try {
        const { data } = await this.$http.get('webhooks', {
          params: {
            company_id: companyId,
          },
        })

        this.webhooks = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async fetchWebhookCallEvents(webhook) {
      this.loading = true
      this.callEventsFetching = true

      try {
        const { data } = await this.$http.get(
          `webhooks/${webhook.id}/webhook-call-events`,
          {
            params: {
              company_id: this.activeCompany.id,
            },
          }
        )

        this.webhookCallEvents = data
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.callEventsFetching = false
      this.loading = false
    },

    async fetchWebhookCallEventData(webhookCallEvent) {
      this.loading = true

      try {
        const { data } = await this.$http.get(
          `webhook-call-events/${webhookCallEvent.id}/payload`,
          {
            params: {
              company_id: this.activeCompany.id,
            },
          }
        )

        this.webhookCallEventData = data
      } catch ({ response }) {
        this.validateFromResponse(response, true)
      }

      this.loading = false
    },

    async submit() {
      await this.validate()

      if (!this.valid) {
        return
      }

      await this.submitWebhooksForm()

      await this.fetchWebhooks(this.activeCompany.id)
    },

    async submitWebhooksForm() {
      this.loading = true

      try {
        const method = this.editing ? 'put' : 'post'
        const url = this.editing ? `webhooks/${this.form.id}` : 'webhooks'

        await this.$http[method](url, {
          company_id: this.activeCompany.id,
          url: this.form.url,
          event_types: this.form.event_types,
        })

        this.success(
          `Webhook ${this.editing ? 'updated' : 'created'} successfully.`
        )

        this.hideWebhookModel()
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async submitFakeEvent() {
      await this.validate()

      if (!this.valid) {
        return
      }

      this.fakeEventSubmitting = true

      try {
        await this.$http.post(
          `webhooks/${this.selectedWebhook.id}/fake/${this.form.event_type}`,
          {
            company_id: this.activeCompany.id,
          }
        )

        this.success('Test event sent successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.fakeEventSubmitting = false

      await this.fetchWebhookCallEvents(this.selectedWebhook)
    },

    hideWebhookModel() {
      this.$modal.hide('modal-webhook')

      this.resetWebhookForm()
    },

    hideWebhookCallEventsModal() {
      this.$modal.hide('modal-webhook-call-events')

      this.resetWebhookCallEvents()
    },

    resetWebhookForm() {
      this.$validator.pause()

      this.form.url = null
      this.form.event_types = []

      this.editing = false

      this.$validator.reset()

      this.errors.clear()
    },

    resetWebhookCallEvents() {
      this.selectedWebhook = null

      this.webhookCallEvents = []

      this.webhookCallEventData = null

      this.form.event_type = null
    },

    editWebhook(webhook) {
      this.editing = true

      this.form = {
        id: webhook.id,
        url: webhook.url,
        event_types: webhook.event_types,
      }

      this.$modal.show('modal-webhook')
    },

    async deleteWebhook(webhook) {
      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      this.loading = true

      try {
        await this.$http.delete('webhooks/' + webhook.id, {
          params: {
            company: this.activeCompany.id,
          },
        })

        this.success('Webhook removed successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false

      await this.fetchWebhooks(this.activeCompany.id)
    },
  },
}
</script>
