<template>
  <div>
    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2 class="page-title tw-flex tw-items-center tw-mb-1">
              <span>Holiday Management</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Automatically create holidays when the leave year finishes.
                </div>
              </ExtraInfo>
            </h2>
            <p class="tw-mt-1">Holidays are created at midnight UTC</p>
          </div>
        </div>
      </div>

      <form class="tw-w-full" @submit.prevent="changeHolidaySettings">
        <div class="form-group">
          <div class="tw-w-full tw-flex tw-items-center">
            <ToggleButton
              :value="holidaySettings.manage_automatically"
              :sync="true"
              :labels="true"
              class="tw-mr-4"
              color="#1da1f2"
              @change="toggleUpcomingCompanyHolidaysSettings($event.value)"
            />
            <span>Enable automatic management of holidays</span>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label tw-mb-4" for="create_before_months">
            Create holidays
          </label>
          <div class="tw-w-full tw-flex tw-items-center">
            <input
              id="create_before_months"
              v-model="holidaySettings.create_before_months"
              v-validate="'required|numeric'"
              :disabled="!holidaySettings.manage_automatically"
              name="create_before_months"
              data-vv-as="months in advance"
              class="form-control tw-mr-4 tw-w-24"
              type="number"
              min="1"
              max="12"
              inputmode="numeric"
            />
            <div>
              Months in advance <span class="required-field">&#42;</span>
            </div>
          </div>
          <p
            v-show="errors.has('create_before_months')"
            class="tw-mt-3 tw-text-red-700 tw-text-sm"
          >
            Please enter a whole number of months.
          </p>
        </div>

        <SpinnerButton
          :disabled="
            !valid ||
              modifyingHolidaySettings ||
              !holidaySettings.manage_automatically
          "
          :loading="modifyingHolidaySettings"
          :spinner-only="true"
          class="tw-mb-6"
          type="submit"
        >
          Save
        </SpinnerButton>
      </form>
    </div>

    <div class="card card-container">
      <div class="tw-py-10">
        <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
          <div>
            <h2
              class="page-title tw-flex tw-items-center"
              data-cy="holidays-title"
            >
              <span>Holidays</span>
              <ExtraInfo icon="question" class="tw--mt-2">
                <div class="tw-p-4 tw-w-48">
                  Import public holidays to display them on your employee's
                  calendars and the wall chart. Public holidays will be treated
                  as non-working days and leave booked over them will not be
                  deducted from an allowance.
                  <div>
                    <a
                      class="btn-link tw-font-semibold"
                      target="_blank"
                      href="https://help.leavedates.com/hc/en-us/articles/360008905000-Importing-and-using-public-holidays"
                      >More info</a
                    >
                  </div>
                </div>
              </ExtraInfo>
            </h2>
          </div>
          <div>
            <SpinnerButton
              data-cy="add-holidays-button"
              type="button"
              @click="addHolidays"
            >
              Add Holiday
            </SpinnerButton>
          </div>
        </div>
      </div>

      <div v-scroll-hint class="table-responsive settings-table">
        <HolidayLocationsTable
          :locations="holidayLocations"
          :loading="loadingHolidayLocations"
          @edit-holidays="editHolidays"
          @delete-holidays="deleteHolidays"
          @assign-to-all-employments="assignToAllEmployments"
          @add-year="addHolidayByYear"
        />
      </div>

      <Modal
        id="location-year-form"
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="480"
        name="location-year-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="modal-title">
                New Holidays
              </p>
            </div>
            <div data-cy="holidays-close">
              <button
                class="modal-close"
                @click="$modal.hide('location-year-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <LocationYearForm
          :countries="countries"
          :states="sortedHolidayStates"
          :selected-location="selectedLocation"
          :selected-year="selectedYear"
          :loading="loading"
          :custom-states="customStates"
          @location-year-changed="fetchHolidayRegions"
          @submit="createHolidays"
        />
      </Modal>

      <Modal
        id="holidays-form"
        :classes="[
          'tw-shadow-md',
          'tw-bg-white',
          'tw-rounded-lg',
          'modal-overflow-visible',
        ]"
        :max-width="650"
        name="holidays-form"
        width="95%"
        height="auto"
        adaptive
        scrollable
      >
        <div class="modal-header">
          <div class="tw-flex tw-justify-between tw-items-center">
            <div class="sm:tw-flex tw-items-center">
              <div class="modal-title" data-cy="holidays-year-label">
                Holidays
                <span v-if="selectedYear">{{ selectedYear }}</span>
                - {{ selectedLocation.name }}
              </div>
            </div>
            <div class="tw-flex tw-items-center">
              <button
                class="tw-opacity-50 hover:tw-opacity-100 modal-close"
                data-cy="btn-holiday-close"
                @click="$modal.hide('holidays-form')"
              >
                <SvgIcon name="close" class="tw-w-4 tw-h-4" />
              </button>
            </div>
          </div>
        </div>

        <HolidaysForm
          v-if="selectedLocation"
          :location="selectedLocation"
          :year="selectedYear"
          :is-saving="isSaving"
          @add-holiday="storeHoliday"
          @remove-holiday="removeHoliday"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ValidatesForm from '@/mixins/ValidatesForm'
import { first, flatMap } from 'lodash-es'
import { ToggleButton } from 'vue-js-toggle-button'
import HolidaysShortcuts from '@/mixins/page-shortcuts/settings/HolidaysShortcuts'
import SpinnerButton from '@/components/SpinnerButton'
import ExtraInfo from '@/components/ExtraInfo'
import HolidaysForm from '@/components/holidays/HolidaysForm'
import LocationYearForm from '@/components/holidays/LocationYearForm'
import HolidayLocationsTable from '@/components/holidays/HolidayLocationsTable'
import HolidaySettings from '@/api/company/HolidaySettings'
import Holidays from '@/api/company/Holidays'
import HolidayLocations from '@/api/company/HolidayLocations'
import CompanyHolidayLocations from '@/api/reporting/CompanyHolidayLocations'

export default {
  name: 'Holidays',

  components: {
    SpinnerButton,
    ExtraInfo,
    HolidaysForm,
    LocationYearForm,
    HolidayLocationsTable,
    ToggleButton,
  },

  mixins: [HolidaysShortcuts, ValidatesForm],

  data() {
    return {
      countries: [],
      holidayLocations: {},
      regions: [],
      selectedLocation: '',
      selectedYear: '',
      editing: false,
      loading: false,
      isSaving: false,
      modifyingHolidaySettings: false,
      holidaysFormSubmitting: false,
      customStates: {
        all: 'All',
        nationalHolidaysOnly: 'Exclude regional holidays',
      },
      loadingHolidayLocations: false,
      holidaySettings: {
        manage_automatically: true,
        create_before_months: 3,
      },
    }
  },

  computed: {
    sortedHolidayStates() {
      const states = [...this.regions].sort()

      if (!states.length) return [this.customStates.all]

      if (
        this.selectedLocation.name &&
        this.selectedLocation.name.toUpperCase() === 'UNITED KINGDOM'
      )
        return states

      return [this.customStates.nationalHolidaysOnly, ...states]
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchHolidaysWithLocations()
      },
    },
  },

  created() {
    this.fetchCountries()
    this.fetchHolidaySettings()
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    reset() {
      this.$nextTick(() => {
        this.selectedLocation = {}
        this.selectedYear = ''
        this.editing = false
      })
    },

    async fetchCountries() {
      try {
        const { data } = await this.$http.get('countries')

        this.countries = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    async fetchHolidaysWithLocations() {
      this.loadingHolidayLocations = true

      try {
        this.holidayLocations = await CompanyHolidayLocations.getReport({
          params: {
            company: this.activeCompany.id,
          },
        })
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loadingHolidayLocations = false
    },

    addHolidays() {
      this.reset()

      this.$modal.show('location-year-form')
    },

    addHolidayByYear({ year, location }) {
      this.selectedLocation = location
      this.selectedYear = year

      this.$modal.show('location-year-form')
    },

    async createHolidays({ country, year, state, locationName }) {
      this.loading = true

      let existingLocation = this.holidayLocations.findLocation({
        name: locationName,
        country: country.name,
        region: state,
      })

      let locationKey = existingLocation?.id

      try {
        if (!locationKey) {
          const data = await HolidayLocations.save({
            company_id: this.activeCompany.id,
            name: locationName,
            country: country.name,
            region: state,
          })

          locationKey = data.location.id
        }

        await Holidays.save(locationKey, {
          company_id: this.activeCompany.id,
          year,
        })

        await this.fetchHolidaysWithLocations()

        this.success('Holidays saved successfully.')
        this.$modal.hide('location-year-form')

        let location = this.holidayLocations.findByKey(locationKey)

        await this.editHolidays({ location, year })
      } catch ({ response }) {
        if (response.status === 422) {
          if (response.data.hasOwnProperty('message')) {
            this.error(response.data.message, 'Oops...', { duration: 8000 })
          } else {
            const errors = flatMap(response.data.errors, errors => errors[0])

            this.error(first(errors))
          }
        }
      }

      this.loading = false
    },

    async editHolidays({ location, year }) {
      this.selectedLocation = location
      this.selectedYear = year
      this.$modal.show('holidays-form')
    },

    async deleteHolidays({ year, location }) {
      const confirmed = await this.confirm(
        'Are you sure you want to delete?',
        'Confirm delete'
      )

      if (!confirmed) return

      this.selectedLocation = location

      this.loading = true

      await HolidayLocations.delete(location.id, {
        data: {
          company_id: this.activeCompany.id,
          year,
        },
      })

      await this.fetchHolidaysWithLocations()

      this.success('Holidays deleted successfully.')

      this.loading = false
    },

    async fetchHolidayRegions({ year, country }) {
      this.loading = true

      this.selectedYear = year

      try {
        const holidayRegions = await this.$http.get('holiday-regions', {
          params: {
            country_iso: country.iso_code,
            year: this.selectedYear ? this.selectedYear : year,
          },
        })

        this.regions = holidayRegions.data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    async storeHoliday({ holiday, location }) {
      this.loading = true
      this.isSaving = true

      try {
        await Holidays.save(location.id, {
          company_id: this.activeCompany.id,
          holidays: [
            {
              name: holiday.name,
              date: holiday.date.format('YYYY-MM-DD'),
              type: holiday.type,
            },
          ],
        })

        await this.fetchHolidaysWithLocations()

        this.selectedLocation = this.holidayLocations.findByKey(location.id)

        this.success('Holiday added successfully.')
      } catch ({ response }) {
        if (response.status === 422) {
          if (response.data.hasOwnProperty('message')) {
            this.error(response.data.message, 'Oops...', { duration: 8000 })
          } else {
            const errors = flatMap(response.data.errors, errors => errors[0])

            this.error(first(errors))
          }
        }
      }

      this.loading = false
      this.isSaving = false
    },

    async removeHoliday(holiday) {
      this.loading = true

      await Holidays.remove(this.activeCompany, holiday)

      await this.fetchHolidaysWithLocations()

      this.selectedLocation = this.holidayLocations.findByKey(
        holiday.location_id
      )

      this.success('Holiday removed successfully.')

      this.loading = false
    },

    async assignToAllEmployments(location) {
      if (this.hasDifferentHolidayLocations()) {
        const confirmed = await this.confirm(
          `Are you sure you want to assign the ${location.name} holidays to ALL employees? This cannot be undone.`,
          'Confirm assignment'
        )

        if (!confirmed) return
      }

      await this.assignHolidayLocations(location)
    },

    hasDifferentHolidayLocations() {
      return this.holidayLocations.length() > 1
    },

    getHolidayLocation(holidayGroup) {
      if (holidayGroup.state === '') {
        return holidayGroup.location
      }

      return `${holidayGroup.location} (${holidayGroup.state})`
    },

    async assignHolidayLocations(location) {
      this.selectedLocation = location

      await this.$http.put('company-holiday-locations/employments', {
        company_id: this.activeCompany.id,
        location: location.name,
      })

      await this.fetchHolidaysWithLocations()

      await this.fetchUser()
    },

    fetchHolidaySettings() {
      this.holidaySettings = this.activeCompany.holiday_settings
    },

    async changeHolidaySettings() {
      await this.validate()

      if (!this.valid) return

      this.modifyingHolidaySettings = true

      try {
        await this.enableUpcomingCompanyHolidaySettings()

        await this.fetchUser()

        this.success('Holiday settings saved successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.modifyingHolidaySettings = false
    },

    async toggleUpcomingCompanyHolidaysSettings(value) {
      this.holidaySettings.manage_automatically = value

      this.modifyingHolidaySettings = true

      value
        ? await this.enableUpcomingCompanyHolidaySettings()
        : await this.disableUpcomingCompanyHolidaySettings()

      await this.fetchUser()

      const status = value ? 'enabled' : 'disabled'
      this.success(`Automatic holiday ${status} successfully.`)

      this.modifyingHolidaySettings = false
    },

    async enableUpcomingCompanyHolidaySettings() {
      await HolidaySettings.enable(this.activeCompany, {
        holiday_settings: {
          manage_automatically: this.holidaySettings.manage_automatically,
          create_before_months: this.holidaySettings.create_before_months,
        },
      })
    },

    async disableUpcomingCompanyHolidaySettings() {
      await HolidaySettings.disable(this.activeCompany)
    },
  },
}
</script>
