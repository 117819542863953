<template>
  <tr @click.stop="$emit('edit')">
    <td>{{ companyOrDepartment }}</td>
    <td>{{ leaveLimit.amount }}</td>
    <td>{{ from }}</td>
    <td>{{ to }}</td>
    <td>
      <div class="tw-flex tw-items-center tw-justify-end">
        <button
          class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
          title="Edit"
          data-cy="btn-edit-limit"
          @click.stop="$emit('edit')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="edit-pencil"
              class="svg-icon tw-w-3 tw-h-3 tw-text-gray-700"
            />
          </div>
        </button>
        <button
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          title="Delete"
          data-cy="btn-delete-limit"
          @click.stop="$emit('destroy')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="trash"
              class="svg-icon tw-w-3 tw-h-3 tw-text-white"
            />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import FormatDate from '../../mixins/FormatDate'

export default {
  name: 'LimitsTableRow',

  mixins: [FormatDate],

  props: {
    leaveLimit: {
      type: Object,
      required: true,
    },
  },

  computed: {
    companyOrDepartment() {
      return this.leaveLimit.department
        ? this.leaveLimit.department.name
        : `Company`
    },

    from() {
      if (!this.leaveLimit.from) {
        return ''
      }

      return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
        this.leaveLimit.from,
        'utc'
      )
    },

    to() {
      if (!this.leaveLimit.to) {
        return ''
      }

      return this.formatOfDayReadableShortDayNumberShortMonthNumberYearNumberWithSlash(
        this.leaveLimit.to,
        'utc'
      )
    },
  },
}
</script>
