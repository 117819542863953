<template>
  <div>
    <div v-show="false" ref="tooltip">
      <div>
        <div class="tw-p-4" data-cy="birthday">
          <p class="tw-font-semibold">
            {{ title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
  name: 'Birthday',

  props: {
    birthday: {
      type: Object,
      required: true,
    },

    myLeave: {
      type: Boolean,
    },

    element: {
      type: HTMLElement,
      default: () => {},
    },
  },

  computed: {
    title() {
      let name = this.birthday.employmentName

      return name ? `${name}'s birthday` : 'n/a'
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.tippy = tippy.one(this.$el.parentElement, {
        html: this.$refs.tooltip.firstChild,
        interactive: true,
        distance: -33,
        theme: 'light',
        animation: 'scale',
        placement: 'bottom',
        arrow: true,
        delay: [400, 0],
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    })
  },
}
</script>
