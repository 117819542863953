<template>
  <div class="tw-flex tw-flex-col tw-space-y-6">
    <CalendarService
      v-for="service in services"
      :key="service.id"
      :service="service"
      :integrated-calendars="calendarServices"
      @disconnected="calendar => $emit('disconnected', calendar)"
    />
  </div>
</template>

<script>
import CalendarService from '@/components/calendar-services/CalendarService'
import googleCalendarLogo from '@/assets/img/google-calendar-logo.png'
import outlookCalendarLogo from '@/assets/img/outlook-calendar-logo.png'

export default {
  name: 'CalendarServices',

  components: {
    CalendarService,
  },

  props: {
    calendarServices: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      services: [
        {
          id: 'google_calendar',
          name: 'Google Calendar',
          logo: googleCalendarLogo,
        },
        {
          id: 'outlook_calendar',
          name: 'Outlook Calendar',
          logo: outlookCalendarLogo,
        },
      ],
    }
  },
}
</script>
