<template>
  <tr @click.stop="$emit('show-data')">
    <td ref="target" style="width: 60px">
      <BaseTag :theme="eventStatusColor" class="tw-my-1">
        <svg-icon :name="eventStatusIcon" class="tw-w-4 tw-h-4" />
      </BaseTag>

      <div v-show="false" ref="content">
        <div class="tw-p-4 tw-w-48">
          <p>{{ eventStatusTooltip }}</p>
        </div>
      </div>
    </td>

    <td style="width: 200px">{{ event.subscribed_event_type }}</td>

    <td style="width: 200px">{{ event.created_at }}</td>
  </tr>
</template>

<script>
import tippy from 'tippy.js'
import BaseTag from '@/components/BaseTag'

export default {
  name: 'WebhookCallEventsTableRow',

  components: { BaseTag },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  computed: {
    eventStatusColor() {
      if (this.event.status === 'succeeded') {
        return 'green'
      }

      return 'red'
    },

    eventStatusIcon() {
      if (this.event.status === 'succeeded') {
        return 'checkmark'
      }

      if (this.event.status === 'failed') {
        return 'clock'
      }

      return 'close'
    },

    eventStatusTooltip() {
      if (this.event.status === 'succeeded') {
        return 'This webhook delivery succeeded'
      }

      if (this.event.status === 'failed') {
        return 'This webhook delivery failed. Leavedates will try to redeliver the event in a moment.'
      }

      return (
        'This webhook delivery failed. All automatic retries have been exhausted, ' +
        'no more attempts to deliver this event will be made.'
      )
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.target, {
      html: this.$refs.content.firstChild,
      placement: 'right',
      distance: 2,
      interactiveBorder: 15,
      interactive: true,
      theme: 'dark',
      arrow: true,
      delay: [400, 0],
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })
  },
}
</script>
