<template>
  <div>
    <PersonalAccessTokens />
    <SingleSignOn />
    <CompanyCalendars />
    <Webhooks />
  </div>
</template>

<script>
import SingleSignOn from '@/components/integrations/SingleSignOn'
import PersonalAccessTokens from '@/components/integrations/PersonalAccessTokens'
import CompanyCalendars from '@/components/integrations/CompanyCalendars'
import Webhooks from '@/components/integrations/Webhooks'

export default {
  name: 'Integrations',

  middleware: 'auth',

  components: {
    SingleSignOn,
    PersonalAccessTokens,
    CompanyCalendars,
    Webhooks,
  },
}
</script>
