<template>
  <div
    class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
    @click="showMoreDetails"
  >
    <div class="tw-flex tw-items-center">
      <div>
        <img
          src="@/assets/icons/logo.svg"
          class="tw-block tw-rounded-full tw-mr-4 notifications-list-avatar"
          alt="leavedates-logo"
        />
      </div>
      <div>
        <div>
          <div class="notification-message-wrap">
            {{ notification.getMessage() }}
          </div>
        </div>
        <HappenedOn :time="fromNow" class="tw-mt-1" />
      </div>
    </div>
  </div>
</template>

<script>
import HappenedOn from '@/components/notifications/HappenedOn'
import Notification from '@/components/notifications/mixins/Notification'

export default {
  name: 'UpcomingCompanyHolidaysCreated',

  components: {
    HappenedOn,
  },

  mixins: [Notification],

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)
    },
  },
}
</script>
