<template>
  <table aria-label="Company calendars table" class="ld-table ld-table-striped">
    <thead>
      <tr>
        <th>Calendar Type</th>
        <th>Account</th>
        <th>Calendar</th>
        <th>Scope</th>
        <th style="width: 200px;"></th>
        <th style="width: 100px;"></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="companyCalendars.length">
        <CompanyCalendarTableRow
          v-for="companyCalendar in companyCalendars"
          :key="companyCalendar.ids"
          :company-calendar="companyCalendar"
          @reconnect="$emit('reconnect', companyCalendar)"
          @edit-calendar="$emit('edit-calendar', companyCalendar)"
          @delete-calendar="$emit('delete-calendar', companyCalendar)"
        />
      </template>
      <tr v-else>
        <td class="tw-bg-white" colspan="7">
          <NoData :message="'No company calendars have been added'" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
const NoData = () => import('@/components/NoData')
import CompanyCalendarTableRow from '@/components/integrations/company-calendar/CompanyCalendarTableRow'

export default {
  name: 'CompanyCalendarTable',

  components: {
    NoData,
    CompanyCalendarTableRow,
  },

  props: {
    companyCalendars: {
      type: Array,
      required: true,
    },
  },
}
</script>
