<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <DetailEvent v-else :event="event" />
  </div>
</template>

<script>
import RetractedEvent from '@/components/requests/leave/events/RetractedEvent'
import SummaryEvent from '@/components/requests/leave/timeline/SummaryEvent'
import DetailEvent from '@/components/requests/leave/timeline/DetailEvent'

export default {
  name: 'LeaveWasRetracted',

  components: { DetailEvent, SummaryEvent },

  props: {
    event: {
      type: RetractedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
