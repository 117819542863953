<template>
  <RequestUpdated
    request-type="leave"
    :notification="notification"
    :active-notification="activeNotification"
    @show-more-details="$emit('click', notification)"
  />
</template>

<script>
import Notification from '@/components/notifications/mixins/Notification'
import RequestUpdated from '@/components/notifications/RequestUpdated'

export default {
  name: 'LeaveUpdated',

  components: {
    RequestUpdated,
  },

  mixins: [Notification],
}
</script>
