<template>
  <table aria-label="Single sign on table" class="ld-table ld-table-striped">
    <thead>
      <tr>
        <th>Auth Provider</th>
        <th>Base Url</th>
        <th>Client Id</th>
        <th style="width: 30%;">Login Url</th>
        <th style="width: 30%;">Callback Url</th>
        <th style="width: 100px;"></th>
      </tr>
    </thead>
    <tbody>
      <template v-if="providers.length">
        <single-sign-on-table-row
          v-for="provider in providers"
          :key="provider.id"
          :provider="provider"
          @delete-provider="$emit('delete-provider', provider)"
        />
      </template>
      <tr v-else>
        <td class="tw-bg-white" colspan="7">
          <no-data :message="'No authentication providers have been added'" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import SingleSignOnTableRow from './SingleSignOnTableRow'
const NoData = () => import('../../NoData.vue')
export default {
  name: 'SingleSignOnTable',

  components: {
    SingleSignOnTableRow,
    NoData,
  },

  props: {
    providers: {
      type: Array,
      required: true,
    },
  },
}
</script>
