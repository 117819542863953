<template>
  <Multiselect
    :value="selectedStatus"
    :options="unSelectedStatus"
    :multiple="true"
    :hide-selected="true"
    :show-labels="false"
    :allow-empty="true"
    :searchable="false"
    :max-height="130"
    class="input-chips input-chips_basic"
    label="name"
    :placeholder="placeholder"
    track-by="value"
    data-cy="request-status"
    @input="$emit('input', $event)"
  >
    <template slot="option" slot-scope="props">
      <div class="tw-flex tw-justify-start tw-items-center tw-content-center">
        <div class="tw-text-base tw-font-normal tw-text-gray-800">
          {{ props.option.name }}
        </div>
      </div>
    </template>

    <template slot="tag" slot-scope="props">
      <div class="chip-wrapper">
        <div class="chips">
          <div class="chip">
            <div class="title">{{ props.option.name }}</div>
            <button class="close" type="button" @click="remove(props.option)">
              <svg-icon name="close" class="tw-w-2 tw-h-2 tw-text-red-500" />
            </button>
          </div>
        </div>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import { Multiselect } from 'vue-multiselect'

export default {
  components: { Multiselect },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    placeholder: {
      type: String,
      default: 'Select status',
    },

    selectableStatus: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    unSelectedStatus: [],
    selectedStatus: [],
    requestStatus: [
      {
        name: 'Requested',
        value: 'requested',
      },
      {
        name: 'Approved',
        value: 'approved',
      },
      {
        name: 'Declined',
        value: 'rejected',
      },
      {
        name: 'Cancelled',
        value: 'cancelled',
      },
    ],
  }),

  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.selectedStatus = [...value]
        this.unSelectedStatus = [
          ...[...this.selectableStatus, ...this.requestStatus].filter(
            status =>
              !value.some(selectedStatus => selectedStatus.name === status.name)
          ),
        ]
      },
    },
  },

  methods: {
    remove(option) {
      this.$emit('input', [
        ...this.selectedStatus.filter(status => {
          return status.name !== option.name
        }),
      ])
    },
  },
}
</script>
