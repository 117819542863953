<template>
  <div class="tw-min-h-screen tw-flex tw-flex-col tw-text-center">
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <div
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
        >
          <div class="tw-mb-12">
            <a :href="marketingSiteUrl" title="Click to visit">
              <HeaderLogo />
            </a>
          </div>

          <div class="tw-mb-4">
            <div>
              <p class="tw-mb-4 tw-text-gray-900 tw-text-xl">
                Unsubscribed
              </p>
              <p class="tw-mb-10 tw-px-4 tw-text-gray-700">
                You have unsubscribed from all email summary reports.
              </p>
            </div>
          </div>
        </div>

        <auth-footer />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFooter from './../components/AuthFooter'
import MarketingSiteUrl from '../mixins/MarketingSiteUrl'
import HeaderLogo from '@/components/HeaderLogo'

export default {
  name: 'Unsubscribed',

  layout: 'DefaultLayout',

  components: { HeaderLogo, AuthFooter },

  mixins: [MarketingSiteUrl],
}
</script>
