<template>
  <label
    class="tw-relative tw-block tw-bg-white tw-rounded-lg tw-p-4 tw-cursor-pointer sm:tw-flex sm:tw-justify-between focus:tw-outline-none tw-border-gray-400 tw-border"
    :class="{ 'tw-border-2 tw-border-blue-500': isChecked }"
  >
    <input
      type="radio"
      :checked="isChecked"
      :value="value"
      class="tw-sr-only"
      data-cy="radio-company-year"
      @change="$emit('input', $event.target.value)"
    />

    <span
      class="tw-flex tw-items-center tw-whitespace-pre-line"
      data-cy="label-company-leave-year"
    >
      <SvgIcon
        v-if="isChecked"
        class="tw-mr-2 tw-w-6 tw-h-6 tw-rounded-full tw-text-blue-500"
        name="check-circle-solid"
      />

      <span
        v-else
        class="tw-w-6 tw-h-6 tw-mr-2 tw-border-2 tw-border-gray-350 tw-rounded-full"
      ></span>

      {{ label }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'RadioBox',

  model: {
    prop: 'modelValue',
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    modelValue: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: true,
    },
  },

  computed: {
    isChecked() {
      return this.modelValue === this.value
    },
  },
}
</script>
