<template>
  <RequestRequested
    request-type="overtime"
    :notification="notification"
    :active-notification="activeNotification"
    @show-more-details="showMoreDetails"
  />
</template>

<script>
import RequestRequested from '@/components/notifications/RequestRequested'
import Notification from '@/components/notifications/mixins/Notification'

export default {
  name: 'OvertimeRequested',

  components: {
    RequestRequested,
  },

  mixins: [Notification],

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)
    },
  },
}
</script>
