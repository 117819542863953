import http from '@/plugins/http'

export default class Overtime {
  static async getReport(params) {
    return await http.get('reports/time-off-in-lieu', { params: params })
  }

  static async downloadReport(params) {
    return await http.get('reports/downloads/time-off-in-lieu', {
      params: params,
      responseType: 'blob',
    })
  }
}
