<template>
  <div />
</template>

<script>
import Profile from '@/api/profile/Profile'

export default {
  name: 'PendingEmailVerify',

  layout: 'DefaultLayout',

  async created() {
    try {
      await Profile.verifyPendingEmail(this.$route.fullPath)

      this.success(
        `Your email address has been changed to ${this.$route.params.email}`
      )

      this.$router.replace({ name: 'home' }, () => {})
    } catch ({ response }) {
      if (response.status === 410 || response.status === 403) {
        this.error(response.data.message)
      }

      this.$router.replace({ name: 'home' }, () => {})
    }
  },
}
</script>
