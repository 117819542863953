<template>
  <div class="card card-container">
    <div class="tw-py-10">
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
        <div>
          <h2 class="page-title">Single Sign On</h2>
        </div>
        <div class="tw-flex tw-flex-wrap">
          <div>
            <SpinnerButton type="button" @click="showCreateProviderForm">
              Add Provider
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="tw-mb-2">
        Single sign-On (SSO) allows you to create a custom domain which your
        employees can use to access Leave Dates securely using the same
        credentials for all their applications.
      </p>
      <p class="tw-mb-4">
        Full documentation of how to configure SSO can be found

        <BaseLink :href="ssoApiDocumentationUrl">here</BaseLink>.
      </p>

      <div v-scroll-hint class="table-responsive  settings-table">
        <SingleSignOnTable
          :providers="providers"
          @delete-provider="revokeAuthProvider"
        />
      </div>
    </div>

    <Modal
      id="modal-create-domain"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-create-domain"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Setup Company Domain
            </p>
          </div>
          <div data-cy="edit-domain-close">
            <button class="modal-close" @click="hideCreateDomainForm">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form
          id="companyDomainCreateform"
          class="tw-w-full"
          @submit.prevent="addDomainName"
        >
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="domain_name">
                Company Domain<span class="required-field">&#42;</span>
              </label>
              <div class="tw-flex tw-items-center">
                <input
                  id="domain_name"
                  v-validate="'required|max:191'"
                  :value="domain"
                  data-vv-name="domain_name"
                  data-vv-as="domain url"
                  class="form-control tw-mr-3"
                  type="text"
                  autocomplete="off"
                  @input="domain = $event.target.value.toLowerCase()"
                />
                <span class="tw-select-none">.leavedates.com</span>
              </div>
              <p
                v-show="errors.has('domain_name')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('domain_name') }}
              </p>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="!valid || loading"
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                >
                  Continue
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>

    <Modal
      id="modal-create-provider"
      ref="providerCreateModal"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-create-provider"
      width="95%"
      height="auto"
      adaptive
      scrollable
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              New Provider
            </p>
          </div>
          <div data-cy="edit-provider-close">
            <button class="modal-close" @click="hideCreateProviderForm">
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form
          id="authProviderCreateform"
          class="tw-w-full"
          @submit.prevent="addProvider"
        >
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="provider">
                Provider <span class="required-field">&#42;</span>
              </label>
              <div class="tw-relative">
                <AuthProviderNamePicker
                  id="provider"
                  v-model="form.provider"
                  :options="providerTypes"
                  data-cy="provider"
                />
              </div>
              <p
                v-show="errors.has('provider')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('provider') }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="base_url">
                {{ form.provider.id === 2 ? 'Tenant' : 'Base Url' }}
                <span class="required-field">&#42;</span>
              </label>
              <input
                id="base_url"
                v-model="form.baseUrl"
                v-validate="'required|max:191'"
                :placeholder="getAuthProviderBaseUrlPlaceHolder"
                data-vv-name="base_url"
                data-vv-as="base url"
                class="form-control"
                type="text"
                autocomplete="off"
              />
              <p
                v-show="errors.has('base_url')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('base_url') }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="client_id">
                Client ID <span class="required-field">&#42;</span>
              </label>
              <input
                id="client_id"
                v-model="form.clientId"
                v-validate="'required|max:191'"
                data-vv-name="client_id"
                data-vv-as="client id"
                class="form-control"
                type="text"
                autocomplete="off"
              />
              <p
                v-show="errors.has('client_id')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('client_id') }}
              </p>
            </div>
          </div>

          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="client_secret">
                Client Secret<span class="required-field">&#42;</span>
              </label>
              <input
                id="client_secret"
                v-model="form.clientSecret"
                v-validate="'required|max:191'"
                data-vv-name="client_secret"
                data-vv-as="client secret"
                class="form-control"
                type="text"
                autocomplete="off"
              />
              <p
                v-show="errors.has('client_secret')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('client_secret') }}
              </p>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="!valid || loading"
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                >
                  Create
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ValidatesForm from '@/mixins/ValidatesForm'
import AuthProviderNamePicker from '@/components/pickers/AuthProviderNamePicker'
import SingleSignOnTable from '@/components/integrations/single-sign-on/SingleSignOnTable'
import SpinnerButton from '@/components/SpinnerButton'
import BaseLink from '@/components/BaseLink'
import documentationUrls from '@/documentations/documentation-urls'
import AuthProviders from '@/api/company/AuthProviders'

export default {
  name: 'SingleSignOn',

  components: {
    SingleSignOnTable,
    AuthProviderNamePicker,
    SpinnerButton,
    BaseLink,
  },

  mixins: [ValidatesForm],

  data() {
    return {
      form: {
        provider: {
          id: '',
          name: '--',
        },
        baseUrl: '',
        clientId: '',
        clientSecret: '',
      },
      providers: [],
      providerTypes: [],
      domain: '',
      loading: false,
    }
  },

  computed: {
    ssoApiDocumentationUrl() {
      return documentationUrls.ssoUrl
    },

    providerNames() {
      return [
        {
          id: 1,
          name: 'Okta',
        },
        {
          id: 2,
          name: 'Microsoft',
        },
        {
          id: 3,
          name: 'OneLogin',
        },
      ]
    },

    getAuthProviderBaseUrlPlaceHolder() {
      return this.form.provider.id === 2
        ? 'example.onmicrosoft.com'
        : 'https://dev-12345.example.com'
    },
  },

  watch: {
    '$route.query.company': {
      immediate: true,
      handler(companyId) {
        this.fetchProviders(companyId)
      },
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    fetchProvidersTypes() {
      return [
        {
          id: '',
          name: '--',
          $isDisabled: true,
        },
        ...this.providerNames,
      ]
    },

    async fetchProviders(companyId) {
      try {
        const { data } = await AuthProviders.get(companyId)

        this.providers = data
        this.providerTypes = this.fetchProvidersTypes().filter(obj => {
          return !data.some(
            authProvider => authProvider.provider === obj.name.toLowerCase()
          )
        })

        return data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    showCreateProviderForm() {
      this.domain = this.activeCompany.domain_name

      if (this.domain === null || this.domain === '') {
        this.$modal.show('modal-create-domain')
      } else {
        this.$modal.show('modal-create-provider')
      }
    },

    async addProvider() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        await AuthProviders.post({
          company_id: this.activeCompany.id,
          provider: this.form.provider.name.toLowerCase(),
          client_id: this.form.clientId,
          client_secret: this.form.clientSecret,
          base_url: this.form.baseUrl,
          domain: this.activeCompany.domain_name,
        })

        await this.fetchProviders(this.activeCompany.id)

        this.$modal.hide('modal-create-provider')
        this.resetProviderModal()

        this.success('Provider created successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    hideCreateProviderForm() {
      this.$modal.hide('modal-create-provider')
      this.resetProviderModal()
    },

    resetProviderModal() {
      this.$nextTick(() => {
        this.form.provider = {
          id: '',
          name: '--',
        }
        this.form.baseUrl = ''
        this.form.clientId = ''
        this.form.clientSecret = ''

        this.$validator.reset()
        this.errors.clear()
      })
    },

    async revokeAuthProvider(provider) {
      try {
        const confirmed = await this.confirm(
          'Are you sure you want to delete?',
          'Confirm delete'
        )

        if (!confirmed) return

        await AuthProviders.delete(provider.id, this.activeCompany.id)

        await this.fetchProviders(this.activeCompany.id)

        this.success('Provider revoked successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },

    async addDomainName() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        await this.$http.post('company-domains', {
          company_id: this.activeCompany.id,
          domain_name: this.domain.toLowerCase(),
        })

        await this.fetchUser()
        this.domain = ''
        await this.$validator.reset()

        this.$modal.hide('modal-create-domain')

        this.success('Domain name updated successfully.')

        this.$modal.show('modal-create-provider')
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }

      this.loading = false
    },

    hideCreateDomainForm() {
      this.domain = ''
      this.$modal.hide('modal-create-domain')
    },
  },
}
</script>
