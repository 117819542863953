<template>
  <div>
    <div class="tw-w-full">
      <WallChartCalendar
        ref="wallChartCalendar"
        :data-leaves="employmentsLeave"
        :data-overtimes="overtimes"
        :employments="employments"
        :employments-holidays="employmentsHolidays"
        :employments-exceeded-leave-limits="employmentsExceededLeaveLimits"
        :employment-birthdays="employmentBirthdays"
        :default-filter="defaultFilter"
        @create-request="createRequest"
        @show-overtime="overtime => showOvertimeDetailsPanel(overtime)"
        @show-leave="leave => showLeaveDetailsPanel(leave)"
        @view-duration-changed="setCurrentViewDuration"
        @timeline-view-changed="timelineViewChange"
        @department-changed="departmentChanged"
        @scroll-down="scrollDown"
        @scroll-up="scrollUp"
        @default-filter-changed="updateDefaultCompanyScheduleFilter($event)"
      />
    </div>

    <ReviewReminder />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import EventBus from '@/plugins/event-bus'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleWorkingSchedule from '@/mixins/HandleWorkingSchedule'
import RequestPanel from '@/components/requests/RequestPanel'
import { debounce, groupBy } from 'lodash-es'
import CompanyScheduleQuery from '@/graphql/queries/company-schedule'
import { EmploymentSettings } from '@/api'
import WallChartCalendar from '@/components/wall-chart/WallChartCalendar'
import { mapActions } from 'vuex'
import EmploymentsQuery from '@/graphql/queries/employments'
import WallChartFilter from '@/support/WallChartFilter'
import Employments from '@/graphql/Employments'

const ReviewReminder = () => import('@/components/ReviewReminder')

export default {
  name: 'WallChart',

  middleware: 'auth',

  components: {
    ReviewReminder,
    WallChartCalendar,
  },

  mixins: [ValidatesForm, FormatDate, HandleWorkingSchedule],

  data: () => ({
    isResourcesLoading: false,
    employee: null,
    period: null,
    leaves: [],
    overtimes: [],
    employments: [],
    employmentsExceededLeaveLimits: [],
    employmentsHolidays: [],
    employmentBirthdays: [],
    currentView: 'resourceTimelineMonth',
    currentViewDuration: {
      start: moment.utc().startOf('month'),
      end: moment
        .utc()
        .add(1, 'months')
        .startOf('month'),
    },
    hasDepartmentSwitcherValueChanged: false,
    pagination: {
      offset: 0,
      limit: 50,
      length: 50,
      groupByDepartment: null,
    },
    visibleEmployments: [],
    totalEmployments: 0,
    defaultPaginationLimit: 50,
  }),

  computed: {
    employmentsLeave() {
      if (this.inActiveEmploymentTimezone) {
        return this.getTimeShiftedLeavesForViewerTimezone()
      }

      return this.leaves
    },

    defaultFilter() {
      return this.activeEmployment.getCompanyScheduleFilter()
    },
  },

  watch: {
    '$route.query.company'() {
      this.resetPagination()
      this.setCompanyScheduleFilter()
      this.fetchVisibleEmployments()
    },
  },

  created() {
    EventBus.$on(
      [
        'notification-fetched',
        'leave-requested',
        'leave-approved',
        'leave-updated',
        'leave-cancelled',
        'leave-retracted',
        'overtime-requested',
        'overtime-approved',
        'overtime-updated',
        'overtime-cancelled',
        'overtime-rejected',
      ],
      () => this.refetchWallchart()
    )
  },

  mounted() {
    if (this.$route.query.tour === 'yes') {
      this.$tours['leave-dates-tour'].start()
    }

    this.setCompanyScheduleFilter()

    this.fetchVisibleEmployments()
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),

    getTimeShiftedLeavesForViewerTimezone() {
      return this.leaves.map(leave => {
        const viewerTimezone = this.activeEmployment.timezone

        const start = moment.tz(leave.start, leave.timezone).tz(viewerTimezone)
        const end = moment.tz(leave.end, leave.timezone).tz(viewerTimezone)

        return {
          ...leave,
          start: start.format('YYYY-MM-DD HH:mm:ss'),
          end: end.format('YYYY-MM-DD HH:mm:ss'),
        }
      })
    },

    departmentChanged() {
      this.resetPagination()
      this.fetchVisibleEmployments()
      this.hasDepartmentSwitcherValueChanged = true
    },

    groupByDepartment() {
      this.$router.push({
        query: {
          ...this.$route.query,
          'group-by': 'department',
        },
      })
    },

    showRequestPanel() {
      const panelResult = this.$showPanel({
        component: RequestPanel,
        openOn: 'right',
        width: 1100,
        props: {
          employee: this.employee,
          period: this.period,
          currentView: this.currentView,
          employments: this.visibleEmployments,
        },
      })

      panelResult.promise.then(() => {
        this.unselectCalendar()
        this.resizeCalendar()
      })
    },

    showOvertimeDetailsPanel(overtime) {
      this.$router.push({
        query: {
          ...this.$route.query,
          company: this.activeCompany.id,
          overtime: overtime.id,
        },
      })
    },

    showLeaveDetailsPanel(leave) {
      this.$router.push({
        query: {
          ...this.$route.query,
          company: this.activeCompany.id,
          'leave-request': leave.id,
        },
      })
    },

    timelineViewChange(view) {
      this.currentView = view
    },

    setCurrentViewDuration(duration) {
      this.currentViewDuration = duration
    },

    async createRequest({ start, end, resource }, currentView) {
      this.employee = this.employments.find(
        employment => employment.id === resource.id
      )

      this.currentView = currentView

      start = moment.utc(start)
      end = moment.utc(end)

      if (
        'resourceTimelineDay' !== currentView &&
        end.format('HH:mm') === '00:00'
      ) {
        // fix exclusive end date of full-calendar moment object
        end = end.subtract(1, 'day').endOf('day')
      }

      if (!this.isFromDayView()) {
        start = this.getStartDateForBreakdown(start, this.employee)
        end = this.getEndDateForBreakdown(end, this.employee)
      }

      this.period = [start, end]

      this.showRequestPanel()

      this.resizeCalendar()
    },

    isFromDayView() {
      return this.currentView === 'resourceTimelineDay'
    },

    groupWallChartByDepartment() {
      this.$nextTick(() => {
        if (!this.$refs.wallChartCalendar) return

        this.$refs.wallChartCalendar.groupByDepartment(this.$route.query)
      })
    },

    resizeCalendar() {
      this.$nextTick(() => {
        this.$refs.wallChartCalendar?.resizeCalendar()
      })
    },

    unselectCalendar() {
      this.$refs.wallChartCalendar.unselect()
    },

    resetPagination() {
      this.pagination.offset = 0
      this.pagination.limit = this.pagination.length
    },

    refetchWallchart: debounce(async function() {
      try {
        await this.$apollo.queries.companySchedule.refetch()
        this.resizeCalendar()
      } catch (error) {
        this.validateGraphQLFromResponse(error, true)
      }
    }, 50),

    async fetchVisibleEmployments() {
      try {
        await this.$apollo.queries.emp.refetch()
      } catch (error) {
        this.validateGraphQLFromResponse(error, false)
      }
    },

    setCompanyScheduleFilter() {
      const routeQuery = WallChartFilter.getRouteQuery(
        this.$route.query,
        this.activeEmployment
      )

      if (routeQuery) {
        this.$router.push({
          query: routeQuery,
        })
      }
    },

    scrollDown: debounce(function() {
      if (
        !this.isResourcesLoading &&
        this.hasMoreResourcesToLoad() &&
        this.$route.query['group-by'] !== 'department'
      ) {
        this.loadResourcesToCalendarBottom()
      }
    }, 1200),

    hasMoreResourcesToLoad() {
      return (
        this.pagination.offset + this.pagination.limit <= this.totalEmployments
      )
    },

    loadResourcesToCalendarBottom() {
      this.showLoadingIcon('down')

      this.setPaginationOffset()

      this.setPaginationLimit()
    },

    setPaginationOffset() {
      if (this.pagination.limit !== this.defaultPaginationLimit) {
        this.pagination.offset += this.pagination.length
      }
    },

    setPaginationLimit() {
      if (this.pagination.limit === this.defaultPaginationLimit) {
        this.pagination.limit = this.pagination.length * 2
      }
    },

    scrollUp: debounce(function() {
      if (!this.isResourcesLoading && this.hasLoadedResources()) {
        this.loadResourcesToCalendarTop()
      }
    }, 1200),

    hasLoadedResources() {
      return this.pagination.offset > 0
    },

    loadResourcesToCalendarTop() {
      this.showLoadingIcon('up')

      this.pagination.offset -= this.pagination.length
    },

    showLoadingIcon(direction) {
      this.$refs.wallChartCalendar.loadCalendar(true, direction)
      this.isResourcesLoading = true
    },

    getCompanyScheduleParameters() {
      const intervals = {
        resourceTimelineDay: 'Day',
        resourceTimelineWeek: 'Days7',
        resourceTimelineMonth: 'Month',
        resourceTimeline30Days: 'Days30',
      }

      return {
        company: this.activeCompany.id,
        date: this.currentViewDuration.start.format('YYYY-MM-DD'),
        interval: intervals[this.currentView],
        employmentsQuery: {
          offset: this.pagination.offset,
          limit: this.pagination.limit,
          employmentIds: [],
          departmentIds: this.getDepartmentIds(),
          orderBy: this.getOrderByKey(),
        },
      }
    },

    getDepartmentIds() {
      if (this.hasFilterByDepartment()) {
        return [this.filteredDepartment()]
      }

      return []
    },

    getOrderByKey() {
      if (this.hasGroupByDepartment()) {
        return 'Department'
      }

      return null
    },

    mapEmployments(companySchedule) {
      return companySchedule.employments.map(employment => {
        const workingSchedule = this.findEmploymentWorkingSchedule(
          employment,
          companySchedule
        )
        const department = this.findDepartment(employment, companySchedule)

        return {
          ...employment,
          department: department,
          available_working_schedule: {
            id: workingSchedule.id,
            schedule_breakdowns: [...workingSchedule.breakdowns],
          },
          company: {
            ...this.activeCompany,
          },
        }
      })
    },

    findEmploymentWorkingSchedule(employment, companySchedule) {
      return companySchedule.workingSchedules.find(workingSchedule =>
        workingSchedule.ownerIds.includes(employment.id)
      )
    },

    findDepartment(employment, companySchedule) {
      return (
        companySchedule.departments.find(department =>
          department.resourceIds.includes(employment.id)
        ) ?? null
      )
    },

    setEmployments(employments) {
      this.employments = employments
    },

    setLeave(leave) {
      const self = this

      self.leaves = leave.map(leave => {
        const owner = self.leaveOwner(leave)

        return {
          ...leave,
          start: self
            .getLeaveStartTime(leave, owner)
            .format('YYYY-MM-DD HH:mm:ss'),
          end: self.getLeaveEndTime(leave, owner).format('YYYY-MM-DD HH:mm:ss'),
          eventType: 'leave',
          owner_id: owner.id,
          owner: {
            id: owner.id,
          },
        }
      })
    },

    leaveOwner(leave) {
      return this.employments.find(
        employment => employment.id === leave.resourceId
      )
    },

    getLeaveStartTime(leave, owner) {
      const leaveStart = moment.utc(leave.start).tz(leave.timezone)

      if (this.currentView === 'resourceTimelineDay') {
        return leaveStart
      }

      return this.adjustLeaveStartTime(leaveStart, owner)
    },

    adjustLeaveStartTime(leaveStart, owner) {
      if (this.isNonWorkingDay(leaveStart, owner)) {
        return this.adjustLeaveStartTimeForNonWorkingDay(leaveStart, owner)
      }

      return this.adjustLeaveStartTimeForWorkingDay(leaveStart, owner)
    },

    adjustLeaveStartTimeForNonWorkingDay(leaveStart, owner) {
      const breakdowns = this.commonWorkingPeriodOfDay(leaveStart, owner)

      if (
        leaveStart.isBefore(breakdowns.evening?.startTime) ||
        (breakdowns.morning && leaveStart.isBefore(breakdowns.morning.endTime))
      ) {
        return leaveStart.startOf('date')
      }

      return leaveStart.set({ hours: 12, minutes: 0 })
    },

    adjustLeaveStartTimeForWorkingDay(leaveStart, owner) {
      const breakdowns = this.workingScheduleForDay(leaveStart, owner)

      if (
        this.hasSingleScheduleForDay(leaveStart, owner) &&
        this.hasEveningSchedule(leaveStart, owner)
      ) {
        return this.adjustLeaveStartTimeAccordingToEveningSchedule(
          leaveStart,
          breakdowns
        )
      }

      return this.adjustLeaveStartTimeAccordingToMorningSchedule(
        leaveStart,
        breakdowns
      )
    },

    adjustLeaveStartTimeAccordingToEveningSchedule(leaveStart, breakdowns) {
      const eveningStartTime = leaveStart.clone().set({
        hours: this.eveningSchedule(breakdowns).start_time.split(':')[0],
        minutes: this.eveningSchedule(breakdowns).start_time.split(':')[1],
      })

      if (leaveStart.isBefore(eveningStartTime)) {
        return leaveStart.startOf('date')
      }

      return leaveStart.set({ hours: 12, minutes: 0 })
    },

    adjustLeaveStartTimeAccordingToMorningSchedule(leaveStart, breakdowns) {
      const morningEndTime = leaveStart.clone().set({
        hours: this.morningSchedule(breakdowns).end_time.split(':')[0],
        minutes: this.morningSchedule(breakdowns).end_time.split(':')[1],
      })

      if (leaveStart.isBefore(morningEndTime)) {
        return leaveStart.startOf('day')
      }

      return leaveStart.set({ hours: 12, minutes: 0 })
    },

    getLeaveEndTime(leave, owner) {
      const leaveEnd = moment.utc(leave.end).tz(leave.timezone)

      if (this.currentView === 'resourceTimelineDay') {
        return leaveEnd
      }

      return this.adjustLeaveEndTime(leaveEnd, owner)
    },

    adjustLeaveEndTime(leaveEnd, owner) {
      if (this.isNonWorkingDay(leaveEnd, owner)) {
        return this.adjustLeaveEndTimeForNonWorkingDay(leaveEnd, owner)
      }

      return this.adjustLeaveEndTimeForWorkingDay(leaveEnd, owner)
    },

    adjustLeaveEndTimeForNonWorkingDay(leaveEnd, owner) {
      const breakdowns = this.commonWorkingPeriodOfDay(leaveEnd, owner)

      if (
        leaveEnd.isAfter(breakdowns.morning?.endTime) ||
        (breakdowns.evening && leaveEnd.isAfter(breakdowns.evening.startTime))
      ) {
        return leaveEnd.endOf('date')
      }

      return leaveEnd.set({ hours: 12, minutes: 0 })
    },

    adjustLeaveEndTimeForWorkingDay(leaveEnd, owner) {
      const breakdowns = this.workingScheduleForDay(leaveEnd, owner)

      if (
        this.hasSingleScheduleForDay(leaveEnd, owner) &&
        this.hasMorningSchedule(leaveEnd, owner)
      ) {
        return this.adjustLeaveEndTimeAccordingToMorningSchedule(
          leaveEnd,
          breakdowns
        )
      }

      return this.adjustLeaveEndTimeAccordingToEveningSchedule(
        leaveEnd,
        breakdowns
      )
    },

    adjustLeaveEndTimeAccordingToMorningSchedule(leaveEnd, breakdowns) {
      const morningEndTime = leaveEnd.clone().set({
        hours: this.morningSchedule(breakdowns).end_time.split(':')[0],
        minutes: this.morningSchedule(breakdowns).end_time.split(':')[1],
      })

      if (leaveEnd.isAfter(morningEndTime)) {
        return leaveEnd.endOf('date')
      }

      return leaveEnd.set({ hours: 12, minutes: 0 })
    },

    adjustLeaveEndTimeAccordingToEveningSchedule(leaveEnd, breakdowns) {
      const eveningStartTime = leaveEnd.clone().set({
        hours: this.eveningSchedule(breakdowns).start_time.split(':')[0],
        minutes: this.eveningSchedule(breakdowns).start_time.split(':')[1],
      })

      if (leaveEnd.isAfter(eveningStartTime)) {
        return leaveEnd.endOf('day')
      }

      return leaveEnd.set({ hours: 12, minutes: 0 })
    },

    setOvertime(overtime) {
      const self = this
      self.overtimes = []

      overtime.forEach(overtime => {
        self.overtimes.push({
          ...overtime,
          eventType: 'overtime',
          start: self
            .getOvertimeStartTime(overtime)
            .format('YYYY-MM-DD HH:mm:ss'),
          end: self.getOvertimeEndTime(overtime).format('YYYY-MM-DD HH:mm:ss'),
        })
      })
    },

    getOvertimeStartTime(overtime) {
      return moment
        .utc(overtime.start)
        .tz(overtime.timezone)
        .startOf('day')
    },

    getOvertimeEndTime(overtime) {
      return moment
        .utc(overtime.end)
        .tz(overtime.timezone)
        .endOf('day')
    },

    setLeaveLimits(leaveLimits) {
      const self = this
      self.employmentsExceededLeaveLimits = []

      leaveLimits.forEach(leaveLimit => {
        self.employmentsExceededLeaveLimits.push({
          ...leaveLimit,
          eventType: 'leave-limit',
          display: 'background',
          classNames: ['event-leave-limit-exceeded'],
        })
      })
    },

    setBirthdays(birthdays, employments) {
      const self = this
      self.employmentBirthdays = []

      birthdays.flatMap(birthday => {
        const eventDate = moment(birthday.date)

        birthday.resourceIds.forEach(resourceId => {
          const employment = employments.find(
            employment => employment.id === resourceId
          )

          self.employmentBirthdays.push({
            resourceId,
            employmentName: employment.full_name,
            display: 'background',
            start: eventDate.startOf('day').format('YYYY-MM-DD HH:mm'),
            end: eventDate.endOf('day').format('YYYY-MM-DD HH:mm'),
            eventType: 'birthday',
            classNames: ['event-birthday', 'wall-chart-birthday-event'],
          })
        })
      })
    },

    setHolidays(holidays) {
      const self = this
      self.employmentsHolidays = []
      const groupedHolidays = groupBy(holidays, holiday => {
        return holiday.date + holiday.location + holiday.type
      })

      Object.values(groupedHolidays).forEach(holidays => {
        const event = holidays[0]

        const eventDate = moment(event.date)
        const typeClass =
          event.type === 'non_working_day'
            ? 'event-holiday--non-working-day'
            : 'event-holiday--working-day'

        self.employmentsHolidays.push({
          display: 'background',
          start: eventDate.startOf('day').format('YYYY-MM-DD HH:mm'),
          end: eventDate.endOf('day').format('YYYY-MM-DD HH:mm'),
          eventType: 'holiday',
          classNames: ['event-holiday', typeClass],
          events: holidays,
          resourceIds: event.resourceIds,
          type: event.type,
        })
      })
    },

    allocateEmploymentsToDepartments() {
      let self = this
      self.$nextTick(() => {
        if (
          (self.hasGroupByDepartment() && !self.pagination.groupByDepartment) ||
          (!self.hasGroupByDepartment() && self.pagination.groupByDepartment)
        ) {
          self.groupWallChartByDepartment()
          self.pagination.groupByDepartment = self.hasGroupByDepartment()
        }
      })
    },

    hasGroupByDepartment() {
      return (
        this.$route.query.hasOwnProperty('group-by') &&
        this.$route.query['group-by'] === 'department'
      )
    },

    hasFilterByDepartment() {
      return this.$route.query.hasOwnProperty('department')
    },

    filteredDepartment() {
      return this.hasFilterByDepartment() ? this.$route.query.department : null
    },

    async updateDefaultCompanyScheduleFilter(filter) {
      try {
        await EmploymentSettings.update(this.activeEmployment, {
          company_id: this.activeCompany.id,
          settings: {
            company_schedule: this.getInsertableCompanySchedule(filter),
          },
        })

        await this.fetchUser()

        this.success('Default company schedule filter successfully changed.')
      } catch (e) {
        this.error('Something went wrong.')
      }
    },

    getInsertableCompanySchedule(filter) {
      if (filter === 'everyone') {
        return {
          filter: null,
          order: null,
        }
      }

      if (filter === 'group_by_department') {
        return {
          filter: null,
          order: ['department'],
        }
      }

      if (filter === 'departments_missing') {
        return {
          filter: {
            department: null,
          },
          order: null,
        }
      }

      return {
        filter: {
          department: filter,
        },
        order: null,
      }
    },
  },

  apollo: {
    companySchedule: {
      debounce: 50,
      query: CompanyScheduleQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return this.getCompanyScheduleParameters()
      },
      result(response) {
        const self = this
        if (!self.$refs.wallChartCalendar) return

        self.$refs.wallChartCalendar.loadCalendar(false, false)
        self.isResourcesLoading = false

        const companySchedule = response.data.companySchedule
        let employments = this.mapEmployments(companySchedule)
        this.setEmployments(employments)
        this.setLeave(companySchedule.leave)
        this.setOvertime(companySchedule.overtime)
        this.setLeaveLimits(companySchedule.leaveLimits)
        this.setBirthdays(companySchedule.birthdays, employments)
        this.setHolidays(companySchedule.holidays)

        self.totalEmployments = companySchedule.totalEmployments
        this.allocateEmploymentsToDepartments()
      },
      error() {},
    },
    visibleEmployments: {
      debounce: 50,
      query: EmploymentsQuery,
      fetchPolicy: 'no-cache',
      variables() {
        return {
          company: this.activeCompany.id,
          employmentsQuery: {
            offset: 0,
            limit: 10000,
            employmentIds: [],
            departmentIds: this.getDepartmentIds(),
            orderBy: this.getOrderByKey(),
          },
        }
      },
      result(response) {
        this.visibleEmployments = Employments.all(response.data.employments)
      },
      error() {},
      update: data => data.employments,
    },
  },
}
</script>
