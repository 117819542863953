<template>
  <div class="tw-flex tw-items-center tw-text-gray-600">
    <svg-icon
      name="time"
      class="tw-block tw-rounded tw-mr-1 notification-icon"
    />
    <span class="tw-mr-1 tw-text-sm tw-font-semibold" v-text="time" />
    <span class="tw-text-xs">{{ suffix }}</span>
  </div>
</template>

<script>
export default {
  name: 'HappenedOn',

  props: {
    time: {
      type: String,
      required: true,
    },

    suffix: {
      type: String,
      default: 'ago',
    },
  },
}
</script>
