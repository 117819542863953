<template>
  <div>
    <ScheduleBreakdownRow
      v-for="dailySchedule in dailyWorkingSchedules"
      :key="dailySchedule.day"
      :daily-schedule="dailySchedule"
      @update="update"
      @duplicate="duplicateSessionBreakdowns"
    />
  </div>
</template>

<script>
import ScheduleBreakdownRow from '@/components/working-schedule/ScheduleBreakdownRow'
import WorkingSchedule from '@/components/working-schedule/WorkingSchedule'

export default {
  name: 'WorkingSchedule',

  components: {
    ScheduleBreakdownRow,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    workingSchedule() {
      return new WorkingSchedule(this.value)
    },

    dailyWorkingSchedules() {
      return this.workingSchedule.getDailySchedules()
    },
  },

  watch: {
    value() {
      this.$emit('valid', this.workingSchedule.isValid())
    },
  },

  methods: {
    duplicateSessionBreakdowns(copiedBreakdown) {
      let updatedBreakdowns = []
      this.workingSchedule
        .getSameSessionBreakdowns(copiedBreakdown)
        .forEach(breakdown => {
          breakdown.setTimePeriodFrom(copiedBreakdown)
          updatedBreakdowns.push(breakdown)
        })

      this.update(updatedBreakdowns)
    },

    update(updatedBreakdowns) {
      let scheduleBreakdowns = [
        ...this.workingSchedule.getNotUpdatedBreakdowns(updatedBreakdowns),
        ...this.getUpdatedActiveBreakdowns(updatedBreakdowns),
      ]

      this.$emit('input', {
        ...this.value,
        schedule_breakdowns: scheduleBreakdowns.map(breakdown =>
          breakdown.toJson()
        ),
      })
    },

    getUpdatedActiveBreakdowns(updatedBreakdowns) {
      return updatedBreakdowns.filter(breakdown => breakdown.isActive())
    },
  },
}
</script>
