<template>
  <div
    class="tw-inline-flex tw-rounded-full tw-text-center tw-px-3 tw-py-1"
    :class="themeClasses"
  >
    <span class="tw-font-semibold tw-flex tw-items-center">
      <slot></slot>
    </span>
  </div>
</template>

<script>
const THEMES = {
  default: ['tw-bg-blue-200', 'tw-text-blue-500'],

  green: ['tw-bg-green-200', 'tw-text-green-500'],

  red: ['tw-bg-red-200', 'tw-text-red-500'],
}

export default {
  name: 'BaseTag',

  props: {
    theme: {
      type: String,
      default: 'default',
    },
  },

  computed: {
    themeClasses() {
      return THEMES[this.theme]
    },
  },
}
</script>
