<template>
  <div ref="reference">
    <span class="fc-title">&zwnj;</span>
    <div
      :style="styles"
      :class="{ 'request-event--stripes': isRequestedOvertime }"
      class="request-event"
      data-cy="overtime-bar"
    >
      &zwnj;
    </div>

    <div v-show="false" ref="tooltip">
      <div v-if="!overtime.from">
        <div class="tw-px-3 tw-py-2">Loading...</div>
      </div>

      <div v-else-if="!isPrivateOvertime">
        <div style="width: 240px">
          <div class="tw-p-4">
            <div
              :style="{ color: overtimeColor }"
              class="tw-text-sm tw-font-semibold tw-uppercase"
              data-cy="overtime-title"
            >
              {{ overtime.title }}
            </div>

            <div
              v-if="overtimeCount"
              class="tw-mt-1 tw-font-normal tw-text-xl tw-uppercase tw-leading-none"
              data-cy="overtime-amount"
            >
              {{ overtimeCount }}
              {{ allowanceUnit | pluralize(overtimeCount) }}
            </div>

            <div
              v-if="overtime.reason"
              class="tw-mt-1 tw-w-full tw-text-gray-600"
              data-cy="overtime-reason"
            >
              {{ reason }}
            </div>
          </div>

          <div
            class="tw-px-4 tw-py-3 tw-flex tw-justify-start tw-items-center tw-content-center tw-bg-gray-300"
          >
            <UserAvatar class="tw-shadow-lg" :user="overtime.owner" />

            <div class="tw-px-3 tw-flex tw-items-center">
              <div class="tw-text-base tw-font-normal">
                <div>{{ overtime.owner.full_name }}</div>

                <div
                  v-if="overtime.owner.department"
                  class="tw-text-xs tw-text-gray-700"
                >
                  {{ overtime.owner.department.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button
          v-if="canViewOvertimeDetails"
          class="tw-w-full tw-p-4 tw-flex tw-justify-center tw-items-center btn-link tw-bg-blue-500 tw-text-white tw-text-thin tw-font-semibold tw-uppercase hover:tw-no-underline"
          data-cy="view-details"
          @click="showMoreDetails"
        >
          View Details
          <svg-icon
            class="tw-ml-1 tw-w-5 tw-h-5 tw-text-white"
            name="cheveron-right"
            style="margin-top: 1px;"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import tippy from 'tippy.js'
import router from '@/router'
import { truncate } from 'lodash-es'
import FormatNumbers from '@/mixins/FormatNumbers'
import LeavePermissions from '@/mixins/LeavePermissions'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'
import { Overtimes } from '@/api'

export default Vue.extend({
  router,

  name: 'Overtime',

  mixins: [LeavePermissions, FormatNumbers, FormatDate, ValidatesForm],

  props: {
    dataOvertime: {
      type: Object,
      required: true,
    },

    company: {
      type: Object,
      required: true,
    },

    employment: {
      type: Object,
      required: true,
    },

    user: {
      type: Object,
      required: true,
    },

    element: {
      type: HTMLElement,
      default: null,
    },
  },

  data() {
    return {
      overtime: this.dataOvertime,
    }
  },

  computed: {
    overtimeColor() {
      return this.overtime.colour
    },

    styles() {
      return {
        backgroundColor: this.overtimeColor,
      }
    },

    allowanceUnitInDays() {
      return this.overtime.allowance_unit_is_days
    },

    allowanceUnit() {
      return this.allowanceUnitInDays ? 'days' : 'hours'
    },

    overtimeCount() {
      if (this.overtime.duration_in_allowance_unit) {
        return this.toFixed(this.overtime.duration_in_allowance_unit, 2)
      }

      return null
    },

    isPrivateOvertime() {
      return this.overtime.is_private && !this.canViewOvertimeDetails
    },

    isRequestedOvertime() {
      return this.overtime.status === 'REQUESTED'
    },

    canViewOvertimeDetails() {
      return (
        this.employment.is_admin ||
        this.employment.id === this.overtime.owner_id ||
        this.canApprove(this.overtime, this.employment) ||
        this.isFollower(this.overtime, this.employment)
      )
    },

    reason() {
      return truncate(this.overtime.reason, {
        length: 80,
      })
    },
  },

  mounted() {
    if (this.isPrivateOvertime) return

    this.$nextTick(() => {
      this.tippy = tippy.one(this.$refs.reference, {
        html: this.$refs.tooltip.firstChild,
        interactive: true,
        theme: 'light',
        animation: 'scale',
        placement: 'bottom',
        arrow: true,
        delay: [400, 0],
        onShow: () => {
          this.fetchOvertime()
        },
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    })
  },

  methods: {
    showMoreDetails() {
      this.tippy.hide()

      this.$emit('show-overtime', this.overtime)
    },

    async fetchOvertime() {
      this.overtime = await Overtimes.fetch(this.dataOvertime.id, this.company)
    },
  },
})
</script>
