<template>
  <RequestApproved
    request-type="overtime"
    :notification="notification"
    :active-notification="activeNotification"
    @show-more-details="showMoreDetails"
  />
</template>

<script>
import RequestApproved from '@/components/notifications/RequestApproved'
import Notification from '@/components/notifications/mixins/Notification'

export default {
  name: 'OvertimeRequestApproved',

  components: {
    RequestApproved,
  },

  mixins: [Notification],

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)
    },
  },
}
</script>
