<template>
  <div class="tw-mb-4 settings-workday_slot" data-cy="settings-workday-slot">
    <div class="md:tw-flex md:tw-items-center md:tw-justify-start">
      <div class="tw-w-full md:tw-w-1/4" style="min-width: 132px;">
        <div
          class="tw-flex tw-items-center md:tw-justify-end tw-h-full tw-mb-3 md:tw-mb-0 tw-mr-10"
          style="width: 100px;"
          data-cy="workday"
        >
          {{ dailySchedule.day }}
        </div>
      </div>

      <ScheduleBreakdown
        v-for="breakdown in breakdowns"
        :key="breakdown.id"
        :schedule-breakdown="breakdown"
        @update="update"
        @duplicate="$emit('duplicate', $event)"
      />
    </div>

    <div
      v-show="errors.has('breakdown-row-valid')"
      class="tw-w-full tw-inline-block tw-mt-1 tw-text-red-700 tw-text-sm breakdown-row-error"
    >
      {{ errors.first('breakdown-row-valid') }}
    </div>
  </div>
</template>

<script>
import ScheduleBreakdown from '@/components/working-schedule/ScheduleBreakdown'
import ValidatesForm from '@/mixins/ValidatesForm'
import DailySchedule from '@/components/working-schedule/DailySchedule'

export default {
  name: 'ScheduleBreakdownRow',

  components: { ScheduleBreakdown },

  mixins: [ValidatesForm],

  props: {
    dailySchedule: {
      type: DailySchedule,
      required: true,
    },
  },

  computed: {
    breakdowns() {
      return this.dailySchedule.getBreakdowns()
    },
  },

  watch: {
    breakdowns(value) {
      if (this.dailySchedule.isBreakdownOverlaps(value[0])) {
        this.setValidationErrors()
      } else {
        this.clearValidationErrors()
      }
    },
  },

  methods: {
    update(updatedBreakdown) {
      const breakdowns = this.breakdowns.filter(breakdown => {
        return breakdown.id !== updatedBreakdown.id
      })

      if (this.dailySchedule.isBreakdownOverlaps(updatedBreakdown)) {
        this.setValidationErrors()
      } else {
        this.clearValidationErrors()
      }

      this.$emit('update', [...breakdowns, updatedBreakdown])
    },

    setValidationErrors() {
      this.errors.add({
        field: 'breakdown-row-valid',
        msg: 'Afternoon start time must be greater than the morning end time.',
      })
    },

    clearValidationErrors() {
      this.errors.clear()
    },
  },
}
</script>

<style lang="scss" scoped>
.breakdown-row-error {
  max-width: 360px;
}

@screen lg {
  .breakdown-row-error {
    padding-left: 133px;
  }
}
</style>
