<template>
  <v-select
    v-model="selectedOption"
    :options="options"
    :multiple="false"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    :max-height="180"
    track-by="id"
    label="name"
    placeholder=""
  >
  </v-select>
</template>

<script>
import commonOptions from './commonOptions'

export default {
  name: 'AuthProviderNamePicker',

  mixins: [commonOptions],
}
</script>
