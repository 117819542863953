<template>
  <div
    class="tw-mx-2 tw-mb-4 tw-bg-white tw-shadow-lg tw-rounded-lg tw-h-full tw-overflow-hidden"
  >
    <div class="tw-p-8 tw-leading-loose tw-text-gray-700">
      <h2 class="tw-mb-4 tw-text-gray-800">Privacy</h2>

      <form class="tw-w-full">
        <div class="form-group tw-mb-0">
          <div class="tw-w-full tw-mb-3">
            <input
              id="share-birthday"
              v-model="user.share_birthday"
              class="magic-checkbox"
              type="checkbox"
              name="share-birthday"
              data-cy="share-birthday"
              @change="updatePrivacySettings"
            />
            <label class="magic-checkbox-label" for="share-birthday"
              >Share my birthday (month and day only)</label
            >
          </div>

          <div class="tw-w-full">
            <input
              id="keep-leave-private"
              v-model="user.keep_leave_private"
              class="magic-checkbox"
              type="checkbox"
              name="keep-leave-private"
              @change="updatePrivacySettings"
            />
            <label class="magic-checkbox-label" for="keep-leave-private"
              >Mark my leave requests as private by default</label
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ValidatesForm from '../../mixins/ValidatesForm'

export default {
  name: 'PrivacySettings',

  mixins: [ValidatesForm],

  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    privacySettings() {
      return {
        share_birthday: this.user.share_birthday,
        keep_leave_private: this.user.keep_leave_private,
      }
    },
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),
    async updatePrivacySettings() {
      try {
        await this.$http.put('me/privacy-settings', this.privacySettings)

        await this.fetchUser()

        this.success('Privacy settings successfully updated.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },
  },
}
</script>
