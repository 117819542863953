import moment from 'moment-timezone'
import { filter, head, sortBy } from 'lodash-es'
import Calendar from '@/models/company/Calendar'

export default class EmployeeAllowanceCalendar {
  employeeCalendars = []

  constructor(employee, calendars) {
    this.employee = employee
    this.calendars = calendars

    this.calculateEmployeeCalendars()
  }

  calculateEmployeeCalendars() {
    const employeeCalendars = this.calendars.map(calendar => {
      if (!this.employee.company.allow_calendar_align_to_start_date) {
        return new Calendar({
          ...calendar,
          start_date: moment.utc(calendar.start_date),
          end_date: moment.utc(calendar.end_date),
        })
      }

      const startDate = this.needsAlign()
        ? moment
            .utc(this.employee.start_date)
            .year(moment.utc(calendar.start_date).year())
            .subtract(1, 'year')
        : moment.utc(calendar.start_date)

      return new Calendar({
        ...calendar,
        start_date: startDate,
        end_date: startDate
          .clone()
          .add(1, 'years')
          .subtract(1, 'day')
          .endOf('day'),
      })
    })

    this.employeeCalendars = this.filterOverlapCalendarsWithEmployeeWorkingDates(
      employeeCalendars
    )
  }

  filterOverlapCalendarsWithEmployeeWorkingDates(calendars) {
    return filter(calendars, calendar => {
      let startDate = this.employee.start_date
        ? moment.utc(this.employee.start_date)
        : calendar.start_date

      let endDate = this.employee.end_date
        ? moment.utc(this.employee.end_date)
        : calendar.end_date

      return (
        calendar.start_date.startOf('day') <= endDate &&
        calendar.end_date.endOf('day') >= startDate
      )
    })
  }

  getEmployeeCalendars() {
    return this.employeeCalendars
  }

  getCurrentYearCalendar() {
    const today = moment().utc(true)

    return this.employeeCalendars.find(calendar => {
      return today.isBetween(calendar.start_date, calendar.end_date)
    })
  }

  getCurrentAndNextCalendars() {
    const today = moment().utc(true)

    return filter(this.employeeCalendars, calendar => {
      return (
        today.isBetween(calendar.start_date, calendar.end_date) ||
        today.isSameOrBefore(calendar.start_date, 'days')
      )
    })
  }

  getNearestCalendar() {
    const today = moment().utc(true)

    return head(
      sortBy(this.employeeCalendars, calendar => {
        return Math.abs(
          Math.min(
            today.diff(calendar.start_date, 'days'),
            today.diff(calendar.end_date, 'days')
          )
        )
      })
    )
  }

  needsAlign() {
    return (
      this.employee.align_allowance_to_start_date && this.employee.start_date
    )
  }

  getCalendarForPeriod(period) {
    if (!period) return null

    const [start, end] = period.split(',')

    return this.employeeCalendars.find(
      calendar =>
        moment
          .utc(start, true)
          .isBetween(calendar.start_date, calendar.end_date) &&
        moment.utc(end, true).isBetween(calendar.start_date, calendar.end_date)
    )
  }
}
