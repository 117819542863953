<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <DetailEvent v-else :event="event" />
  </div>
</template>

<script>
import OvertimeUpdatedEvent from '@/components/requests/overtime/events/OvertimeUpdatedEvent'
import DetailEvent from '@/components/requests/overtime/timeline/DetailEvent'
import SummaryEvent from '@/components/requests/overtime/timeline/SummaryEvent'

export default {
  name: 'OvertimeWasUpdated',

  components: { SummaryEvent, DetailEvent },

  props: {
    event: {
      type: OvertimeUpdatedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
