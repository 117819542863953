<template>
  <tr>
    <td>{{ getProviderName }}</td>
    <td>{{ provider.base_url }}</td>
    <td>{{ provider.client_id }}</td>
    <td
      class="tw-leading-normal"
      style="width: 30%;"
      @mouseover="hoverRedirectionUrl = true"
      @mouseleave="hoverRedirectionUrl = false"
    >
      {{ provider.redirect_url }}
      <button
        v-show="hoverRedirectionUrl"
        v-clipboard:copy="provider.redirect_url"
        v-clipboard:success="redirectionUrlCopied"
        type="button"
        title="Copy To Clipboard"
        class="tw-text-blue-500"
      >
        <svg-icon name="clipboard" class="tw-w-5 tw-h-5 tw-mr-2" />
      </button>
    </td>
    <td
      class="tw-leading-normal"
      style="width: 30%;"
      @mouseover="hoverCallbackUrl = true"
      @mouseleave="hoverCallbackUrl = false"
    >
      {{ provider.callback_url }}
      <button
        v-show="hoverCallbackUrl"
        v-clipboard:copy="provider.callback_url"
        v-clipboard:success="callbackUrlCopied"
        type="button"
        title="Copy To Clipboard"
        class="tw-text-blue-500"
      >
        <svg-icon name="clipboard" class="tw-w-5 tw-h-5 tw-mr-2" />
      </button>
    </td>
    <td>
      <div class="tw-flex tw-items-center tw-justify-center">
        <button
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          title="Delete"
          data-cy="delete-token"
          @click="$emit('delete-provider')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon
              name="trash"
              class="svg-icon tw-w-3 tw-h-3 tw-text-white"
            />
          </div>
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'SingleSignOnTableRow',

  props: {
    provider: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hoverRedirectionUrl: false,
      hoverCallbackUrl: false,
    }
  },

  computed: {
    getProviderName() {
      return (
        this.provider.provider.charAt(0).toUpperCase() +
        this.provider.provider.slice(1)
      )
    },
  },

  methods: {
    redirectionUrlCopied() {
      this.success('Redirection url successfully copied to the clipboard.')
    },

    callbackUrlCopied() {
      this.success('Callback url successfully copied to the clipboard.')
    },
  },
}
</script>
