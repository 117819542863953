<template>
  <div
    class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
    @click="$emit('show-more-details')"
  >
    <div class="tw-flex tw-items-center">
      <div>
        <user-avatar
          :user="notification.getCommenter()"
          class="tw-block tw-mr-4 notifications-list-avatar"
        ></user-avatar>
      </div>
      <div>
        <div>
          <div
            class="notification-message-wrap"
            data-cy="request-comment-notification"
          >
            <span class="tw-font-semibold">{{ commentator }}</span>
            added a comment to
            <span class="tw-font-semibold">{{ requestOwner }}</span> request.
          </div>
        </div>
        <HappenedOn :time="fromNow" class="tw-mt-1" />
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/notifications/mixins/Notification'
import HappenedOn from '@/components/notifications/HappenedOn'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'

export default {
  name: 'RequestCommentAdded',

  components: {
    HappenedOn,
  },

  mixins: [Notification, FormatEmploymentName],

  props: {
    requestType: {
      type: String,
      required: true,
    },
  },

  computed: {
    commentator() {
      return this.compactFullName(this.notification.getCommenter())
    },

    requestOwner() {
      if (
        this.notification.viewerIsRequestOwner(
          this.activeEmployment,
          this.requestType
        )
      ) {
        return 'your'
      }

      if (this.notification.requestOwnerIsCommentator(this.requestType)) {
        return 'their'
      }

      return (
        this.compactFullName(
          this.notification.getRequestOwner(this.requestType)
        ) + "'s"
      )
    },
  },
}
</script>
