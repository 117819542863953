import http from '@/plugins/http'

export default class ExternalCalendars {
  static async getAvailableCalendars(group) {
    const { data } = await http.get(`synchronizations/${group}/calendars`)

    return data
  }

  static async updateCompanyCalendar(
    companyId,
    group,
    serviceId,
    scopes,
    titlePrefix,
    titleFormat,
    calendarId,
    leaveTypes
  ) {
    await http.put(`companies/${companyId}/synchronizations/${group}`, {
      company_id: companyId,
      ownable_id: companyId,
      ownable_type: 'company',
      service: serviceId,
      scopes: scopes,
      event_title_prefix: titlePrefix,
      event_title_format: titleFormat,
      calendar_id: calendarId,
      leave_types: leaveTypes,
    })
  }

  static deleteCalendar(company, calendar) {
    return http.delete(
      `companies/${company.id}/synchronizations/${calendar.group}`
    )
  }

  static connect(company, calendar) {
    return http.post(`companies/${company.id}/synchronizations`, {
      company_id: company.id,
      user_id: company.user_id,
      ownable_id: company.id,
      ownable_type: 'company',
      group: calendar.group,
      service: calendar.service,
      scopes: calendar.scopes,
      event_title_prefix: calendar.event_title_prefix,
      event_title_format: calendar.event_title_format,
      leave_types: calendar.leave_types,
    })
  }

  static async getSynchronizations(company) {
    const { data } = await http.get(`companies/${company.id}/synchronizations`)

    return data
  }
}
