<template>
  <FullCalendar
    id="full-calendar"
    ref="fullCalendar"
    :options="calendarOptions"
    class="tw-mb-0"
  >
    <template v-slot:slotLabelContent="arg">
      <DateTimeLabel :arg="arg" />
    </template>
    <template v-slot:resourceLabelContent="arg">
      <Employment
        :compact="true"
        :employment="arg.resource.extendedProps.employment"
      />
    </template>
    <template v-slot:eventContent="arg">
      <EventComponent
        :arg="arg"
        @show-overtime="overtime => $emit('show-overtime', overtime)"
        @show-leave="leave => $emit('show-leave', leave)"
      />
    </template>
    <template
      v-if="isResourceAreaHeaderContentEnabled"
      v-slot:resourceAreaHeaderContent
    >
      <AddEmployment />
    </template>
  </FullCalendar>
</template>

<script>
import { defaultsDeep } from 'lodash-es'
import FullCalendar from '@fullcalendar/vue'
import interactionPlugin from '@fullcalendar/interaction'
import Employment from '@/components/wall-chart/Employment'
import EventComponent from '@/components/wall-chart/EventComponent'
import AddEmployment from '@/components/wall-chart/AddEmployment'
import DateTimeLabel from '@/components/wall-chart/DateTimeLabel'

export default {
  components: {
    DateTimeLabel,
    EventComponent,
    FullCalendar,
    Employment,
    AddEmployment,
  },

  props: {
    events: {
      type: Array,
      default: () => [],
    },

    resources: {
      type: Array,
      default: () => [],
    },

    selectable: {
      type: Boolean,
      default: () => true,
    },

    header: {
      type: [Object, Boolean],
      default: () => false,
    },

    fixedHeight: {
      type: Boolean,
      default: () => true,
    },

    defaultView: {
      type: String,
      required: true,
    },

    config: {
      type: Object,
      default: () => {},
    },

    isResourceAreaHeaderContentEnabled: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    arguments: {},
    contentHeight: window.innerHeight - 230,
  }),

  computed: {
    calendarOptions() {
      return defaultsDeep(this.defaultConfig, this.config)
    },

    defaultConfig() {
      return {
        timeZone: 'UTC',
        headerToolbar: this.header,
        events: this.events,
        selectable: this.selectable,
        plugins: [interactionPlugin],
        initialView: this.defaultView,
        schedulerLicenseKey: process.env.VUE_APP_FULL_CALENDAR_LICENSE_KEY,
        resources: this.resources,
        resourceOrder: 'auto',
        rerenderDelay: 50,
        select: info => {
          this.$emit('event-created', info)
        },
        contentHeight:
          this.fixedHeight === false
            ? this.contentHeight
            : this.config.contentHeight,
        windowResize: () => {
          this.resizeCalendar()
        },
      }
    },
  },

  watch: {
    defaultView(view) {
      this.$refs.fullCalendar.getApi().removeAllEvents()

      this.$refs.fullCalendar.getApi().batchRendering(() => {
        this.$refs.fullCalendar.getApi().changeView(view)
      })
    },
  },

  methods: {
    fireMethod(...options) {
      return this.$refs.fullCalendar.getApi()[options.shift()](...options)
    },

    getProperty(prop) {
      return this.$refs.fullCalendar.getApi()[prop]
    },

    resizeCalendar() {
      this.contentHeight = window.innerHeight - 230
    },
  },
}
</script>
<style>
.fc-timeline-overlap-disabled .fc-timeline-event {
  padding-top: 0 !important;
}
</style>
