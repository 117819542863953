import moment from 'moment-timezone'
import Notification from '@/components/notifications/Notification'

export default {
  props: {
    notification: {
      type: Notification,
    },

    activeNotification: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    fromNow() {
      return moment
        .duration(moment().diff(moment(this.notification.getCreatedAt())))
        .humanize()
    },

    isActive() {
      return (
        this.activeNotification &&
        this.activeNotification.getKey() === this.notification.getKey()
      )
    },
  },

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)

      this.$modal.show('app-modal')
    },
  },
}
