<template>
  <CreateAllowanceNotification
    :notification="notification"
    @click="$emit('click', notification)"
  />
</template>
<script>
import CreateAllowanceNotification from '@/components/notifications/CreateAllowanceNotification'
import AllowanceNotification from '@/components/notifications/AllowanceNotification'

export default {
  name: 'CreateAllowanceForAllowanceBreakdownTypeCompleted',

  components: {
    CreateAllowanceNotification,
  },

  props: {
    notification: {
      type: AllowanceNotification,
      default: () => null,
    },
  },
}
</script>
