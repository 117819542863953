import http from '@/plugins/http'

export default class Holidays {
  static async save(location, payload) {
    const { data } = await http.post(
      `holiday-locations/${location}/holidays`,
      payload
    )

    return data
  }

  static async remove(company, holiday) {
    const { data } = await http.delete(
      `holiday-location-events/${holiday.id}`,
      {
        data: {
          company_id: company.id,
        },
      }
    )

    return data
  }
}
