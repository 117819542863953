<template>
  <div class="tw-mb-8">
    <div
      v-scroll-hint
      class="table-responsive scroll-hint"
      data-cy="holidays-list"
    >
      <table class="ld-table ld-table-sm">
        <thead>
          <tr>
            <th style="width: 140px">Date</th>
            <th style="width: 250px">Name</th>
            <th>Type</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="sortedHolidays.length">
            <HolidaysTableRow
              v-for="(holiday, index) in sortedHolidays"
              :key="index"
              :holiday="holiday"
              :holiday-types="holidayTypes"
              @remove="remove(holiday)"
            />
          </template>
          <tr v-else>
            <td class="tw-bg-white" colspan="3">
              <NoData message="No holidays available for this year" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HolidaysTableRow from './HolidaysTableRow'
import NoData from '@/components/NoData.vue'
import { sortBy } from 'lodash-es'

export default {
  name: 'HolidaysTable',

  components: { HolidaysTableRow, NoData },

  props: {
    holidays: {
      type: Array,
      required: true,
    },

    holidayTypes: {
      type: Array,
      required: true,
    },
  },

  computed: {
    sortedHolidays() {
      return sortBy(this.holidays, 'date')
    },
  },

  methods: {
    remove(holiday) {
      this.$emit('remove', holiday)
    },
  },
}
</script>
<style scoped>
.ld-table th {
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  padding-top: 0.4rem;
}
</style>
