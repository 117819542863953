<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title">
        <h2 class="page-title">Allowances Report</h2>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive reports-table">
      <table
        class="ld-table ld-table-striped"
        data-cy="table-allowance-report-detail"
      >
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Start Date</th>
            <th>Years of Service</th>
            <th>Allowance</th>
            <th>Allowance Year</th>
            <th>Annual Allowance</th>
            <th v-if="hasToilOfferedForAtLeastOneAllowance">
              Time off in Lieu
            </th>
            <th>Carry Over</th>
            <th>Locked</th>
            <th>Expired Carry Over</th>
            <th>Total Allowance</th>
            <th>Accrued Allowance</th>
            <th>Booked</th>
            <th>Taken</th>
            <th>Remaining to Book</th>
            <th>Remaining to Take</th>
            <th>Accrued Remaining to Book</th>
            <th>Accrued Remaining to Take</th>
            <th>Carried to Next Year</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="employmentAllowances.length()">
            <AllowanceDetailReportTableRow
              v-for="(employmentAllowance, index) in employmentAllowances.all()"
              :key="index"
              :employment-allowance="employmentAllowance"
              :has-toil-offered-for-at-least-one-allowance="
                hasToilOfferedForAtLeastOneAllowance
              "
            />
          </template>
          <tr v-if="!loading && !employmentAllowances.length()">
            <td
              class="tw-bg-white"
              :colspan="hasToilOfferedForAtLeastOneAllowance ? 22 : 21"
            >
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>

      <Loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/NoData'
import Loading from 'vue-loading-overlay'
import EmploymentAllowanceCollection from '@/models/reporting/EmploymentAllowanceCollection'
import AllowanceDetailReportTableRow from '@/components/reports/allowances/AllowanceDetailReportTableRow'

export default {
  name: 'AllowanceDetailReportTable',

  components: {
    AllowanceDetailReportTableRow,
    NoData,
    Loading,
  },

  props: {
    employmentAllowances: {
      type: EmploymentAllowanceCollection,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    hasToilOfferedForAtLeastOneAllowance() {
      return this.employmentAllowances.whereHasToilOffered().length > 0
    },
  },
}
</script>
