<template>
  <div
    class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
    @click="showMoreDetails"
  >
    <div class="tw-flex tw-items-center">
      <div>
        <user-avatar
          :user="notification.getOwner()"
          class="tw-block tw-mr-4 notifications-list-avatar"
        ></user-avatar>
      </div>
      <div>
        <div>
          <div class="notification-message-wrap">
            Payment authentication required.
          </div>
        </div>
        <HappenedOn :time="fromNow" class="tw-mt-1" />
      </div>
    </div>
    <portal v-if="isActive" to="app-modal-content">
      <div>
        <div class="tw-relative">
          <button
            class="tw-m-6 tw-opacity-50 hover:tw-opacity-100 modal-close tw-absolute tw-right-0"
            @click="$modal.hide('app-modal')"
          >
            <svg-icon name="close" class="tw-w-4 tw-h-4" />
          </button>
        </div>
        <div class="tw-rounded-t-lg tw-p-6 tw-pb-4 tw-bg-red-200">
          <div
            class="tw-flex tw-flex-wrap tw-items-center tw-content-center tw-mb-2"
          >
            <div class="tw-flex tw-flex-wrap">
              <svg-icon
                name="exclamation-solid"
                class="tw-w-4 tw-h-4 tw-mr-2 tw-text-red-500"
              />
            </div>
            <div
              class="tw-text-red-500 tw-text-xl tw-font-semibold tw-text-lg tw-leading-zero"
            >
              Payment authentication required
            </div>
          </div>
        </div>
        <div class="tw-p-6">
          <div class="tw-mb-4 tw-text-gray-700">
            In order to complete a payment, your bank requires you to provide
            authentication. Please click the button below to do this.
          </div>
          <div class="tw-flex tw-justify-end">
            <a :href="notification.getRedirectUrl()" class="btn btn-blue"
              >Authenticate
            </a>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import Notification from './mixins/Notification'
import HappenedOn from './HappenedOn'

export default {
  name: 'CompletePayment',

  components: {
    HappenedOn,
  },

  mixins: [Notification],
}
</script>
