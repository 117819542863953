<template>
  <v-select
    v-model="selectedOption"
    :options="options"
    :multiple="false"
    :searchable="false"
    :show-labels="false"
    :allow-empty="false"
    :max-height="180"
    :data-cy="cypressAttribute"
    track-by="id"
    label="name"
    placeholder=""
  >
    <template slot="option" slot-scope="props">
      {{ props.option.full_name || props.option.name }}
    </template>
    <template slot="singleLabel" slot-scope="props">
      {{ props.option.full_name || props.option.name }}
    </template>
  </v-select>
</template>

<script>
import commonOptions from './commonOptions.js'

export default {
  name: 'EmploymentPicker',

  mixins: [commonOptions],
}
</script>
