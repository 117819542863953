<template>
  <div
    class="tw-mb-2 md:tw-mb-0 tw-flex tw-items-center tw-justify-start tw-flex-wrap"
    data-cy="employment-working-schedule-breakdown"
    style="min-width: 245px;"
    @mouseover="mouseIsOver = true"
    @mouseleave="mouseIsOver = false"
  >
    <div>
      <input
        :id="scheduleBreakdown.id"
        v-model="active"
        type="checkbox"
        class="magic-checkbox"
      />
      <label
        :for="scheduleBreakdown.id"
        class="tw-inline-block"
        style="height: 20px;"
      ></label>
    </div>

    <div>
      <ClockPicker
        v-model="startTime"
        :disabled="!active"
        :class="errorClass"
        class="clockpicker-input"
      />
      &#8211;
      <ClockPicker
        v-model="endTime"
        :disabled="!active"
        :class="errorClass"
        class="clockpicker-input"
      />
    </div>

    <button
      v-if="active && mouseIsOver"
      type="button"
      class="tw-text-sm tw-text-blue-500 tw-hidden md:tw-inline-block"
      title="Apply same schedule to other working days"
      data-cy="btn-copy-schedule"
      @click="duplicate"
    >
      <SvgIcon name="clipboard" class="tw-w-5 tw-h-5" />
    </button>

    <button
      v-if="active"
      type="button"
      class="tw-text-sm tw-text-blue-500 md:tw-hidden"
      title="Apply same schedule to other working days"
      data-cy="btn-copy-schedule"
      @click="duplicate"
    >
      <SvgIcon name="clipboard" class="tw-w-5 tw-h-5" />
    </button>

    <div
      v-show="errors.has('breakdown-time-valid')"
      class="tw-w-full tw-inline-block tw-mt-1 tw-text-red-700 tw-text-sm"
    >
      {{ errors.first('breakdown-time-valid') }}
    </div>
  </div>
</template>

<script>
import ClockPicker from './../ClockPicker.vue'
import ValidatesForm from './../../mixins/ValidatesForm'
import Breakdown from '@/components/working-schedule/Breakdown'

export default {
  name: 'ScheduleBreakdown',

  components: {
    ClockPicker,
  },

  mixins: [ValidatesForm],

  props: {
    scheduleBreakdown: {
      type: Breakdown,
      required: true,
    },
  },

  data() {
    return {
      mouseIsOver: false,
      dataBreakdown: this.scheduleBreakdown,
    }
  },

  computed: {
    errorClass() {
      if (!this.isValidBreakdown) {
        return 'has-error'
      } else {
        return ''
      }
    },

    active: {
      get() {
        return this.dataBreakdown.isActive()
      },

      set(active) {
        if (active) {
          this.dataBreakdown.activate()
        } else {
          this.dataBreakdown.deactivate()
        }

        this.$emit('update', this.dataBreakdown)
      },
    },

    startTime: {
      get() {
        return this.dataBreakdown.startTime
      },

      set(startTime) {
        this.dataBreakdown.startTime = startTime

        this.validateClockPicker()
        this.emitChanges()
      },
    },

    endTime: {
      get() {
        return this.dataBreakdown.endTime
      },

      set(endTime) {
        this.dataBreakdown.endTime = endTime

        this.validateClockPicker()
        this.emitChanges()
      },
    },

    isValidBreakdown() {
      return this.dataBreakdown.isValid()
    },
  },

  watch: {
    scheduleBreakdown() {
      this.dataBreakdown = this.scheduleBreakdown
    },
  },

  methods: {
    validateClockPicker() {
      if (!this.isValidBreakdown) {
        this.errors.add({
          field: 'breakdown-time-valid',
          msg: 'End time must be greater than the start time.',
        })

        return false
      }

      this.errors.clear()
      return true
    },

    duplicate() {
      this.$emit('duplicate', this.dataBreakdown)
    },

    emitChanges() {
      this.$emit('update', this.dataBreakdown)
    },
  },
}
</script>

<style lang="scss" scoped>
.clockpicker-input {
  width: 75px;
  margin: 0 6px;
  @apply .px-4;
  @apply .py-2;
  @apply .inline-block;
  @apply .text-sm;
}
</style>
