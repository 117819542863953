<script>
import { groupBy, map } from 'lodash-es'
import AllowanceSummaryReportTableAllowanceUnitGroup from '@/components/reports/allowances/AllowanceSummaryReportTableAllowanceUnitGroup'

export default {
  name: 'AllowanceSummaryReportTableAllowanceTypeGroup',

  functional: true,

  props: {
    allowances: {
      type: Array,
      required: true,
    },

    hasToilOfferedForAtLeastOneAllowance: {
      type: Boolean,
      required: true,
    },
  },

  render(createElement, { props }) {
    const groups = groupBy(props.allowances, 'allowance_unit')

    return map(groups, allowances => {
      return createElement(AllowanceSummaryReportTableAllowanceUnitGroup, {
        props: {
          allowances: allowances.map((allowance, index) => {
            return {
              ...allowance,
              is_allowance_unit_group_first: index === 0,
              allowance_unit_group_count: allowances.length,
            }
          }),
          hasToilOfferedForAtLeastOneAllowance:
            props.hasToilOfferedForAtLeastOneAllowance,
        },
      })
    })
  },
}
</script>
