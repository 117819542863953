<template>
  <div>
    <div
      class="tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-gray-200 tw-p-4 ie_tw-h-screen"
    >
      <div class="tw-w-full w-360">
        <form
          class="tw-bg-white tw-shadow-md tw-rounded tw-px-8 tw-pt-6 tw-pb-8 tw-mb-4"
          @submit.prevent="submit"
        >
          <div>
            <a :href="marketingSiteUrl" title="Click to visit">
              <HeaderLogo />
            </a>
          </div>
          <p
            class="tw-font-semibold tw-mb-4 tw-mt-8 tw-text-2xl tw-text-gray-800"
          >
            Forgot Password
          </p>
          <div class="tw-mb-4">
            <label class="form-label" for="username">
              Email
            </label>
            <input
              id="username"
              v-model="username"
              v-validate="'required|email'"
              v-focus
              name="email"
              data-vv-as="username"
              class="form-control"
              type="text"
              placeholder="john@example.com"
              autocomplete="off"
              inputmode="email"
              data-cy="email"
            />
          </div>
          <div class="tw-flex tw-items-center tw-justify-between">
            <SpinnerButton
              :disabled="!valid || loading"
              :loading="loading"
              :spinner-only="true"
              type="submit"
              data-cy="btn-submit"
            >
              Submit
            </SpinnerButton>

            <router-link
              :to="{ name: 'signin' }"
              class="tw-mx-0 btn btn-borderless btn-link btn-link_focus tw-text-blue-500"
            >
              Sign In
            </router-link>
          </div>
        </form>

        <auth-footer />
      </div>
    </div>
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import SpinnerButton from '@/components/SpinnerButton'
import ValidatesForm from '@/mixins/ValidatesForm'
import MarketingSiteUrl from '@/mixins/MarketingSiteUrl'
import HeaderLogo from '@/components/HeaderLogo'
import Profile from '@/api/profile/Profile'

export default {
  name: 'ForgotPassword',

  middleware: 'guest',

  layout: 'DefaultLayout',

  components: { HeaderLogo, AuthFooter, SpinnerButton },

  mixins: [ValidatesForm, MarketingSiteUrl],

  data: () => ({
    username: '',
    loading: false,
  }),

  mounted() {
    this.showRecaptchaBadge()
  },

  methods: {
    async showRecaptchaBadge() {
      await this.$recaptchaLoaded()

      this.$recaptchaInstance.showBadge()
    },

    async submit() {
      this.loading = true

      try {
        await this.validate()

        if (!this.valid) return

        const recaptchaToken = await this.$recaptcha('forgotPassword')

        const { data } = await Profile.sendPasswordResetLink(
          this.username,
          recaptchaToken
        )

        this.success(data.message)
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },
  },
}
</script>
