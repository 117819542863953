<template>
  <table
    aria-label="Webhook call events table"
    class="ld-table ld-table-clickable tw-flex tw-flex-col"
  >
    <thead>
      <tr>
        <th style="width: 60px"></th>

        <th style="width: 200px">Event Type</th>

        <th style="width: 200px">Created Date</th>
      </tr>
    </thead>

    <tbody>
      <template v-if="events.length">
        <WebhookCallEventsTableRow
          v-for="event in events"
          :key="event.id"
          :event="event"
          :class="{ active: isActiveRow(event) }"
          class="tw-flex-shrink-0"
          @show-data="$emit('show-webhook-call-event-data', event)"
        />
      </template>

      <tr v-else>
        <td style="width: 460px" class="tw-bg-white" colspan="3">
          <NoData message="No events available" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import NoData from '@/components/NoData'
import WebhookCallEventsTableRow from '@/components/integrations/webhooks/WebhookCallEventsTableRow'

export default {
  name: 'WebhookCallEventsTable',

  components: { NoData, WebhookCallEventsTableRow },

  props: {
    events: {
      type: Array,
      required: true,
    },

    selectedEvent: {
      type: Object,
      default: null,
    },
  },

  methods: {
    isActiveRow(event) {
      if (!this.selectedEvent) {
        return false
      }

      return this.selectedEvent.id === event.id
    },
  },
}
</script>

<style lang="scss">
.ld-table-clickable {
  tbody tr {
    &.active {
      background-color: #ebf8fe;
      transition: 0.25s;
    }

    &:hover {
      td {
        @apply .border-gray-300;
      }

      + tr td {
        @apply .border-gray-300;
      }
    }
  }
}
</style>
