<template>
  <div
    ref="reference"
    data-cy="department-dropdown"
    class="tw-h-12 tw-mx-4 tw-py-2 tw-w-full tw-inline-flex tw-items-center tw-justify-between tw-no-underline tw-cursor-pointer"
  >
    <div>
      <div
        v-if="!loading"
        data-cy="dept-switcher"
        class="tw-font-normal tw-text-base tw-select-none tw-text-gray-800"
      >
        {{ selectedDepartmentName }}
      </div>
      <div v-show="false" ref="tooltip">
        <div class="tw-rounded" data-cy="dept-switcher-list">
          <div
            class="tw-overflow-y-auto"
            style="width: 248px; max-height: 420px;"
          >
            <div class="tw-px-4 tw-py-4 tw-text-gray-600 tw-leading-none">
              Show
            </div>
            <SwitcherOption
              option="everyone"
              :default-filter="defaultFilter"
              :option-label="defaultLabel"
              @default-filter-changed="$emit('default-filter-changed', $event)"
              @option-changed="applyCompanyScheduleFilter($event)"
            />
            <div v-for="department in departments" :key="department.id">
              <SwitcherOption
                :option="department.id"
                :default-filter="defaultFilter"
                :option-label="department.name"
                @default-filter-changed="
                  $emit('default-filter-changed', $event)
                "
                @option-changed="applyCompanyScheduleFilter($event)"
              />
            </div>
            <div>
              <div class="tw-px-4 tw-py-4 tw-text-gray-600 tw-leading-none">
                Filter by
              </div>
              <SwitcherOption
                option="departments_missing"
                :default-filter="defaultFilter"
                option-label="Department Missing"
                @default-filter-changed="
                  $emit('default-filter-changed', $event)
                "
                @option-changed="applyCompanyScheduleFilter($event)"
              />
            </div>
            <div v-if="enableGroupByDepartment && hasMultipleDepartments">
              <div class="tw-border-t"></div>
              <div class="tw-px-4 tw-py-4 tw-text-gray-600 tw-leading-none">
                Group by
              </div>
              <SwitcherOption
                option="group_by_department"
                :default-filter="defaultFilter"
                option-label="Department"
                @default-filter-changed="
                  $emit('default-filter-changed', $event)
                "
                @option-changed="applyCompanyScheduleFilter($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <SvgIcon
      v-if="!loading"
      name="cheveron-down"
      class="tw-ml-1 tw-w-5 tw-h-5 tw-cursor-pointer tw-text-gray-800"
    />
  </div>
</template>

<script>
import tippy from 'tippy.js'
import { omit } from 'lodash-es'
import ValidatesForm from '@/mixins/ValidatesForm'
import { Departments } from '@/api'
import SwitcherOption from '@/components/SwitcherOption'

export default {
  name: 'DepartmentSwitcher',
  components: { SwitcherOption },

  mixins: [ValidatesForm],

  props: {
    enableGroupByDepartment: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAllDeptLable: {
      type: Boolean,
      default: false,
    },
    defaultFilter: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    departments: [],
    loading: false,
  }),

  computed: {
    defaultLabel() {
      return this.isAllDeptLable ? 'All Departments' : 'Everyone'
    },

    selected() {
      return this.departments.find(department => {
        return department.id === this.$route.query.department
      })
    },

    selectedDepartmentName() {
      if (!('department' in this.$route.query)) {
        if ('group-by' in this.$route.query) {
          return 'By department'
        }

        return this.defaultLabel
      } else if (this.$route.query.department === '') {
        return 'Department missing'
      }

      return this.selected ? this.selected.name : ''
    },

    hasMultipleDepartments() {
      return this.departments.length > 1
    },
  },

  watch: {
    '$route.query.company': {
      async handler(newVal, oldVal) {
        if (newVal === oldVal) return

        this.fetchDepartments()
      },
    },
  },

  mounted() {
    this.tippy = tippy.one(this.$refs.reference, {
      html: this.$refs.tooltip.firstChild,
      trigger: 'click',
      interactive: true,
      placement: 'bottom-start',
      distance: 4,
      offset: '-13,0',
      theme: 'light',
      animation: 'scale',
      inertia: true,
      arrow: true,
    })

    this.$once('hook:beforeDestroy', () => {
      this.tippy.destroy()
    })

    this.fetchDepartments()
  },

  methods: {
    async fetchDepartments() {
      this.loading = true

      const { data } = await Departments.all({
        company_id: this.activeCompany.id,
      })

      this.departments = data

      this.loading = false
    },

    applyCompanyScheduleFilter(option) {
      if (option === 'group_by_department') {
        this.applyGroupByDepartmentFilter()
      }

      if (option === 'everyone') {
        this.removeFilters()
      }

      if (option === 'departments_missing') {
        this.applyDepartmentMissingFilter()
      }

      if (
        option !== 'group_by_department' &&
        option !== 'everyone' &&
        option !== 'departments_missing'
      ) {
        this.applyDepartmentFilter(option)
      }

      this.$emit('changed')

      this.tippy.hide()
    },

    applyGroupByDepartmentFilter() {
      this.$router.push(
        {
          query: {
            ...omit(this.$route.query, 'department'),
            'group-by': 'department',
          },
        },
        () => {}
      )
    },

    removeFilters() {
      this.$router.push(
        {
          query: {
            ...omit(this.$route.query, ['department', 'group-by']),
          },
        },
        () => {}
      )
    },

    applyDepartmentMissingFilter() {
      this.$router.push(
        {
          query: { ...omit(this.$route.query, 'group-by'), department: '' },
        },
        () => {}
      )
    },

    applyDepartmentFilter(department_id) {
      this.$router.push(
        {
          query: {
            ...omit(this.$route.query, 'group-by'),
            department: department_id,
          },
        },
        () => {}
      )
    },
  },
}
</script>
