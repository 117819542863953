<template>
  <div>
    <div class="tw-pb-4">
      <div data-cy="page-title">
        <h2 class="page-title">Overtime Report</h2>
      </div>
    </div>

    <div v-scroll-hint class="table-responsive">
      <table class="ld-table ld-table-striped" data-cy="table-toil-report">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Department</th>
            <th>Employee Code</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Reason</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody ref="body">
          <template v-if="overtimes.length">
            <OvertimeDetailReportTableRow
              v-for="(overtime, index) in overtimes"
              :key="index"
              :overtime="overtime"
            />
          </template>
          <tr v-if="!loading && !overtimes.length">
            <td class="tw-bg-white" colspan="8">
              <NoData />
            </td>
          </tr>
        </tbody>
      </table>
      <Loading
        :blur="null"
        loader="dots"
        :is-full-page="false"
        :active.sync="loading"
        :container="$refs.body"
        class="tw-mt-12"
      />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'

const NoData = () => import('@/components/NoData')
const OvertimeDetailReportTableRow = () =>
  import('@/components/reports/overtime/OvertimeDetailReportTableRow')

export default {
  name: 'OvertimeDetailReportTable',

  components: {
    NoData,
    Loading,
    OvertimeDetailReportTableRow,
  },

  props: {
    overtimes: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
