import { Company } from '@/api'

export default class HolidaySettings {
  static async enable(company, payload) {
    const { data } = await Company.update(company, payload)

    return data
  }

  static async disable(company) {
    const { data } = await Company.update(company, {
      holiday_settings: {
        manage_automatically: false,
        create_before_months: company.holiday_settings.create_before_months,
      },
    })

    return data
  }
}
