<template>
  <RequestCanceled
    request-type="overtime"
    :notification="notification"
    :active-notification="activeNotification"
    @show-more-details="showMoreDetails"
  />
</template>

<script>
import RequestCanceled from '@/components/notifications/RequestCanceled'
import Notification from '@/components/notifications/mixins/Notification'

export default {
  name: 'OvertimeCancelled',

  components: {
    RequestCanceled,
  },

  mixins: [Notification],

  methods: {
    showMoreDetails() {
      this.$emit('click', this.notification)
    },
  },
}
</script>
