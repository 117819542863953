<template>
  <table aria-label="Webhooks table" class="ld-table ld-table-striped">
    <thead>
      <tr>
        <th>Url</th>

        <th style="width: 30%;">Signing secret</th>

        <th>Listening for</th>

        <th style="width: 200px;"></th>

        <th style="width: 100px;"></th>
      </tr>
    </thead>

    <tbody>
      <template v-if="webhooks.length">
        <WebhooksTableRow
          v-for="webhook in webhooks"
          :key="webhook.id"
          :webhook="webhook"
          @edit="$emit('edit-webhook', webhook)"
          @delete="$emit('delete-webhook', webhook)"
          @show-call-events="$emit('show-webhook-call-events', webhook)"
        />
      </template>

      <tr v-else>
        <td class="tw-bg-white" colspan="7">
          <NoData :message="'No webhooks have been added'" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import NoData from '@/components/NoData.vue'
import WebhooksTableRow from '@/components/integrations/webhooks/WebhooksTableRow'

export default {
  name: 'WebhooksTable',

  components: {
    NoData,
    WebhooksTableRow,
  },

  props: {
    webhooks: {
      type: Array,
      required: true,
    },
  },
}
</script>
