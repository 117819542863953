<template>
  <div
    class="tw-cursor-pointer tw-px-4 tw-py-3 tw-border-t tw-border-gray-300 hover:tw-bg-blue-200 tw-overflow-hidden notification-item tw-w-full"
    @click="$emit('show-more-details')"
  >
    <div class="tw-flex tw-items-center">
      <div>
        <UserAvatar
          :user="notification.getCreator()"
          class="tw-block tw-mr-4 notifications-list-avatar"
        />
      </div>

      <div>
        <div>
          <div class="notification-message-wrap" data-cy="notification-message">
            <span class="tw-font-semibold">{{ creator }}</span>
            <span> {{ adverb }} requested {{ requestType }}</span>
            <span v-if="!notification.creatorIsOwner()">
              for
              <span class="tw-font-semibold">{{ owner }}</span>
            </span>
            <span>.</span>
          </div>
        </div>

        <HappenedOn :time="fromNow" class="tw-mt-1" />
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '@/components/notifications/mixins/Notification'
import HappenedOn from '@/components/notifications/HappenedOn'
import ActiveEmployment from '@/mixins/ActiveEmployment'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'

export default {
  name: 'RequestRequested',

  components: {
    HappenedOn,
  },

  mixins: [Notification, ActiveEmployment, FormatEmploymentName],

  props: {
    requestType: {
      type: String,
      required: true,
    },
  },

  computed: {
    creator() {
      if (this.notification.viewerIsCreator(this.activeEmployment)) {
        return 'You'
      }

      return this.compactFullName(this.notification.getCreator())
    },

    adverb() {
      if (this.notification.viewerIsCreator(this.activeEmployment)) {
        return 'have'
      }

      return 'has'
    },

    owner() {
      if (this.notification.viewerIsOwner(this.activeEmployment)) {
        return 'you'
      }

      return this.compactFullName(this.notification.getOwner())
    },
  },
}
</script>
