import URL from 'url'
import extractDomain from 'extract-domain'

export default {
  methods: {
    async redirectToProvider(domain, provider) {
      const url = URL.parse(process.env.VUE_APP_API_URL)

      const { data } = await this.$http.get(
        `${url.protocol}//${domain}.${extractDomain(
          process.env.VUE_APP_API_URL
        )}/${provider}/login`
      )

      window.location.href = data.redirect_url
    },
  },
}
