<template>
  <div class="tw-flex tw-items-center" data-cy="holiday-group">
    <div style="margin-top: -10px; width: 86%;" data-cy="holiday-year">
      <a class="btn-link tw-text-black" @click.prevent="$emit('edit-holidays')">
        {{ holidayGroupYear }}
      </a>
    </div>
    <div
      class="tw-w-full tw-flex tw-items-center tw-justify-between"
      data-cy="number-of-holidays"
    >
      <div style="margin-top: -10px;">
        {{ numberOfHolidays }}
      </div>
      <div class="tw-mb-2">
        <button
          class="btn btn-icon tw-border tw-border-gray-400 hover:tw-bg-gray-300 tw-ml-3"
          title="Edit"
          data-cy="edit-holiday"
          @click="$emit('edit-holidays')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon name="edit-pencil" class="svg-icon tw-text-gray-700" />
          </div>
        </button>
        <button
          class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
          title="Delete"
          data-cy="delete-holiday"
          @click="$emit('delete-holidays')"
        >
          <div class="tw-flex tw-items-center tw-justify-center">
            <svg-icon name="trash" class="svg-icon tw-text-white" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HolidaysYearGroup',

  props: {
    holidayGroup: {
      type: Object,
      required: true,
    },
  },

  computed: {
    numberOfHolidays() {
      return this.holidayGroup.holidays.length
    },

    holidayGroupYear() {
      return this.holidayGroup.year
    },
  },
}
</script>
