<template>
  <div class="tw-flex tw-items-center tw-justify-start">
    <div class="tw-mr-6">
      <img
        :src="service.logo"
        class="tw-w-16"
        style="min-width: 4rem;"
        :alt="service.name"
      />
    </div>

    <div>
      <div class="tw-text-lg tw-text-gray-800 tw-mb-2">
        {{ service.name }}
      </div>

      <template v-if="isSuccessfullyIntegrated" class="tw-flex">
        <div class="tw-flex tw-justify-between">
          <svg-icon
            name="check-circle-solid"
            class="tw-w-5 tw-h-5 tw-text-green-500"
            style="min-width: 1.25rem;"
          />

          <div class="tw-ml-2 tw-break-all">
            Connected with
            <span class="tw-font-semibold">{{ integratedCalendar.email }}</span>
          </div>
        </div>
      </template>

      <div class="tw-mt-2">
        <CalendarServiceConnector
          v-slot="{ connect, reconnect, disconnect }"
          @disconnected="calendar => $emit('disconnected', calendar)"
        >
          <div>
            <button
              v-if="isSuccessfullyIntegrated"
              type="button"
              class="btn-link"
              @click="disconnect(integratedCalendar)"
            >
              Disconnect
            </button>

            <button
              v-if="isIntegratedWithErrors"
              type="button"
              class="btn-link"
              @click="reconnect(integratedCalendar)"
            >
              Connect
            </button>

            <button
              v-if="!integratedCalendar"
              type="button"
              class="btn-link"
              @click="connect(service.id)"
            >
              Connect
            </button>
          </div>
        </CalendarServiceConnector>
      </div>
    </div>
  </div>
</template>

<script>
import { find } from 'lodash-es'
import CalendarServiceConnector from '@/components/calendar-services/CalendarServiceConnector'

export default {
  name: 'CalendarService',

  components: { CalendarServiceConnector },

  props: {
    service: {
      type: Object,
      required: true,
    },

    integratedCalendars: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    integratedCalendar() {
      return (
        find(this.integratedCalendars, { service: this.service.id }) || null
      )
    },

    isSuccessfullyIntegrated() {
      return (
        !!this.integratedCalendar &&
        !this.integratedCalendar.has_connection_error
      )
    },

    isIntegratedWithErrors() {
      return (
        !!this.integratedCalendar &&
        this.integratedCalendar.has_connection_error
      )
    },
  },
}
</script>
