<template>
  <RequestStatusPicker
    :value="value"
    :placeholder="placeholder"
    @input="$emit('input', $event)"
  />
</template>

<script>
import RequestStatusPicker from '@/components/pickers/RequestStatusPicker'

export default {
  name: 'LeaveStatusPicker',

  components: {
    RequestStatusPicker,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },

    placeholder: {
      type: String,
      default: 'Select leave status',
    },
  },
}
</script>
