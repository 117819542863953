<template>
  <div class="tw-w-full w-360">
    <div class="tw-shadow-md tw-rounded">
      <div class="tw-mb-4 tw-px-8 tw-py-6 tw-bg-white tw-rounded">
        <p class="tw-my-5 tw-font-bold tw-text-2xl tw-text-gray-800">
          👋 Welcome {{ authUser.full_name }}
        </p>

        <p class="tw-mb-3 tw-font-medium tw-text-xl">Company leave year</p>

        <p class="tw-mb-6 tw-text-base tw-text-gray-700">
          The leave year defines when the calendar starts and ends, renewing the
          allowances and triggering (optional) carry overs. Usually, this is
          aligned with the company's fiscal year.
        </p>

        <fieldset>
          <legend class="tw-sr-only">Select leave year</legend>

          <div class="tw-space-y-4">
            <RadioBox
              v-for="option in leaveYearOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :model-value="selectedOption"
              @input="setLeaveYearOption"
            />

            <div v-if="isCustomDate" class="form-group">
              <label class="form-label">
                Leave year starts on <span class="required-field">&#42;</span>
              </label>

              <SingleDatePicker
                id="start_date"
                v-model="customDate"
                v-validate.disable="'required'"
                :format="'MMMM Do, YYYY'"
                :placeholder="placeholder"
                data-vv-as="calendar date"
                name="start_date"
                class="form-control"
                type="text"
                autocomplete="off"
                data-cy="start-date"
                readonly
              />

              <p
                v-show="errors.has('start_date')"
                class="tw-mt-1 tw-text-red-500 tw-text-sm tw-italic"
              >
                {{ errors.first('start_date') }}
              </p>
            </div>
          </div>
        </fieldset>

        <div class="tw-mt-5 tw-flex tw-items-center tw-justify-end">
          <BaseButton class="tw-mr-3" type="button" @click="goToNext">
            Next
          </BaseButton>
        </div>
      </div>
    </div>

    <AuthFooter />
  </div>
</template>

<script>
import AuthFooter from '@/components/AuthFooter'
import BaseButton from '@/components/BaseButton'
import RadioBox from '@/components/RadioBox'
import moment from 'moment-timezone'
import FormatDate from '@/mixins/FormatDate'
import ValidatesForm from '@/mixins/ValidatesForm'

const SingleDatePicker = () => import('@/components/SingleDatePicker.vue')

const LEAVE_YEAR_OPTIONS = [
  {
    value: 'january_first',
    date: moment().startOf('year'),
  },
  {
    value: 'april_first',
    date: moment()
      .month(3)
      .startOf('month'),
  },
]

export default {
  name: 'LeaveYearSelector',

  layout: 'DefaultLayout',

  components: { AuthFooter, BaseButton, RadioBox, SingleDatePicker },

  mixins: [FormatDate, ValidatesForm],

  data() {
    return {
      customDate: '',
      selectedOption: 'january_first',
    }
  },

  computed: {
    startDate() {
      if (this.isCustomDate) {
        return moment(this.customDate, 'MMMM Do, YYYY').clone()
      }

      return LEAVE_YEAR_OPTIONS.find(
        option => option.value === this.selectedOption
      ).date
    },

    leaveYearOptions() {
      let options = LEAVE_YEAR_OPTIONS.map(option => ({
        value: option.value,
        label: this.periodText(option.date),
      }))

      return [
        ...options,
        {
          value: 'custom',
          label:
            'Custom' +
            '\n' +
            (this.customDate ? this.periodText(this.startDate) : ''),
        },
      ]
    },

    placeholder() {
      return `January 1st, ${moment().format('yyyy')}`
    },

    isCustomDate() {
      return this.selectedOption === 'custom'
    },
  },

  methods: {
    async goToNext() {
      await this.validate()

      if (!this.valid) return

      this.$emit('date-selected', this.startDate)

      this.$emit('next', 'CreateCompany')
    },

    periodText(date = '') {
      if (!date) return ''

      let from = moment(date).format('MMMM Do')
      let to = moment(date)
        .add(1, 'y')
        .subtract(1, 'd')
        .format('MMMM Do')

      return `${from} to ${to}`
    },

    setLeaveYearOption(value) {
      this.selectedOption = value
      this.customDate = ''
    },
  },
}
</script>
