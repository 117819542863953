import HolidayLocation from '@/models/company/HolidayLocation'
import { first, toLower } from 'lodash-es'

export default class HolidayLocationsCollection {
  constructor(locations = []) {
    this.locations = locations.map(location => new HolidayLocation(location))
  }

  all() {
    return this.locations
  }

  length() {
    return this.locations.length
  }

  locationsWithHolidays() {
    return this.locations.filter(location => location.holidays.length)
  }

  findByKey(key) {
    return this.locations.find(location => location.id === key)
  }

  findLocation({ name, country, region }) {
    const locations = this.locations.filter(location => {
      return (
        toLower(location.name) === toLower(name) &&
        toLower(location.country) === toLower(country) &&
        (((region === 'All' || region === 'Exclude regional holidays') &&
          location.region === null) ||
          toLower(region) === toLower(location.region))
      )
    })

    return first(locations)
  }
}
