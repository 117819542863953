<template>
  <tr @click.stop="$emit('show-info', pendingApproval)">
    <td>
      <div class="tw-flex tw-items-center" data-cy="request-leave-owner">
        <UserAvatar :user="pendingApproval.owner"></UserAvatar>
        <span class="tw-ml-2">{{
          compactFullName(pendingApproval.owner)
        }}</span>
      </div>
    </td>

    <td data-cy="request-type">{{ pendingApproval.getTypeName() }}</td>

    <td data-cy="request-from">{{ from }}</td>

    <td data-cy="request-duration">{{ duration }}</td>
  </tr>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'
import UserAvatar from '@/components/UserAvatar'
import PendingApproval from '@/models/reporting/PendingApproval'
import FormatEmploymentName from '@/mixins/FormatEmploymentName'
import RequestDuration from '@/components/requests/RequestDuration'

export default {
  name: 'PendingApprovalsSummaryTableRow',

  components: {
    UserAvatar,
  },

  mixins: [FormatDate, FormatEmploymentName],

  props: {
    pendingApproval: {
      type: PendingApproval,
      required: true,
    },
  },

  computed: {
    from() {
      return this.formatDateFromIsoToDayReadableDayNumberShortMonthYear(
        this.pendingApproval.from,
        this.pendingApproval.getTimezone()
      )
    },

    duration() {
      return new RequestDuration(this.pendingApproval.getDuration()).toString()
    },
  },
}
</script>
