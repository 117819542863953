import http from '@/plugins/http'

export default class AuthProviders {
  static get(companyId) {
    return http.get('auth-providers', {
      params: {
        company_id: companyId,
      },
    })
  }

  static post(payload) {
    return http.post('auth-providers', payload)
  }

  static delete(providerId, companyId) {
    return http.delete('auth-providers/' + providerId, {
      params: {
        company: companyId,
      },
    })
  }
}
