<template>
  <div />
</template>

<script>
import { mapActions } from 'vuex'
import Profile from '@/api/profile/Profile'

export default {
  name: 'VerifyAccount',

  layout: 'DefaultLayout',

  async created() {
    try {
      const { data } = await Profile.verifyAccount(this.$route.query.token)

      await this.fetchUser()

      this.$router.replace({ name: 'home' }, () => {
        this.success(data.message)
      })
    } catch ({ response }) {
      this.$router.replace({ name: 'home' }, () => {
        this.error(response.data.errors['verification_code'][0])
      })
    }
  },

  methods: {
    ...mapActions('auth', ['fetchUser']),
  },
}
</script>
