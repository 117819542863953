<template>
  <td colspan="2">
    <HolidaysYearGroup
      v-for="holidayYearGroup in holidayGroup"
      :key="holidayYearGroup.year"
      :holiday-group="holidayYearGroup"
      @edit-holidays="$emit('edit-holidays', holidayYearGroup.year)"
      @delete-holidays="$emit('delete-holidays', holidayYearGroup.year)"
    />
    <div data-cy="add-year" style="margin-top: -2px; margin-bottom: 2px;">
      <a class="btn-link tw-font-semibold" @click.prevent="$emit('add-year')">
        Add Year
      </a>
    </div>
  </td>
</template>

<script>
import HolidaysYearGroup from './HolidaysYearGroup'

export default {
  name: 'HolidaysGroup',

  components: { HolidaysYearGroup },

  props: {
    holidayGroup: {
      type: Array,
      required: true,
    },
  },
}
</script>
