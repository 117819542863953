import http from '@/plugins/http'

export default class HolidayLocations {
  static async all(params) {
    const { data } = await http.get('holiday-locations', params)

    return data
  }

  static async save(payload) {
    const { data } = await http.post('holiday-locations', payload)

    return data
  }

  static async delete(location, payload) {
    const { data } = await http.delete(`holiday-locations/${location}`, payload)

    return data
  }
}
