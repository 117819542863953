<template>
  <div class="tw-text-center timeline-action-btn-wrap">
    <div class="tw-flex tw-justify-center">
      <div class="tw-shadow-lg tw-rounded-lg">
        <button
          :class="{
            'tw-bg-blue-500 active-view': currentView === 'resourceTimelineDay',
          }"
          class="btn-cal-switch btn tw-px-6 md:tw-px-6 sm:tw-px-3 tw-text-gray-800 tw-rounded-lg tw-rounded-r-none btn-day-view"
          type="button"
          data-cy="btn-day-view"
          @click="setCurrentView('resourceTimelineDay')"
        >
          Day
        </button>
        <button
          :class="{
            'tw-bg-blue-500 active-view':
              currentView === 'resourceTimelineWeek',
          }"
          class="btn-cal-switch btn md:tw-px-6 sm:tw-px-3 tw-text-gray-800 tw-rounded-none"
          type="button"
          data-cy="btn-week-view"
          @click="setCurrentView('resourceTimelineWeek')"
        >
          Week
        </button>
        <button
          :class="{
            'tw-bg-blue-500 active-view':
              currentView === 'resourceTimelineMonth',
          }"
          class="btn-cal-switch btn md:tw-px-6 sm:tw-px-3 tw-text-gray-800 tw-rounded-none btn-month-view"
          type="button"
          data-cy="btn-month-view"
          @click="setCurrentView('resourceTimelineMonth')"
        >
          Month
        </button>
        <button
          :class="{
            'tw-bg-blue-500 active-view':
              currentView === 'resourceTimeline30Days',
          }"
          class="btn-cal-switch btn md:tw-px-6 sm:tw-px-3 tw-text-gray-800 tw-rounded-lg tw-rounded-l-none"
          type="button"
          data-cy="btn-30days-view"
          @click="setCurrentView('resourceTimeline30Days')"
        >
          30 Days
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Mousetrap from 'mousetrap'

export default {
  name: 'TimelineViewSwitcher',

  props: {
    currentView: {
      type: String,
      required: true,
    },
  },

  mounted() {
    Mousetrap.bind('d', e => {
      e.preventDefault()
      this.$emit('change', 'resourceTimelineDay')
    })

    Mousetrap.bind('w', e => {
      e.preventDefault()
      this.$emit('change', 'resourceTimelineWeek')
    })

    Mousetrap.bind('m', e => {
      e.preventDefault()
      this.$emit('change', 'resourceTimelineMonth')
    })

    this.$once('hook:beforeDestroy', () => {
      Mousetrap.unbind('d')
      Mousetrap.unbind('w')
      Mousetrap.unbind('m')
    })
  },

  methods: {
    setCurrentView(view) {
      if (view != 'resourceTimelineDay') {
        this.inActiveEmploymentTimezone = false
      }
      this.$emit('change', view)
    },
  },
}
</script>
