<template>
  <Multiselect
    :value="value"
    :options="options"
    :multiple="true"
    :hide-selected="true"
    :show-labels="false"
    :searchable="true"
    :max-height="180"
    :loading="loading"
    :internal-search="false"
    placeholder="Search"
    class="input-chips"
    track-by="id"
    @input="$emit('input', $event)"
    @search-change="$emit('search-change', $event)"
  >
    <template #option="props">
      <div class="tw-flex tw-justify-start tw-items-center tw-content-center">
        <UserAvatar :user="props.option" class="tw-mr-2"></UserAvatar>
        <div class="tw-text-base tw-font-normal tw-text-gray-800">
          {{ props.option.full_name }}
        </div>
      </div>
    </template>

    <template #tag="props">
      <div class="chip-wrapper">
        <div class="chips">
          <div class="chip">
            <UserAvatar :user="props.option" class="tw-mr-2"></UserAvatar>
            <div class="title">
              {{ props.option.full_name }}
            </div>
            <button
              class="close"
              type="button"
              @click="props.remove(props.option)"
            >
              <SvgIcon name="close" class="tw-w-2 tw-h-2 tw-text-red-500" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <template #placeholder>
      <div class="tw-text-gray-500">
        Search
      </div>
    </template>
    <template #noResult>
      <div class="tw-text-gray-500" v-text="notFoundMessage" />
    </template>
    <template #afterList>
      <ul class="tw-list-none">
        <li
          v-observe-visibility="{
            callback: hasNextPage
              ? reached => $emit('scroll-down', reached)
              : () => false,
            throttle: 300,
          }"
        >
          <span v-if="hasNextPage" class="tw-text-sm tw-px-4 tw-py-2">
            Loading...
          </span>
        </li>
      </ul>
    </template>
  </Multiselect>
</template>

<script>
import { Multiselect } from 'vue-multiselect'

export default {
  name: 'EmployeePicker',

  components: { Multiselect },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasNextPage: {
      type: Boolean,
      default: false,
    },
    notFoundMessage: {
      type: String,
      default: 'No Employees available',
    },
  },
}
</script>
