<template>
  <div>
    <SummaryEvent v-if="summary" :event="event" />

    <div v-else class="tw-flex">
      <UserAvatar :user="event.event.actor" class="tw-mt-2 tw-mr-2" />

      <div
        class="tw-flex-1 tw-p-3 tw-rounded-lg tw-rounded-tl-none tw-bg-red-100"
      >
        <div class="tw-mb-2 tw-flex tw-items-center tw-justify-between">
          <EventText
            event-text-class="tw-text-red-900"
            :text="event.asText(activeEmployment)"
          />

          <div class="tw-text-sm tw-text-gray-600">
            {{ event.getCreatedTime() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventText from '@/components/requests/EventText'
import SummaryEvent from '@/components/requests/overtime/timeline/SummaryEvent'
import CommentDeletedEvent from '@/components/requests/overtime/events/CommentDeletedEvent'

export default {
  name: 'OvertimeCommentWasDeleted',
  components: { EventText, SummaryEvent },

  props: {
    event: {
      type: CommentDeletedEvent,
      required: true,
    },

    summary: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
