import { omit } from 'lodash-es'

export default class WallChartFilter {
  static getRouteQuery(query, employment) {
    const companyScheduleFilter = employment.getCompanyScheduleFilter()

    if (this._isFilterForEveryone(query, companyScheduleFilter)) {
      return {
        ...omit(query, 'group-by', 'department'),
      }
    }

    if (this._isFilterForGroupByDepartment(query, companyScheduleFilter)) {
      return {
        ...omit(query, 'group-by', 'department'),
        'group-by': 'department',
      }
    }

    if (this._isFilterForDepartmentMissing(query, companyScheduleFilter)) {
      return {
        ...omit(query, 'group-by', 'department'),
        department: '',
      }
    }

    if (this._isFilterForDepartment(query, companyScheduleFilter)) {
      return {
        ...omit(query, 'group-by', 'department'),
        department: companyScheduleFilter,
      }
    }
  }

  static _isFilterForEveryone(query, companyScheduleFilter) {
    return (
      companyScheduleFilter === 'everyone' &&
      (query.department !== undefined || query['group-by'] !== undefined)
    )
  }

  static _isFilterForGroupByDepartment(query, companyScheduleFilter) {
    return (
      companyScheduleFilter === 'group_by_department' &&
      (query.department !== undefined || query['group-by'] !== 'department')
    )
  }

  static _isFilterForDepartmentMissing(query, companyScheduleFilter) {
    return (
      companyScheduleFilter === 'departments_missing' &&
      (query['group-by'] !== undefined || query.department !== '')
    )
  }

  static _isFilterForDepartment(query, companyScheduleFilter) {
    return (
      companyScheduleFilter !== 'departments_missing' &&
      companyScheduleFilter !== 'group_by_department' &&
      companyScheduleFilter !== 'everyone' &&
      query.department !== companyScheduleFilter
    )
  }
}
